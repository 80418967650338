import React, { useEffect, useState } from 'react';
import { ManageLocalStorage } from '../../Services/ManageLocalStorage';
import { getProfile, coachList } from '../../Services/SiteServices';
import ButtonWrapper from '../../Shared/Components/Button';
import WithLayout from '../../Shared/Components/HeaderHOC';

const DefaultCoachSelected = (props) => {

    const [coaches, setCoaches] = useState([]);
    const [selectedCoach, setSelectedCoach] = useState({})
    const [avatar, setAvatar] = useState({})

    useEffect(() => {
        getProfile().then((profile) => {
            setSelectedCoach(profile.data.coach)
            setAvatar(profile.data.avatar)
        });

        coachList()
        .then((res) => {
            setCoaches(res.data.coaches_list);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    const chooseAvataar = (data) => {
        if (data.popup_shown) {
            ManageLocalStorage.set("avatarUrl", data.url);
           
        }
       
        if(ManageLocalStorage.get("popupShown") == "1") {
            
            props.history.push("/dashboard");
        } else {
            props.history.push("/compass");
        }
    };

    return (
        <div className="conersation-agents-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
            <div className="space-around">
                <div className="popup-body p-d-flex mt-top-auto">
                    <div className="conersation-agents-content p-d-flex p-flex-column">

                        <div className="conersation-agents-wrap p-d-flex p-jc-center p-ai-center">
                            <div className="p-d-flex p-flex-wrap p-ai-center p-flex-column">
                                <div className="conersation-start-text p-px-4 p-text-center">
                                    <p>Congratulations...!</p>
                                </div>
                                <div className="conersation-agent">
                                    <div className="conersation-agent-img">
                                        <img
                                            src={selectedCoach.img_url}
                                            alt="coach"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3>{selectedCoach.name}</h3>
                                    {
                                        coaches.map(coach => (
                                            coach.id === selectedCoach.coach_id && <p>{coach.bio}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="p-px-3 p-pt-3 p-pb-4 p-mt-auto w-100">
                    <ButtonWrapper
                        className="login-btn w-100 btn medium-btn"
                        label="Next"
                        id="welcomeButton"
                        onClick={() => chooseAvataar(avatar)}
                    />
                </div>
            </div>
        </div>
    );
};

export default WithLayout(DefaultCoachSelected);
