export const tenantAPIEndpoints = {
  list: () => `admin/tenant-list`,
  create: () => `admin/tenant`,
  update: (id) => `admin/tenant/${id}`,
  tenantConfig: (id) => `admin/configure-tenant/${id}`
};

export const AdminAPIEndpoints = {
  addProject: () => `admin/project`,
  listProjects: () =>`admin/project-list`,
  updateProject: (id) =>`admin/project/${id}`,
  deleteProject: (id) =>`admin/project/${id}`,
  getplans: () =>`admin/list-plans`,
  addplan: () =>`admin/add-plan`,
  masterTemplate: () =>`admin/main-template`
};

export const siteAPIEndpoints = {
  siteList: () => `tenant/site-list`,
  report: () => `tenant/report`,
  chartreport: () => `tenant/url_log_report`,
  projectList: () => `tenant/project-list`,
  downloadDelay: () => `tenant/download-delay`,
  addUrl: () => `tenant/site`,
  getUrl: (site_id) => `tenant/site/${site_id}`,
  getSiteRules: (site_id) => `tenant/site/rule/${site_id}`,
  updateUrl: (site_id) => `/tenant/site/${site_id}`,
  addSiteRule: (site_id) => `tenant/site/rule/${site_id}`,
  deleteUrl: (site_id) => `tenant/site/${site_id}`,
  startScrap: () => `tenant/start-scraping`,
  generateError: () => `tenant/error-log`,
  viewError: () => `tenant/error-log`,
  viewSiteError: (id) => `tenant/site-error/${id}`,
  updateDraftStatus: (site_id) => `tenant/site/update-draft-status/${site_id}`,
  publishAllSite: () => `tenant/publish-all`
};

export const settingsAPIEndpoints = {
  getProject: (id) => `tenant/project/${id}`,
  getProjectTemplate: (id) => `admin/project/${id}`,
  updateProjectTemplate: (id) => `admin/project/${id}`,
  rewriteProjectTemplate: (id) => `admin/project-template/${id}`,
  addschedule: () =>`tenant/schedule-scrape`,
  updateschedule: () =>`tenant/schedule-scrape`,
  deleteschedule: () =>`tenant/schedule-scrape`,
  listschedule: () =>`tenant/schedule-scrape`
};