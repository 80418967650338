import React from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { usePromiseTracker } from "react-promise-tracker";

const ProgressBarWrapper = () => {
 const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&
    <ProgressBar mode="indeterminate" style={{position: 'fixed', left: '0px', top: '0px', width: '100%', height: '4px', zIndex: 9999}}></ProgressBar>
  );
};


export default ProgressBarWrapper;