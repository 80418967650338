import React, { useState, useEffect } from 'react';
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { getProfile, getInfo, profileDetails, updateReward } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import badge from "../../Assets/images/badge.png";
import InfoModal from "../../Shared/Components/InfoModal";

const CompleteScreen = (props) => {

    const [selectedTopicDetails, setSelectedTopicDetails] = useState([]);
    const [latest, setLatestLevel] = useState(0); // Dynamic values
    const [previous, setPreviousLevel] = useState(0);
    let [avatarImage, setAvatarImage] = useState("");
    let [profileName, setProfileName] = useState("");
    const [infoContent, setInfoContent] = useState("");
    const [rewards, setRewards] = useState({});
    
    useEffect(() => {
        setSelectedTopicDetails(JSON.parse(ManageLocalStorage.get("selectedTopicDetails")));

        const rewardObj = {
            tenant_id: ManageLocalStorage.get("tenantId"),
            activity_id: selectedTopicDetails.topic_id,
            type: "topic",
            quadrant: ManageLocalStorage.get("userSelectedQuadrant"),
            points: "1"
        }

        updateReward(rewardObj)
            .then((res) => {
                setRewards(res.data);
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const getLatestLevel = (level) => {
        setLatestLevel(latest + level);
      };
    
      const getPreviousLevel = (level) => {
        setPreviousLevel(previous + level);
      };
    
      const onLoadAnimate = () => {
        setTimeout(logoAnimationSecond, 500);
      };
    

    //   const showAvataar = () => { //;;
    //     setTimeout(logoAnimationSecond, 500);
    //   };
    
    //   const logoAnimationFirst = () => { //;;
    //     // 2
    //     console.log("Logo Animation");
    //     // const logoAnimationClass = document.getElementById("logoAnimation");
    //     // logoAnimationClass.classList.add("animated");
    //   };
    
      const logoAnimationSecond = () => {  //;;
        // 2.1
        // logoAnimationFirst();
        setTimeout(showBackdrop, 800);
      };
    
      const showBackdrop = () => { //;;
        // 3
        console.log("Show backdrop");
        const backdropClass = document.getElementById("backdrop");
        backdropClass.classList.remove("p-d-none");
        backdropClass.classList.add("p-d-block");
        setTimeout(showRewardWrapper, 100);
      };
    
      const showRewardWrapper = () => { //;;
        // 4
        const rewardWrapperAnimatedClass = document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass.classList.add("slide-down");
        console.log("Show reward");
        setTimeout(rewardAnimationWrapper, 500);
      };
    
      const rewardAnimationWrapper = () => { //;;
        // 5
        const rewardAnimatedClass = document.getElementById("rewardAnimationHead");
        rewardAnimatedClass.classList.add("animationPrev");
        setTimeout(levelClassWrapper, 800);
      };
    
      const levelClassWrapper = () => { //;;
        // 6
        const rewardAnimatedLevelClass = document.getElementById("levelCurrent");
        rewardAnimatedLevelClass.classList.add("animationPrev");
        setTimeout(animationOverlayLevelStart, 800);
      };
    
      const animationOverlayLevelStart = () => { //;;
        // 7
        const rewardAnimatedClass = document.getElementById("rewardAnimationHead");
        rewardAnimatedClass.classList.add("fadeVisibleOutOnly");
        overlayEnabled();
        setTimeout(animationOverlayLevelEnd, 900);
      };
    
      const BadgeClassWrapper = () => { //;;
        const rewardAnimatedBadgeClass2 = document.getElementById("levelNext");
        rewardAnimatedBadgeClass2.classList.add("animationNext");
        rewardAnimatedBadgeClass2.classList.add("p-d-none");
        overlayEnabled();
        const badgeTextAnimationClass = document.getElementById("badgeText");
        badgeTextAnimationClass.classList.add("animationPrev");
        setTimeout(animationOverlayBadgeStart, 900);
      };
    
      const animationOverlayBadgeStart = () => { //;;
        overlayEnabled();
        setTimeout(animationBadgeOverlayEnd, 900);
      };
    
      const overlayEnabled = () => { //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass.classList.add("enabled");
      };
      const overlayDisabled = () => { //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass.classList.remove("enabled");
      };
    
      const animationOverlayLevelEnd = () => { //;;
        overlayDisabled();
        const rewardAnimatedLevelClass = document.getElementById("levelCurrent");
        rewardAnimatedLevelClass.classList.remove("animationPrev");
        rewardAnimatedLevelClass.classList.add("animationNext");
        const rewardAnimatedLevelClass1 = document.getElementById("levelNext");
        rewardAnimatedLevelClass1.classList.add("animationPrev");
        setTimeout(overlayEnabled, 900);
        setTimeout(BadgeClassWrapper, 1300);
        // setTimeout(rewardWrapper, 1500);
        setTimeout(overlayDisabled, 1800);
      };
    
      const animationBadgeOverlayEnd = () => { //;;
        overlayDisabled();
        const rewardAnimatedBadgeClass = document.getElementById("badgeText");
        rewardAnimatedBadgeClass.classList.remove("animationPrev");
        rewardAnimatedBadgeClass.classList.add("animationNext");
        const rewardAnimatedBadgeClass1 = document.getElementById("badgeImg");
        rewardAnimatedBadgeClass1.classList.add("animationPrev");
        setTimeout(overlayEnabled, 1300);
        setTimeout(rewardWrapper, 1800);
        setTimeout(overlayDisabled, 1800);
      };
    //   const dashboardAvatarBGWrapper = () => { //;;
    //     const dashboardAvatarBGClass = document.getElementById("dashboardAvatarBG");
    //     dashboardAvatarBGClass.classList.remove("fadeVisibleOutOnly");
    //     dashboardAvatarBGClass.classList.add("fadeVisibleInOnly");
    //     // setTimeout(infoWrapper, 600);
    //   };
    
      const rewardWrapper = () => { //;;
        const rewardWrapperAnimatedClass = document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass.classList.add("fadeVisibleOut");
        // setTimeout(dashboardAvatarBGWrapper, 800);
        setTimeout(infoWrapper, 500);
      };
    
    
    
      const infoWrapper = () => { //;;
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper");
    
        if (ManageLocalStorage.get("popupShown") !== "1") {
          getInfo('welcome')
            .then(res => {
              if (res.data) {
                setInfoContent(res.data[0].content)
                infoWrapperAnimatedClass.classList.add("slideUp");
              }
            })
            .catch(err => {
              console.log(err)
            })
          // infoWrapperAnimatedClass.classList.add("slideUp");
        } else {
          infoWrapperAnimatedClass.classList.remove("slideUp");
          const backdropClass = document.getElementById("backdrop");
            backdropClass.classList.add("p-d-none");
            backdropClass.classList.remove("p-d-block");
             props.history.push("/dashboard");
        //   showFooterOnSkip()
        }

    
      };
    
      const showConversationBtn = () => {
        const dashBoardClass = document.getElementById("dashboardConversationBtn");
        dashBoardClass.classList.remove("p-d-none");
        dashBoardClass.classList.add("p-d-flex");
        dashBoardClass.classList.add("fadeVisibleIn");
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper");
        infoWrapperAnimatedClass.classList.remove("slideUp");
      };
      const profileDetails = async () => {
        return getProfile().then((profile) => profile.data);
      };
      useEffect(() => {
        console.log(
          "-----popupShown---------",
          ManageLocalStorage.get("popupShown")
        );
        if (!ManageLocalStorage.get("avatarUrl")) {
           props.history.push("/welcome");
        } else {
          setAvatarImage(ManageLocalStorage.get("avatarUrl"));
        }
    
        if (ManageLocalStorage.get("popupShown") !== "1") {
          profileDetails().then((profile) => {
            ManageLocalStorage.set("tenantId", profile.tenant_id);
            getLatestLevel(profile.game.level);
            setPreviousLevel(profile.game.level - 1);
            setProfileName(profile.nickname);
            onLoadAnimate();
          });
        } else {
          profileDetails().then((profile) => {
            setProfileName(profile.nickname);
            ManageLocalStorage.set("tenantId", profile.tenant_id);
            getLatestLevel(profile.game.level);
            setPreviousLevel(profile.game.level - 1);
            console.log(profile.game.level)
          });
          overlayDisabled();
    
        }
      }, []);
    
      const loadRewards = () => {
    
        // 1
        // const levelLayerClass = document.getElementById("levelbanner");
        //       levelLayerClass.classList.add("fadeVisibleOut");
        //       levelLayerClass.classList.remove("fadeVisibleIn");
        //       console.log('14')
    
    
        // 2
        // const logoAnimationClass = document.getElementById("logoAnimation");
        //       logoAnimationClass.classList.remove("animated");
    
        //3
        const backdropClass = document.getElementById("backdrop");
        backdropClass.classList.add("p-d-none");
        backdropClass.classList.remove("p-d-block");
    
        //4
        const rewardWrapperAnimatedClass11 = document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass11.classList.remove("slide-down");
    
        //5
        const rewardAnimatedClass11 = document.getElementById("rewardAnimationHead");
        rewardAnimatedClass11.classList.remove("animationPrev");
    
        //6
        const rewardAnimatedLevelClass12 = document.getElementById("levelCurrent");
        rewardAnimatedLevelClass12.classList.remove("animationPrev");
    
        // 7
        const rewardAnimatedClass = document.getElementById("rewardAnimationHead");
        rewardAnimatedClass.classList.remove("fadeVisibleOutOnly");
    
    
        //overlay disbled
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass.classList.add("enabled");
    
        //overlay
        const rewardAnimationOverlayClass13 = document.getElementById("overlay");
        rewardAnimationOverlayClass13.classList.remove("enabled");
    
        //
        const rewardAnimatedLevelClass = document.getElementById("levelCurrent");
        rewardAnimatedLevelClass.classList.add("animationPrev");
        rewardAnimatedLevelClass.classList.remove("animationNext");
        const rewardAnimatedLevelClass1 = document.getElementById("levelNext");
        rewardAnimatedLevelClass1.classList.remove("animationPrev");
    
        const rewardAnimatedBadgeClass2 = document.getElementById("levelNext");
        rewardAnimatedBadgeClass2.classList.remove("animationNext");
        rewardAnimatedBadgeClass2.classList.remove("p-d-none");
        const badgeTextAnimationClass = document.getElementById("badgeText");
        badgeTextAnimationClass.classList.remove("animationPrev");
    
        badgeTextAnimationClass.classList.remove("animationNext");
        const rewardAnimatedBadgeClass1 = document.getElementById("badgeImg");
        rewardAnimatedBadgeClass1.classList.remove("animationPrev");
    
        const rewardWrapperAnimatedClass = document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass.classList.remove("fadeVisibleOut");
    
        onLoadAnimate();
      }





    return (
        <>

      <div id="backdrop" className="backdrop w-100 h-100 p-d-none"></div>
      <div
        id="dashboardConversationBtn"
        className="dahboardPopupBtns h-100 w-100 p-d-none p-jc-center p-ai-center p-px-4 p-flex-column fadeVisibleOut"
      >

      </div>

      <div id="rewardWrapper" className="rewardWrapper p-pt-6 p-pb-4">
        <div className="rewardInnerWrapper h-100">
          <div className="rewardWrapperHeading p-text-center">Rewards</div>
          <div
            id="rewardAnimationHead"
            className="reward-animation-head p-py-2"
          >
            +1 {ManageLocalStorage.get("userSelectedQuadrant")}
          </div>
          <div id="levelId">
            <div
              className="level_badge_wrapper p-d-flex p-ai-center levels"
              id="levelCurrent"
            >
              <div className="level-wrapper p-d-flex p-flex-column">
                <span className="level">Level</span>
                <span id="previousLevel" className="level-value">
                  {rewards.prev_level}
                </span>
              </div>
            </div>

            <div
              className="level_badge_wrapper p-d-flex p-ai-center levels"
              id="levelNext"
            >
              <div className="level-wrapper p-d-flex p-flex-column">
                <span className="level">Level</span>
                <span id="latestLevel" className="level-value">
                    {rewards.cur_level}
                </span>
              </div>
            </div>
          </div>

          <div id="badgeId">
            <div
              className="level_badge_wrapper p-d-flex p-ai-center badge"
              id="badgeText"
            >
              <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                <span id="newBadge" className="badge-value">
                  Badge
                </span>
              </div>
            </div>

            <div
              className="level_badge_wrapper p-d-flex p-ai-center badge"
              id="badgeImg"
            >
              <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                <span className="level-value">
                  <img src={badge} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="overlay" className="overlay"></div>
      </div>
      

      <div id="infoWrapper" className="infoWrapper" onClick={(e) => showConversationBtn(e)}>
        <InfoModal content={infoContent} />
      </div>

        <div className="conersation-agents-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
            <div className="inner-page-header">
                <h1 className="p-m-0">Complete</h1>
            </div>

            <div className="inner-page-content complete-page">
                <div className="p-d-flex p-flex-column complete-page-content p-px-3 h-100">
                    <div className="complete-page-text p-d-flex p-jc-center p-flex-column">
                        <p>You have earned</p>
                        <span className="earned-points">
                            <strong>
                                5
                            </strong>
                        </span>
                        <p>experience points</p>
                    </div>
                    <button className="btn" onClick={() => loadRewards()}>Done</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default WithLayout(CompleteScreen)
