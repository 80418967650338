import Amplify from "@aws-amplify/core";
import API from "@aws-amplify/api";

import GetConfigAWS from "../GetAWSConfig";

const ConfigAWS = GetConfigAWS();

const amplifyInit = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: ConfigAWS.cognito.REGION,
      userPoolId: ConfigAWS.cognito.USER_POOL_ID,
      identityPoolId: ConfigAWS.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: ConfigAWS.cognito.APP_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: ConfigAWS.apiGateway.NAME,
          endpoint: ConfigAWS.apiGateway.URL,
          region: ConfigAWS.apiGateway.REGION,
        },
      ],
    },
    Analytics: {
      disabled: true,
    },
  });
  return true;
};

const apiInit = () => {
  API.configure({
    aws_project_region: "us-west-2",
    aws_cognito_identity_pool_id: ConfigAWS.cognito.IDENTITY_POOL_ID,
    aws_cognito_region: ConfigAWS.cognito.REGION,
    aws_user_pools_id: ConfigAWS.cognito.USER_POOL_ID,
    aws_user_pools_web_client_id: ConfigAWS.cognito.APP_CLIENT_ID,
    aws_cloud_logic_custom: [
      {
        name: ConfigAWS.apiGateway.NAME,
        endpoint: ConfigAWS.apiGateway.URL,
        region: ConfigAWS.apiGateway.REGION,
      },
    ],
  });
  return true;
};

const AmplifyConfig = () => {
  amplifyInit();
  apiInit();
};

export default AmplifyConfig;
