import React, { useEffect, useState } from "react";
import experienceIcon from "../../Assets/images/experience.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import agent1 from "../../Assets/images/agent-1.png";
import ccLogo from "../../Assets/images/loader-1.png";
import { getStory } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";



const ConversationQuestionaireContainer = (props) => {
  // console.log(props.match.params.id)

  const [conversation, setConversation] = useState([]);
  const[avatarurl, setAvatarUrl] = useState('');
  const [agent, setAgent] = useState([]);


  useEffect(() => {

    const id = props.match.params.id;
    // console.log(id)

    getStory(id)
    .then((res) => {
      // console.log(res);
      setConversation(res.data);
      setAvatarUrl(ManageLocalStorage.get('avatarUrl'));
      // console.log(res);
    })
    .catch(err=>{
      console.log(err);
    })

    setAgent(JSON.parse(ManageLocalStorage.get("agentDetails")));


    onLoadAnimate()
  }, [])

  const toStory = (id) => {
    props.history.push(`/story/conversation/${id}`);
  }


  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  }

  return (
    <div
      id="ExperiancePageWrapper"
      className="questionaire-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0">Questionaire</h1>
      </div>
      <div className="inner-page-content">
        <div className="questionaire">
          <div className="questionaire-top-quote p-mt-6 p-px-3">
            <div className="questionaire-top-quote-inner p-d-flex p-ai-center">
              <div className="questionaire-quote-person">
                <img src={agent.img_url} alt="agent" />
              </div>
              <h3 className="p-ml-3">{agent.name}</h3>
            </div>
            <h2 className="question-text-format">{conversation['reply']}</h2>
          </div>
          <div className="questionaire-bottom-topic p-py-3">
            <div className="p-text-center">
              <h3 className="questionaire-bottom-head p-mt-0">Where are you on the compass right now?</h3>
            </div>

            <div className="p-d-flex p-px-3">
              <div className="main-topics">
                <ul>
                  {
                    conversation.options? conversation.options.map(item=>(
                        <li key={item.link} onClick={()=>toStory(item.link)}> {item.content} </li>
                      )) : null
                    
                  }
                </ul>
              </div>
              <div className="selected-avatar p-ml-3">
                <div className="selected-avatar-logo">
                  <img src={ccLogo} />
                </div>
                <img src={avatarurl} alt="avatar"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default WithLayout(ConversationQuestionaireContainer);
