import DashboardContainer from "../Modules/Dashboard/DashboardContainer";
import LoginContainer from "../Modules/Login/LoginContainer";
import SignupContainer from "../Modules/Signup/SignupContainer";
import ConfirmContainer from "../Modules/Confirm/ConfirmContainer";
import SplashContainer from "../Modules/Splash/SplashContainer";
import WelcomeContainer from "../Modules/Site/WelcomeContainer";
import AvataarContainer from "../Modules/Site/AvataarContainer";
import LibraryContainer from "../Modules/Site/LibraryContainer";
import ActivityContainer from "../Modules/Site/ActivityContainer";

import TasksContainer from "../Modules/Site/TasksContainer";
import ChatList from "../Modules/Site/ChatList";
import ExperianceContainer from "../Modules/Site/ExperianceContainer";
import ConversationContainer from "../Modules/Site/ConversationContainer";
import ConversationAgentContainer from "../Modules/Site/ConversationAgentContainer";
import ConversationQuestionaireContainer from "../Modules/Site/ConversationQuestionaireContainer";
import WelcomeVideo from "../Modules/Site/welcomeVideo";
import UserManagementContainer from "../Modules/UserManagement/UserManagementContainer";
import BadgeContainer from "../Modules/Site/BadgeContainer";
import IntervieweeContainer from "../Modules/Site/IntervieweeContainer";
import IntervieweQuestionsContainer from "../Modules/Site/InterviewQuestionContainer";
import ProfileContainer from "../Modules/Site/ProfileContainer";
import LinksContainer from "../Modules/Site/LinksContainer";
import QuotesContainer from "../Modules/Site/QuotesContainer";
import DownloadsContainer from "../Modules/Site/DownloadsContainer";
import PastTasksContainer from "../Modules/Site/PastTasksContainer";
import StoryList from "../Modules/Site/StoryList";
import MoreContainer from "../Modules/Site/MoreContainer";
import CompleteScreen from "../Modules/Site/CompleteScreen";
import QuadrantSelectorContainer from "../Modules/Site/QuadrantSelectorContainer";
import ThoughtsContainer from "../Modules/Site/ThoughtsContainer";
import CoachContainer from "../Modules/Site/CoachContainer"
import CoachBioContainer from "../Modules/Site/CoachBioContainer";
import SelectMyCoach from "../Modules/Site/SelectMyCoach";
import DefaultCoachSelected from "../Modules/Site/DefaultCoachSelected";
import PoetryVideo from "../Modules/Site/PoetryVideo";
import CompassContainer from '../Modules/Site/CompassContainer'
import CompassIntoContainer from '../Modules/Site/CompassIntroContainer'
const routes = [
  {
    path: "/login",
    component: LoginContainer,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    component: DashboardContainer,
    isPrivate: false,
  },
  {
    path: "/signup",
    component: SignupContainer,
    isPrivate: false,
  },
  {
    path: "/confirm",
    component: ConfirmContainer,
    isPrivate: false,
  },
  {
    path: "/chat",
    component: ChatList,
    isPrivate: false,
  },
  {
    path: "/compass",
    component: CompassIntoContainer,
    isPrivate: false,
  },
  {
    path: "/compassInto",
    component: CompassIntoContainer,
    isPrivate: false,
  },
  // {
  //   path: "/story",
  //   component: StoryList,
  //   isPrivate: false,
  // },
  {
    path: "/story/conversation/:id",
    component: StoryList,
    isPrivate: false,
  },
  {
    path: "/story/experience/:id",
    component: StoryList,
    isPrivate: false,
  },
  {
    path: "/badge",
    component: BadgeContainer,
    isPrivate: false,
  },
  {
    path: "/splash",
    component: SplashContainer,
    isPrivate: false,
  },
  {
    path: "/interviewee",
    component: IntervieweeContainer,
    isPrivate: false,
  },
  {
    path: "/interview_questions",
    component: IntervieweQuestionsContainer,
    isPrivate: false,
  },
  {
    path: "/welcome",
    component: WelcomeContainer,
    isPrivate: false,
  },
  {
    path: "/poetryvideo",
    component: PoetryVideo,
    isPrivate: false,
  },
  {
    path: "/video",
    component: WelcomeVideo,
    isPrivate: false,
  },
  {
    path: "/activity",
    component: ActivityContainer,
    isPrivate: false,
  },
  {
    path: "/library",
    component: LibraryContainer,
    isPrivate: false,
  },
  {
    path: "/avataar",
    component: AvataarContainer,
    isPrivate: false,
  },
  {
    path: "/changeAvataar",
    component: AvataarContainer,
    isPrivate: false,
  },
  {
    path: "/tasks",
    component: TasksContainer,
    isPrivate: false,
  },
  {
    path: "/past_tasks",
    component: PastTasksContainer,
    isPrivate: false,
  },
  {
    path: "/experience",
    component: ExperianceContainer,
    isPrivate: false,
  },
  {
    path: "/complete-exp",
    component: CompleteScreen,
    isPrivate: false,
  },
  {
    path: "/conversation/:id",
    component: ConversationContainer,
    isPrivate: false,
  },
  {
    path: "/conversation_agent",
    component: ConversationAgentContainer,
    isPrivate: false,
  },
  {
    path: "/quadrant-selector/:id",
    component: QuadrantSelectorContainer,
    isPrivate: false,
  },
  {
    path: "/conversation_questionaire/:id",
    component: ConversationQuestionaireContainer,
    isPrivate: false,
  },
  {
    path: "/profile",
    component: ProfileContainer,
    isPrivate: false,
  },
  {
    path: "/downloads",
    component: DownloadsContainer,
    isPrivate: false,
  },
  {
    path: "/quotes",
    component: QuotesContainer,
    isPrivate: false,
  },
  {
    path: "/thoughts",
    component: ThoughtsContainer,
    isPrivate: false,
  },
  {
    path: "/defaultcoach",
    component: CoachContainer,
    isPrivate: false,
  },
  {
    path: "/coach_bio",
    component: CoachBioContainer,
    isPrivate: false,
  },
  {
    path: "/selectmycoach",
    component: SelectMyCoach,
    isPrivate: false,
  },
  {
    path: "/coach",
    component: DefaultCoachSelected,
    isPrivate: false,
  },
  {
    path: "/links",
    component: LinksContainer,
    isPrivate: false,
  },
  {
    path: "/more",
    component: MoreContainer,
    isPrivate: false,
  },
  {
    path: "/resetpassword",
    component: UserManagementContainer,
    isPrivate: false,
  },
  {
    path: "/",
    component: "",
    isPrivate: false,
  },

];

export default routes;
