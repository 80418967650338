import React, { useEffect } from "react";
import SplashComponent from "./Splash";

const SplashContainer = (props) => {
  useEffect(() => {
    setTimeout(() => {
      setTimePassed();
    }, 5000);
  }, []);
  const setTimePassed = () => {
    splashAction();
  };
  const splashAction = async () => {
    props.history.push("/login"); //navigate to dashboard on success
  };
  return <SplashComponent {...{ componentDidMount: splashAction }} />;
};

export default SplashContainer;
