import React from "react";
import { useGlobalState } from "../../Context";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { userRoles } from "../../Utilities/Utils";
import EndUserDashboard from "./EndUserDashboard";

const DashboardContainer = (props) => {
  return <>{<EndUserDashboard {...props} />}</>;
};

export default WithLayout(DashboardContainer);
