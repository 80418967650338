import React, { useEffect, useState } from "react";
import agent1 from "../../Assets/images/agent-1.png";
import agent2 from "../../Assets/images/agent-2.png";
import agent3 from "../../Assets/images/agent-3.png";
import agent4 from "../../Assets/images/agent-4.png";
import agent5 from "../../Assets/images/agent-5.png";
import agent6 from "../../Assets/images/agent-6.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";

import { coachList, getProfile, getTopics } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const ConversationAgentContainer = (props) => {
    const [coaches, setCoaches] = useState([]);
    const [topicId, setTopicId] = useState();
    const [gameLevel, setGameLevel] = useState("")

    useEffect(() => {
        onLoadAnimate();
        setTopicId(ManageLocalStorage.get("topicId"));
        coachList()
            .then((res) => {
                console.log("coaches_list", res.data.coaches_list);
                setCoaches(res.data.coaches_list);
            })
            .catch((error) => {
                console.log(error);
            });
            getProfile().then((profile) => {
                setGameLevel(profile.data.game.level)
            })
    }, []);

    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const goToConversation = async (coach, topicId) => {
        ManageLocalStorage.set("agentDetails", {
            img_url: coach.img_url,
            name: coach.coach_name,
        });

        await getTopics(ManageLocalStorage.get("tenantId"), coach.id, gameLevel)
            .then((res) => {
                res.data.map((topic) => {
                    if (topic.id == topicId) {
                        ManageLocalStorage.set("selectedTopicDetails", {
                            topic_id: topic.id,
                            audio_url: topic.audio_url,
                            topic_name: topic.topic_name,
                            task_name: topic.task_name,
                            topic_points: topic.topic_points,
                            topic_description: topic.description,
                        });
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });

        props.history.push(`/quadrant-selector/${topicId}`);
    };
    return (
        <>
            <div className="conersation-agents-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
                <div className="inner-page-header">
                    <h1 className="p-m-0">Start a Conversation</h1>
                </div>
                <div className="inner-page-content">
                    <div className="conersation-agents-content p-d-flex p-flex-column p-jc-between">
                        <div className="conersation-agents-wrap p-d-flex p-jc-center p-ai-center">
                            <div className="p-d-flex p-flex-wrap p-jc-center">
                                {coaches.sort((firstItem, nextItem) => firstItem.order > nextItem.order ? 1 : -1).map((coach) => (
                                    <div
                                        key={coach.id}
                                        className="conersation-agent"
                                        onClick={() =>
                                            goToConversation(coach, topicId)
                                        }
                                    >
                                        <div className="conersation-agent-img">
                                            <img
                                                src={coach.img_url}
                                                alt="coach"
                                            />
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="conersation-agent selected" onClick={goToConversation}>
                  <div className="conersation-agent-img">
                    <img src={agent1} />
                  </div>
                </div> */}
                            </div>
                        </div>

                        <div className="conersation-start-text p-px-4 p-text-center">
                            <p>Select someone to start a conversation with.</p>
                            <p>
                                Green means that you have already completed the
                                conversation. New conversations will be updated
                                regularly from the web interface.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default WithLayout(ConversationAgentContainer);
