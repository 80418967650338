import React, { useEffect, useState } from "react";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { getTopics } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const ConversationContainer = (props) => {
    const [topics, setTopics] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [agent, setAgent] = useState([]);

    useEffect(() => {
        onLoadAnimate();
        const coachId = props.match.params.id;
        getTopics(ManageLocalStorage.get("tenantId"), coachId)
            .then((res) => {
                setTopics(res.data);
                setLoaded(true);
            })
            .catch((err) => {
                console.log(err);
                setLoaded(true);
            });
        setAgent(JSON.parse(ManageLocalStorage.get("agentDetails")));
        return () => {
            // Cleanup actions
        };
    }, []);
    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const goToQuadrantSelector = (topic) => {
        props.history.push(`/quadrant-selector/${topic.id}`);
        ManageLocalStorage.set("selectedTopicDetails", {
            topic_id: topic.id,
            audio_url: topic.audio_url,
            topic_name: topic.topic_name,
            task_name: topic.task_name,
            topic_points: topic.topic_points,
            topic_description: topic.description,
        });
    };

    return (
        <div
            id="ExperiancePageWrapper"
            className="experiance-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
            <div className="inner-page-header">
                <h1 className="p-m-0">Start a Conversation </h1>
            </div>
            <div className="inner-page-content">
                <div className="conv-avatar-section">
                    <div className="conv-top-section p-px-3 p-mt-4">
                        <div className="conv-top-section-inner p-d-flex p-flex-column p-jc-center">
                            <div className="conv-top-section-header p-d-flex p-ai-center">
                                <div className="conv-top-section-person">
                                    <img src={agent.img_url} alt="agent" />
                                </div>
                                <h3 className="p-ml-3">{agent.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experience-list p-px-3 p-mt-6">
                    <ul>
                        {/* <li className="active" onClick={goToConversationQuestionair}>
              <span className="experience-name">Racial pay inequality</span>
              <span className="font-weight-bold experience-crs-date">
                Jan 24th
              </span>
            </li> */}
                        {topics.map((topic) => (
                            // topic.story_id ?
                            //   (
                            // <li className="active" key={topic.id} onClick={() => goTotopicQuestionair(topic.story_id)}>
                            <li
                                className="active"
                                key={topic.id}
                                onClick={() => goToQuadrantSelector(topic)}
                            >
                                <span className="experience-name">
                                    {topic.topic_name}
                                </span>
                                {/* <span className="font-weight-bold experience-crs-date">
                      Feb 27th
                    </span> */}
                            </li>
                            // ) : null
                        ))}
                    </ul>
                    <div className="no-topic">
                        {!topics.length && loaded ? (
                            <span className="p-mx-auto">No topics found</span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WithLayout(ConversationContainer);
