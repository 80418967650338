import {Auth} from "aws-amplify";
import axios from "axios";
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
import GetConfigAWS from "../GetAWSConfig";
const ConfigAWS = GetConfigAWS();
function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}
export const getExperiance = async (tenantId, type) => {
    const session = await Auth.currentSession();
    return axios.get(
        ConfigAWS.apiGateway.URL +
            "user/topic-list/" +
            tenantId +
            `?task_name=${type}`,
        {
            headers: {
                Authorization: session.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        }
    );
};

export const getTopics = async (tenantId, coachId, level) => {
    const session = await Auth.currentSession();
    return axios.get(
        ConfigAWS.apiGateway.URL +
            "user/topic-list/" +
            tenantId +
            `?coach_id=${coachId}&lvl=${level}`,
        {
            headers: {
                Authorization: session.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        }
    );
};

export const getStoryListByTopicId = async (topicId) => {
    const session = await Auth.currentSession();
    return axios.get(ConfigAWS.apiGateway.URL + "user/topic/" + topicId, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getStory = async (storyId) => {
    const session = await Auth.currentSession();
    return axios.get(ConfigAWS.apiGateway.URL + "user/story/" + storyId, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const postConversation = async (body) => {
    const session = await Auth.currentSession();
    let user = ManageLocalStorage.get("userDetails");
    user = JSON.parse(user);
    if (user) {
        const requestOptions = {
            sender: user.pool.clientId,
            message: body,
        };

        return axios.post(
            "http://192.168.4.25:5500/user/Chat",
            requestOptions,
            {
                headers: {
                    Authorization: session.idToken.jwtToken,
                    "Content-Type": "application/json",
                },
            }
        );
    }
};

export const getProfile = async () => {
    const session = await Auth.currentSession();
    return axios.get(ConfigAWS.apiGateway.URL + "user/profile", {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getAvatar = async () => {
    const session = await Auth.currentSession();
    return axios.get(ConfigAWS.apiGateway.URL + "user/avatarlist", {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const postAvatar = async (body) => {
    const session = await Auth.currentSession();

    return axios.post(ConfigAWS.apiGateway.URL + "user/pickavatar", body, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const updateReward = async (body) => {
    const session = await Auth.currentSession();

    return axios.post(ConfigAWS.apiGateway.URL + "user/up-reward", body, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getAllDomains = async () => {
    return axios.get(ConfigAWS.apiGateway.URL + "user/domain-list", {});
};

export const getLibrary = async () => {
    const session = await Auth.currentSession();
    return axios.get(ConfigAWS.apiGateway.URL + "user/library", {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getAssets = async (type) => {
    const session = await Auth.currentSession();
    return axios.get(`${ConfigAWS.apiGateway.URL}user/library?type=${type}`, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getInfo = async (type) => {
    const session = await Auth.currentSession();
    return axios.get(`${ConfigAWS.apiGateway.URL}user/info?page=${type}`, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const coachList = async (type) => {
    const session = await Auth.currentSession();
    return axios.get(`${ConfigAWS.apiGateway.URL}user/coach-list`, {
        headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
        },
    });
};

export const getConversationDetails = async (tenand_id, user_level) => {
    const session = await Auth.currentSession();
    return axios.get(
        `${ConfigAWS.apiGateway.URL}user/activity-list/${tenand_id}?lvl=${user_level}`,
        {
            headers: {
                Authorization: session.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        }
    );
};

export const userDefaultCoach = async (coach) => {
    const session = await Auth.currentSession();
    return axios.put(
        `${ConfigAWS.apiGateway.URL}user/coach`,coach,
        {
            headers: {
                Authorization: session.idToken.jwtToken,
                "Content-Type": "application/json",
            },
        }
    );
};

