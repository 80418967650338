import React from 'react';
import share from "../../Assets/images/share.png";
import toast, { Toaster } from 'react-hot-toast';

const ModalContainer = (props) => {

    const shareClick = (e) => {
        e.stopPropagation()
        toast.error('Not available in this version');
    }

    return (
        <div className={`library-modal ${props.modal ? 'slide' : ''}`}>
            <div className="modal-item-wrapper" onClick={() => props.closeModal(false)}>
                <React.Fragment >

                    {
                        props.type == 'badges' ? 
                        <div className="modal-item-image badge-item-image p-mt-4">
                            <div className="image" style={{ backgroundImage: `url("${props.popupItem.image_url}")` }} ></div>
                        </div> 
                        : <div className="modal-item-image library-item-image" style={{ backgroundImage: `url("${props.popupItem.image_url}")` }} />
                    }

                    <div className="modal-item-content p-px-4 p-py-4 p-text-center p-d-flex p-flex-column p-jc-between">

                        <div className="modal-item-desc">
                            {
                                props.type == 'badges' ? 
                                <div>
                                    Badge  {props.popupItem.badeNo}
                                </div> : null
                            }

                            {props.type == 'quotes' ?`"${props.popupItem.description}"` : props.popupItem.description }

                            {
                                props.type == 'quotes' ? <div style={{textAlign: 'right',marginTop: '14px',marginRight: '10px',fontSize: 'larger'}}>
                                    - {props.popupItem.author} </div> : null
                            }

                            {
                               props.type == 'badges' ? 
                               <div className="badge-date p-mt-4 p-mb-3">
                                   Earned: {props.popupItem.earned_date}
                               </div>  : null
                            }
                        </div>

                        <div className="p-d-flex p-jc-center">

                            {
                                (props.type !== 'downloads' && props.type !== 'quotes' && props.type !== 'links') && 
                                <div className="share-icon" onClick={(e)=>shareClick(e)}>
                                    <img src={share} />
                                </div>
                            }

                            {
                                props.type == 'downloads' ? 
                                <button className="btn modal-pop-btn p-px-5"onClick={(e) => props.downloadClick(props.popupItem, e)}>
                                    Download Media
                                </button> : ''
                            }
                            {
                                props.type == 'links' ? 
                                <button className="btn modal-pop-btn p-px-5" onClick={(e) => props.visitUrlClick(props.popupItem, e)}>
                                    Visit Article
                                </button> : ''
                            }

                        </div>
                    </div>

                    <div className="tap-to-close">
                        Tap anywhere to close
                    </div>

                </React.Fragment>
            </div>
            <Toaster position="bottom-center"   reverseOrder={false}
                toastOptions={{
                    // Define default options
                    icon: false,
                    style: {
                        background: '#323232',
                        color: '#fff',
                    }
                }}
            />
        </div>
    )
}

export default ModalContainer
