import React, { useEffect } from "react";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import thinkingImg from "../../Assets/images/thinking-light.png";
import feelingImg from "../../Assets/images/feeling-light.png";
import believingImg from "../../Assets/images/believing-light.png";
import actingImg from "../../Assets/images/acting-light.png";

const InterviewQuestionContainer = (props) => {
  useEffect(() => { }, []);

  return (
    <div
      id="InterviewQuestionsPageWrapper"
      className="interview-questions-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0"> Interview Questions </h1>
      </div>
      <div className="inner-page-content">
        <h4 className="p-text-center interview-sub-title">What do you want to ask Toreeka?</h4>

        <div className="main-topics interview-topics p-px-3">
          <ul>
            <li>
              <div className="p-d-flex p-ai-center p-jc-between">
                What contributes to the income gaps in the district?
                <div className="qn-icon">
                  <img src={actingImg} />
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex p-ai-center p-jc-between">
                What contributes to the income gaps in the district?
                <div className="qn-icon">
                  <img src={thinkingImg} />
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex p-ai-center p-jc-between">
                What contributes to the income gaps in the district?
                <div className="qn-icon">
                  <img src={feelingImg} />
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex p-ai-center p-jc-between">
                What contributes to the income gaps in the district?
                <div className="qn-icon">
                  <img src={believingImg} />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="p-text-center p-px-3">
          <button className="interview-btn btn p-ml-auto p-mr-auto">
            I’m finished interviewing Toreeka
          </button>
        </div>
      </div>
    </div>
  );
};
export default WithLayout(InterviewQuestionContainer);
