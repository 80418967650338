import React, { useEffect, useState } from "react";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import HeaderFooterHOC from "../../Shared/Components/HeaderFooterHOC";
import audioIcon from "../../Assets/images/audio-icon.svg";
import ReactPlayer from 'react-player';



    
import { getInfo, updateReward } from "../../Services/SiteServices";
import badge from "../../Assets/images/badge.png";

const ThoughtsContainer = (props) => {
    const [agent, setAgent] = useState([]);
    const [selectedTopicDetails, setSelectedTopicDetails] = useState([]);
    const [rewards, setRewards] = useState({});
    const [latest, setLatestLevel] = useState(0); // Dynamic values
    const [previous, setPreviousLevel] = useState(0);
    const [infoContent, setInfoContent] = useState("");

    useEffect(() => {
        setSelectedTopicDetails(
            JSON.parse(ManageLocalStorage.get("selectedTopicDetails"))
        );
        setAgent(JSON.parse(ManageLocalStorage.get("agentDetails")));
        console.log(agent);
    }, []);

    // const onLoadAnimate = () => {
    //     const footerClass = document.getElementById("footerLayout");
    //     footerClass.classList.remove("fadeVisibleOut");
    //     footerClass.classList.add("fadeVisibleIn");
    // };

    const getLatestLevel = (level) => {
        setLatestLevel(latest + level);
    };

    const getPreviousLevel = (level) => {
        setPreviousLevel(previous + level);
    };

    const onLoadAnimate = () => {
        setTimeout(logoAnimationSecond, 500);
    };

    //   const showAvataar = () => { //;;
    //     setTimeout(logoAnimationSecond, 500);
    //   };

    //   const logoAnimationFirst = () => { //;;
    //     // 2
    //     console.log("Logo Animation");
    //     // const logoAnimationClass = document.getElementById("logoAnimation");
    //     // logoAnimationClass.classList.add("animated");
    //   };

    const logoAnimationSecond = () => {
        //;;
        // 2.1
        // logoAnimationFirst();
        setTimeout(showBackdrop, 800);
    };

    const showBackdrop = () => {
        //;;
        // 3
        console.log("Show backdrop");
        const backdropClass = document.getElementById("backdrop");
        backdropClass.classList.remove("p-d-none");
        backdropClass.classList.add("p-d-block");
        setTimeout(showRewardWrapper, 100);
    };

    const showRewardWrapper = () => {
        //;;
        // 4
        const rewardWrapperAnimatedClass =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass.classList.add("slide-down");
        console.log("Show reward");
        setTimeout(rewardAnimationWrapper, 500);
    };

    const rewardAnimationWrapper = () => {
        //;;
        // 5
        const rewardAnimatedClass = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass.classList.add("animationPrev");
        setTimeout(levelClassWrapper, 800);
    };

    const levelClassWrapper = () => {
        //;;
        // 6
        const rewardAnimatedLevelClass =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass.classList.add("animationPrev");
        setTimeout(animationOverlayLevelStart, 800);
    };

    const animationOverlayLevelStart = () => {
        //;;
        // 7
        const rewardAnimatedClass = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass.classList.add("fadeVisibleOutOnly");
        overlayEnabled();
        setTimeout(animationOverlayLevelEnd, 900);
    };

    const BadgeClassWrapper = () => {
        //;;
        const rewardAnimatedBadgeClass2 = document.getElementById("levelNext");
        rewardAnimatedBadgeClass2.classList.add("animationNext");
        rewardAnimatedBadgeClass2.classList.add("p-d-none");
        overlayEnabled();
        const badgeTextAnimationClass = document.getElementById("badgeText");
        badgeTextAnimationClass.classList.add("animationPrev");
        setTimeout(animationOverlayBadgeStart, 900);
    };

    const animationOverlayBadgeStart = () => {
        //;;
        overlayEnabled();
        setTimeout(animationBadgeOverlayEnd, 900);
    };

    const overlayEnabled = () => {
        //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass.classList.add("enabled");
    };
    const overlayDisabled = () => {
        //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass.classList.remove("enabled");
    };

    const animationOverlayLevelEnd = () => {
        //;;
        overlayDisabled();
        const rewardAnimatedLevelClass =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass.classList.remove("animationPrev");
        rewardAnimatedLevelClass.classList.add("animationNext");
        const rewardAnimatedLevelClass1 = document.getElementById("levelNext");
        rewardAnimatedLevelClass1.classList.add("animationPrev");
        setTimeout(overlayEnabled, 900);
        // setTimeout(BadgeClassWrapper, 1300);
        setTimeout(rewardWrapper, 1800);
        setTimeout(overlayDisabled, 1800);
    };

    const animationBadgeOverlayEnd = () => {
        //;;
        overlayDisabled();
        const rewardAnimatedBadgeClass = document.getElementById("badgeText");
        rewardAnimatedBadgeClass.classList.remove("animationPrev");
        rewardAnimatedBadgeClass.classList.add("animationNext");
        const rewardAnimatedBadgeClass1 = document.getElementById("badgeImg");
        rewardAnimatedBadgeClass1.classList.add("animationPrev");
        setTimeout(overlayEnabled, 1300);
        setTimeout(rewardWrapper, 1800);
        setTimeout(overlayDisabled, 1800);
    };
    //   const dashboardAvatarBGWrapper = () => { //;;
    //     const dashboardAvatarBGClass = document.getElementById("dashboardAvatarBG");
    //     dashboardAvatarBGClass.classList.remove("fadeVisibleOutOnly");
    //     dashboardAvatarBGClass.classList.add("fadeVisibleInOnly");
    //     // setTimeout(infoWrapper, 600);
    //   };

    const rewardWrapper = () => {
        //;;
        const rewardWrapperAnimatedClass =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass.classList.add("fadeVisibleOut");
        // setTimeout(dashboardAvatarBGWrapper, 800);
        setTimeout(infoWrapper, 500);
    };

    const infoWrapper = () => {
        //;;
        //  const infoWrapperAnimatedClass = document.getElementById("infoWrapper");

        if (ManageLocalStorage.get("popupShown")) {
            getInfo("welcome")
                .then((res) => {
                    if (res.data) {
                        setInfoContent(res.data[0].content);
                        //  infoWrapperAnimatedClass.classList.add("slideUp");
                    }
                    props.history.push("/dashboard");
                })
                .catch((err) => {
                    console.log(err);
                });
                
            // infoWrapperAnimatedClass.classList.add("slideUp");
        } else {
            //    infoWrapperAnimatedClass.classList.remove("slideUp");
            const backdropClass = document.getElementById("backdrop");
            backdropClass.classList.add("p-d-none");
            backdropClass.classList.remove("p-d-block");
            props.history.push("/dashboard");
            //   showFooterOnSkip()
        }
    };

    const goToCompleteExperience = () => {
        if(ManageLocalStorage.get("completed") === "0"){
            const rewardObj = {
                tenant_id: ManageLocalStorage.get("tenantId"),
                activity_id: selectedTopicDetails.topic_id,
                type: "topic",
                quadrant: ManageLocalStorage.get("userSelectedQuadrant"),
                points: "1",
            };
            onLoadAnimate();
            updateReward(rewardObj)
                .then((res) => {
                    setRewards(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }else{
            // Updating reward with 0 points and showing popup if story already completed
            const rewardObj = {
                tenant_id: ManageLocalStorage.get("tenantId"),
                activity_id: selectedTopicDetails.topic_id,
                type: "topic",
                quadrant: ManageLocalStorage.get("userSelectedQuadrant"),
                points: "0",
            };
            onLoadAnimate();
            updateReward(rewardObj)
            .then((res) => {
                setRewards(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
            props.history.push("/dashboard");
        } 
    };

    return (
        <div
            id="ExperiancePageWrapper"
            className="questionaire-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
            <div id="backdrop" className="backdrop w-100 h-100 p-d-none"></div>
            <div id="rewardWrapper" className="rewardWrapper p-pt-6 p-pb-4">
                <div className="rewardInnerWrapper h-100">
                    <div className="rewardWrapperHeading p-text-center">
                        Rewards
                    </div>
                    <div
                        id="rewardAnimationHead"
                        className="reward-animation-head p-py-2"
                    >
                        +1 {ManageLocalStorage.get("userSelectedQuadrant")}
                    </div>
                    <div id="levelId">
                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center levels"
                            id="levelCurrent"
                        >
                            <div className="level-wrapper p-d-flex p-flex-column">
                                <span className="level">Level</span>
                                <span
                                    id="previousLevel"
                                    className="level-value"
                                >
                                    {rewards.prev_level}
                                </span>
                            </div>
                        </div>

                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center levels"
                            id="levelNext"
                        >
                            <div className="level-wrapper p-d-flex p-flex-column">
                                <span className="level">Level</span>
                                <span id="latestLevel" className="level-value">
                                    {rewards.cur_level}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id="badgeId">
                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center badge"
                            id="badgeText"
                        >
                            <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                                <span id="newBadge" className="badge-value">
                                    Badge
                                </span>
                            </div>
                        </div>

                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center badge"
                            id="badgeImg"
                        >
                            <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                                <span className="level-value">
                                    <img src={badge} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" className="overlay"></div>
            </div>

            <div className="inner-page-header">
                <h1 className="p-m-0">Thoughts</h1>
            </div>
            <div className="inner-page-content">
                <div className="questionaire h-100 p-d-flex p-flex-column">
                    <div className="conv-avatar-section">
                        <div className="conv-top-section quadrant-section p-px-3 p-pb-4">
                            <div className="conv-top-section-inner p-d-flex p-flex-column">
                                <div className="conv-top-section-header p-d-flex p-ai-center p-jc-between">
                                    <div className="p-d-flex">
                                        <div className="conv-top-section-person">
                                            <img
                                                src={agent.img_url}
                                                alt="agent"
                                            />
                                        </div>
                                        <h3 className="p-ml-3">Coach {agent.name}</h3>
                                    </div>

                                    <style jsx='true'>
                                        {
                                            `
                                            .audio-player {
                                                width: 100%;
                                                height: auto
                                            }
                                            .audio-player > div:first-child{
                                                width: 210px !important;
                                                height: 60px !important;
                                            }
                                            `
                                        }
                                    </style>

                                    <div className="audio-icon p-mr-3">
                                        {/* <img src={audioIcon} /> */}
                                        {
                                            selectedTopicDetails.audio_url ? 
                                            <div className="audio-player">
                                                <ReactPlayer url={selectedTopicDetails.audio_url} controls={true} style={{width:'300px', height: '75px'}}/> 
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="p-px-3 p-text-center p-mt-3 conv-top-section-quote">
                                    {selectedTopicDetails.topic_description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conv-question-section p-py-3 p-px-4 p-text-center">
                        Share your thoughts on this conversation
                    </div>
                    <div className="conv-thoughts-textarea p-py-3 p-px-3 p-text-center">
                        <textarea
                            name="feedbackArea"
                            className="w-100"
                            id="feedbackArea"
                            cols="20"
                            rows="6"
                            placeholder="Tap here to start typing"
                        ></textarea>
                        <button
                            className="btn p-px-5 p-mt-2"
                            onClick={() => goToCompleteExperience()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderFooterHOC(ThoughtsContainer);
