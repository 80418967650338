export const TEXT_LABELS = {
  commonLables: {
    fetchingData: "Fetching Data",
    noRecords: "No Records!",
    select: "Select",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    reset: "Reset and Save",
    update: "Update",
    reqField: "This field is required",
    allReqField: "All fields are required",
    uniqueField: "Field names must be unique",
    confirmation: "Confirmation",
  },
  fieldNames: {
    tenant: {
      companyName: "tenant_name",
      email: "email",
      projects: "project_name",
      subscriptionType: "subscription_plan",
      startDate: "subscription_valid_from",
      endDate: "subscription_valid_until",
      subscriptionPeriodLeft: "sub_period_left",
      status: "status",
      planId: "plan_id",
      projectId: "project_id",
      plan_amount: "plan_amount",
      download_delay: "download_delay",
      container_idle_time: "container_idle_time",
      use_uploader: "use_uploader",
      uploader_start_api: "uploader_start_api",
      uploader_data_api: "uploader_data_api",
      uploader_end_api: "uploader_end_api",
      uploader_workers: "uploader_workers",
      uploader_url_to_pdf_workers: "uploader_url_to_pdf_workers",
      api_request_timeout: "api_request_timeout",
    },
    site: {
      project: "website_name",
      siteId: "id",
      urlToScrape: "website_url",
      scrapingStatus: "spider_status",
      project_id: "project_id",
      scraping_time: "scraping_time",
      uploader_time: "uploader_time",
    },
    plan: {
      plan_name: "plan_name",
      number_of_urls: "number_of_urls",
      number_of_days: "number_of_days",
      number_of_containers: "number_of_containers",
      plan_amount: "plan_amount",
    },
    project: {
      project_name: "project_name",
      description: "description",
    },
  },
  loginModule: {
    userName: "User Name",
    pwd: "Password",
    logIn: "Login",
    forgotPwd: "Forgot Password",
    signup: "Sign Up",
    required: {
      userName: "User Name Required",
      pwd: "Password Required",
    },
  },
  SignupModule: {
    email: "Email",
    domain_name: "Domain name",
    signup: "Sign Up",
    phone_number: "Phone Number",
    userName: "User Name",
    phone_number: "Phone number",
    confirm: "Confirm",
    code: "Verification Code",
    nickname: "Name",
    gender: "Gender",
    custom: {
      user_role: "User role",
      app: "App",
    },
  },
  userManagementModule: {
    email: "Email",
    code: "Code",
    pwd: "Password",
    confirmPwd: "Confirm Password",
    sendCode: "Send Code",
    resetPwd: "Reset Password",
    codehelp: "Please check your email for the code.",
    required: {
      email: "Email Required",
      domain_name: "Domain name",
      phone_number: "Phone number Required",
      nickname: "Nick name Required",
      gender: "Gender Required",
      code: "Verification Code Required",
      emailInvalid: "Please enter a valid email",
      pwd: "Password Required",
      confirmPwd: "Confirm Password Required",
      pwdMisMatch: "Password and Confirm Password should be same",
    },
  },
  tenantModule: {
    addTenant: "Add Tenant",
    tenantconfig: "Tenant Configuration update",
    editTenant: "Edit Tenant",
    companyName: "Company Name",
    subscriptionType: "Subscription Type",
    plan: "Plan",
    projects: "Categories",
    project: "Category",
    startDate: "Start Date",
    subscriptionPeriodLeft: "Subscription Period Left",
    plan_amount: "Plan Price ($)",
    status: "Status",
    name: "Name",
    email: "Email",
    planStartDate: "Plan Start Date",
    planEndDate: "Plan End Date",
    download_delay: "Download Delay (Sec)",
    container_idle_time: "Container Idle Time (Sec)",
    use_uploader: "Use Uploader",
    uploader_start_api: "Start Scan Callback",
    uploader_data_api: "Data Upload Callback",
    uploader_end_api: "End Scan Callback",
    uploader_workers: "Uploader Workers",
    uploader_url_to_pdf_workers: "Uploader URL To PDF Workers",
    api_request_timeout: "API Request Timeout (Sec)",
    requiredAddFrom: {
      name: "Name Required",
      email: "Email Required",
      emailInvalid: "Please enter a valid email",
      subType: "SubscriptionType Required",
      startDate: "Start Date Required",
      endDate: "End Date Required",
      plan: "Plan Required",
      project: "Category Required",
      download_delay: "Download Delay Required",
      container_idle_time: "Container Idle Time Required",
      use_uploader: "Use Uploader Required",
      uploader_start_api: "Start Scan API Required",
      uploader_data_api: "Data Upload API Required",
      uploader_end_api: "End Scan API Required",
      uploader_workers: "Uploader Workers Required",
      uploader_url_to_pdf_workers: "Uploader URL To Pdf Workers Required",
      api_request_timeout: "API Request Timeout Required",
    },
  },
  siteModule: {
    addSite: "Website",
    project: "Website",
    siteId: "website ID",
    urlToScrape: "URL To Scrape",
    scrapingStatus: "Status",
    uploader_time: "Uploader Time",
    scraping_time: "Scraping Time",
    draftBtnPublish: "Publish",
    draftBtnRepublish: "Republish",
    startScrapBtnLabel: "Start Scraping",
    startScrapAllBtnLabel: "Start Scrape All",
    publishAll: "Publish",
    errLogBtnLabel: "View Error Log",
    genErrLogLabel: "Generate Error Log",
    siteSearchPlaceholder: "Search",
    downloadDelay: "Download Delay(Sec)",
    requiredFormField: {
      url: "website URL is required",
      site_name: "website name is required",
      project: "Category is required",
      downLoadDelay: "Download Delay is required",
      downLoadDelayValidation:
        "Download Delay must be between .25sec and 300sec",
    },
    helpText: {
      downloadDelay:
        "Please modify this field if required. The value should be a decimal which indicates the time in seconds to wait between subsequent hits to the same domain. The value provided will be considered as the max delay.",
    },
  },
  settingsModule: {
    tenantTabLabels: {
      commonTab: "Common Settings",
      ScrapingTab: "Scraping Config",
      outputTab: "Output Config",
      scheduleTab: "Schedule Scraping",
      rewriteTemplateMsg:
        "This will overwrite all customizations made, with the latest available master template. Are you sure wants to continue?",
    },
    projectListing: {
      fields: {
        project_name: "project_name",
        description: "description",
      },
      fieldLabel: {
        project_name: "Category Name",
        description: "Description",
      },
    },
    sceduleListing: {
      fields: {
        project_name: "project_name",
        schedule: "schedule",
        last_run: "last_run",
        next_run: "next_run",
        enable_schedule: "enable_schedule",
      },
      fieldLabel: {
        project_name: "Category",
        schedule: "Schedule",
        last_run: "Last Run",
        next_run: "Next Run",
        enable_schedule: "Enable Schedule",
      },
    },
    plan: {
      heading: "Add New Plan",
      requiredField: {
        plan_name: "Plan name required",
        number_of_urls: "No of URLS is required",
        number_of_days: "Duration is required",
        storage: "Storage field is required",
        number_of_containers: "Containers field is required",
        plan_amount: "Plan price is required",
      },
      label: {
        plan_name: "Plan Name",
        number_of_urls: "Number of URLs",
        number_of_days: "Contract duration",
        storage: "Storage",
        number_of_containers: "No. of containers",
        plan_amount: "Plan Price",
      },
    },
    project: {
      heading: "Add New Category",
      heading_edit: "Edit Category",
      requiredField: {
        project_name: "Category name required",
        description: "Description is required",
      },
      label: {
        project_name: "Category Name",
        description: "Description",
      },
    },
  },
};
