import React from "react";
import { useGlobalState } from "../../Context";
import SignupComponent from "./Signup";
import { signUp } from "../../Services/UsermanagementServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const SignupContainer = (props) => {
  const SignupAction = async (
    nickname,
    email,
    phone_number,
    gender,
    domain_name,
    password
  ) => {
    try {
      await signUp(nickname, email, phone_number, gender, domain_name, password); //Signup action makes the request and handles all the neccessary state changes
      ManageLocalStorage.set("temp_email", email)
      props.history.push("/confirm");
    } catch (error) {
      // console.log(error);
      if (
        error.code === "NotAuthorizedException" ||
        error.code === "UserNotFoundException" || 
        error.code === "InvalidParameterException" ||
        error.code === "UsernameExistsException"
      ) {
        props.showToast("error", "Error", error.message);
      } else if (error.code === "NetworkError") {
        props.showToast(
          "error",
          "Error",
          "Please check your network connection"
        );
      } else {
        props.showToast("error", "Something went wrong please try again");
      }
    }
  };

  const gotoLoginOnClick = () => {
    props.history.push("/login");
  };
  return (
    <SignupComponent
      {...{
        onSignupClick: SignupAction,
        gotoLogin: gotoLoginOnClick,
      }}
    />
  );
};

export default SignupContainer;
