import React from "react";

const FormValidationMessageComponent = (props) => {
    return (
        <React.Fragment>
            {
                props.data.isShow && (props.data.isError) ?
                    <small className="p-invalid p-d-block">{props.data.msg}</small> 
                    :
                    ""
            }
        </React.Fragment>
    );
};
export default FormValidationMessageComponent;