import React from "react";
import PropTypes from "prop-types";

import "../../Assets/css/login.module.scss";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import WithLayout from "../../Shared/Components/HeaderHOC";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation } from "../../Utilities/Utils";
import { trackPromise } from "react-promise-tracker";
import { InputText } from "primereact/inputtext";

const Login = (props) => {
  const { SignUpOnClick, forgotPasswordOnClick, onLoginClick } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [formObj, setFormObj] = React.useState({ username: "", password: "" });
  const [passwordShown, setPasswordShown] = React.useState(false);

  const onLoginSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (formObj.username && formObj.password) {
      trackPromise(onLoginClick(formObj.username, formObj.password));
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const setInput = (event) => {
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };

  return (
    <>
      <div className="h-100  p-d-flex p-jc-center p-ai-center">
        <div className="login-box w-100 p-px-3 p-pt-4 p-pb-6 p-m-auto">
          <form onSubmit={(e) => onLoginSubmit(e)}>
            <div className="p-field p-mb-2">
              <span className="p-float-label">
                <InputText
                  id="login_username"
                  name="username"
                  type="text"
                  value={formObj.username}
                  required
                  autoFocus
                  className="w-100"
                  onChange={setInput}
                  autoComplete="off"
                />
                <label htmlFor="login_username">
                  Email *
                </label>
              </span>

              {submitted && !formObj.username
                ? showFormValidation(
                    true,
                    true,
                    'Email required'
                  )
                : showFormValidation(false)}
            </div>
            <div className="p-field p-mb-2">
              {/* <label htmlFor="login_pwd">{TEXT_LABELS.loginModule.pwd} *</label> */}

              <span className="p-float-label password-text-erap">
                <InputText
                  id="login_pwd"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={formObj.password}
                  autoComplete="off"
                  required
                  className="w-100"
                  onChange={(e) => setInput(e)}
                />
                <i onClick={togglePasswordVisiblity}  className={passwordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
                <label htmlFor="login_pwd">{TEXT_LABELS.loginModule.pwd}</label>
              </span>

              {/* <InputTextWrapper
                id="login_pwd"
                type="password"
                name="password"
                value={formObj.password}
                required
                onChange={(e) => setInput(e)}
              /> */}
              {submitted && !formObj.password
                ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.loginModule.required.pwd
                  )
                : showFormValidation(false)}
            </div>
            <div className="p-field p-mb-0 p-mt-3 p-text-center">
              <ButtonWrapper
                className="login-btn p-px-6 btn w-100"
                label={TEXT_LABELS.loginModule.logIn}
                onClick={(e) => onLoginSubmit(e)}
              />
              <div
                className="p-text-center p-mx-auto p-mt-3 anchor-link"
                onClick={(e) => forgotPasswordOnClick(e)}
              >
                {TEXT_LABELS.loginModule.forgotPwd}
              </div>

              <div className="or-text p-mt-3">
                OR
              </div>

              <button
                className="btn btn-primary p-mt-3 p-px-4 sign-up-btn"
                onClick={(e) => SignUpOnClick(e)}
              >
                {TEXT_LABELS.loginModule.signup}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  forgotPasswordOnClick: PropTypes.func,
  SignUpOnClick: PropTypes.func,
};

export default WithLayout(Login);
