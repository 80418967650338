import React from "react";
import "../../Assets/css/login.module.scss";
import splashImage from "../../Assets/images/splash-logo.png";
import WithHeaderLayout from "../../Shared/Components/HeaderHOC";

const Splash = (props) => {
  return (
    <>
      <div className="splash-screen p-d-flex p-jc-center p-ai-center" id="splashScreen">
        <img src={splashImage} />
      </div>
    </>
  );
};

export default WithHeaderLayout(Splash);
