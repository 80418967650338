import { Auth } from "aws-amplify";
import axios from "axios";
import GetConfigAWS from "../GetAWSConfig";
const ConfigAWS = GetConfigAWS();

export const actions = {
  sendCode: "Send Code",
  resetPwd: "Reset Password",
};
const sendCode = (email) =>
  Auth.forgotPassword(email).then((response) => response);
const resetPwd = (email, code, Password) =>
  Auth.forgotPasswordSubmit(email, code, Password).then((response) => response);

export const forgotPwdSendCode = async (email) => {
  return await sendCode(email);
};

export const forgotPwd = async (formObj) => {
  return resetPwd(formObj.email, formObj.code, formObj.password);
};

export const tenantActiveCheck = async() => {
  const session = await Auth.currentSession();
  return axios.get(`${ConfigAWS.apiGateway.URL}user/checktenant`, {
    headers: {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json"
    },
  });
}

export const signUp = async (
  nickname,
  email,
  phone_number,
  gender,
  domain_name,
  password
  ) => {
  return Auth.signUp({
    username: email,
    password: password, // Default password needs to be reset
    attributes: {
      name: email,
      email,
      gender : 'male',
      nickname,
      "custom:phone": phone_number,
      "custom:user_role": "end_user",
      "custom:app": "end_user",
    },
  }).then((response) => {
    if (response) {
      const reqObj = {
        tenant_id: domain_name,
        user_id: response.userSub,
        user_name: email,
        nickname,
        gender: 'male',
        email,
        phone_number: phone_number,
        created_on: new Date(),
      };

      axios.post(ConfigAWS.apiGateway.URL + "user/signup", reqObj, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      });
    }
    return response;
  });
};

export const confirm = async (username, code) => {
  return Auth.confirmSignUp(username, code);
};

export const signout = async () => {
  return Auth.signOut();
};
