import React, { Component } from "react";
import "../../Assets/css/avatar.scss";
import WithLayout from "../../Shared/Components/HeaderHOC";
// import avatarOne from "../../Assets/images/avatar-1.png";
// import avatarTwo from "../../Assets/images/avatar-2.png";
// import avatarThree from "../../Assets/images/avatar-3.png";
// import avatarFour from "../../Assets/images/avatar-4.png";
import { getAvatar, updateReward } from "../../Services/SiteServices";
import { getProfile } from "../../Services/SiteServices";
import { postAvatar } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

class AvataarContainer extends Component {
  constructor() {
    super();
    this.state = {
      avatars : [],
      avatarExist : true
    };
    
    ManageLocalStorage.delete("popupShown");
    this.profileDetails();
    this.courageousAvatar();
  }
  profileDetails = () => {
    getProfile().then((profile) => {
      // console.log(profile);
      // console.log(profile);
      console.log(profile);
      profile.data.avatar.url === '' && 
      this.setState({
        avatarExist: false
      })
      ManageLocalStorage.set(
        "popupShown",
        profile.data.avatar.popup_shown ? 1 : 0
      );
      ManageLocalStorage.set("tenantId", profile.data.tenant_id);
      // if(profile.data.avatar.url !== "") {
      //   this.props.history.push('/dashboard')
      // }
    });
  };

  courageousAvatar = () => {
    getAvatar().then((avtaar) => {
      this.setState({avatars : avtaar.data})
    });

  };

  uploadAvatar = async (popupShown, url, avatarId) => {
    try {
      const avtObj = {
        avatar: {
          popup_shown: popupShown,
          url,
        },
      };
      await postAvatar(avtObj);
    } catch (e) {
      console.log("error........", e);
    }
    !this.state.avatarExist && updateReward(
        {
            "tenant_id":ManageLocalStorage.get("tenantId"),
            "activity_id":avatarId,
            "type":"avatar",
            "quadrant":"acting",
            "points":1
        }
    )
  };

  expandAvatar = (avatarId, avatarUrl) => {

    this.state.avatars.forEach((clId) => {
      if (clId.id !== avatarId)
        document.getElementById(clId.id).classList.add("hide");
    });
    document.getElementById(avatarId).classList.add("zoom");

    document
      .getElementById("avatarPageWrapper")
      .classList.add("avatar-expanded");

    // Call Upload Avatar
    this.uploadAvatar(true, avatarUrl, avatarId);
    ManageLocalStorage.set("avatarUrl", avatarUrl);
    ManageLocalStorage.set("avatarId", avatarId);

    setTimeout(() => {
      this.props.history.push("/dashboard");
    }, 1000);
  };
  render() {
    return (
      <div
        id="avatarPageWrapper"
        className="avataar-page-wrapper h-100 p-d-flex p-flex-column"
      >
        <div className="avatar-image preload">
          {
            this.state.avatars.map((item) => (
              <div key={item.id} id={item.id} onClick={() => this.expandAvatar(item.id, item.img_url)}>
                <img src={item.img_url} />
              </div>
            ))
          }
          
        </div>

        <div className="avatar-info-wrapper">
          <div className="avatar-info p-py-1 p-px-4 p-text-center">
            Pick an avatar
          </div>
        </div>
        <div id="" className="avataar-popup"></div>
      </div>
    );
  }
}

export default WithLayout(AvataarContainer);
