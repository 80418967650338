import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AmplifyConfig from "./Utilities/AmplifyConfig";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import "./Assets/css/chat.scss";
// import "../src/Assets/css/common.css";
AmplifyConfig();

ReactDOM.render(<App />, document.getElementById("root"));
