import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { coachList, getProfile } from "../../Services/SiteServices";
import ButtonWrapper from "../../Shared/Components/Button";
import WithLayout from "../../Shared/Components/HeaderHOC";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import GetConfigAWS from "../../GetAWSConfig";
import { urlLinks } from "../../Assets/urlLinks";
import {useGlobalState} from '../../Context'
const ConfigAWS = GetConfigAWS();

const PoetryVideo = ({ ...props }) => {

    const [enableButton, setEnableButton] = useState(false)
    const [playAudio, setPlayAudio] = useState(true)

    useEffect(() => {
        !JSON.parse(ManageLocalStorage.get('poetrySkiButton')) ?
            setTimeout(() => {
                setEnableButton(true)
                ManageLocalStorage.set("poetrySkiButton", true)
            }, 1000 * 60 * 2) : setEnableButton(true)
            return () => {
            
            }
    }, [])

    const goTovideo = () => {
        props.history.push('/video')
    }
    const { setvolumeDip } = useGlobalState();
    useEffect(() => {
      setvolumeDip(playAudio)
    
      return () => {
        setvolumeDip(false)
      }
    }, [playAudio])
    
    return (
        <div className="welcome-page-wrapper h-100">
            <div className="welcome-video h-100">
                <div className="space-around">
                    <div className="popup-body p-m-auto w-100">
                        <div className="welcome-container">
                            {/* <div className="welcome-container-title p-ml-3">
                                <h3>Glenn E. <br /> Singleton</h3>
                                <h4>Content Lead, <br /> Courageous Labs</h4>
                            </div> */}

                            <ReactPlayer
                                className="react-player"
                                url={ConfigAWS.BASE_URL + urlLinks.poetryVideoLink}
                                // controls={true}
                                playing={playAudio}
                                width="100%"
                                height="700px"
                                onClick={() => setPlayAudio(prev => !prev)}
                                style={{ cursor: 'pointer' }}
                            // light
                            />
                        </div>
                    </div>
                    <div className="p-px-3 p-pt-3 p-pb-3 p-mt-auto w-100">
                        {enableButton && <ButtonWrapper
                            className="login-btn w-100 btn"
                            label="NEXT"
                            id="welcomeButton"
                            onClick={goTovideo}
                        // disabled={!enableButton}
                        />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithLayout(PoetryVideo);
