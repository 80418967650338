import React, { useEffect, useState } from "react";
import "../../Assets/css/task.scss";
import actingImg from "../../Assets/images/acting.png";
import actingCompletedImg from "../../Assets/images/acting-completed.png";
import feelingCompletedImg from "../../Assets/images/feeling-completed.png";
import thinkingCompletedImg from "../../Assets/images/thinking-completed.png";
import believingCompletedImg from "../../Assets/images/believing-completed.png";
import thinkingImg from "../../Assets/images/thinking.png";
import feelingImg from "../../Assets/images/feeling.png";
import believingImg from "../../Assets/images/believing.png";
import userImage from "../../Assets/images/agent-5.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import toast, { Toaster } from "react-hot-toast";
import {
    getConversationDetails,
    getProfile,
    getTopics,
} from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import dateFormat from "dateformat";
import InfoModal from "../../Shared/Components/InfoModal";
const TasksContainer = (props) => {
    const [activeConversation, setActiveConversation] = useState([]);
    const [completedConversation, setCompletedConversation] = useState([]);
    const [gameLevel, setGameLevel] = useState("")
    const [userCoach, setUserCoach] = useState({})
    const [InfoContent, setInfoContent] = useState('')
   


    const infoModalShow = () => {
      console.log('show')
      setInfoContent('Congratulations You Completed All the Active Conversations')
          const infoWrapperAnimatedClass = document.getElementById("infoWrapper2");
                infoWrapperAnimatedClass.classList.add("slideUp");
      
    }
    const hideBtn = (e) => {
      console.log('hide')

      const infoWrapperAnimatedClass = document.getElementById("infoWrapper2");
            infoWrapperAnimatedClass.classList.remove("slideUp");
  
      const backdropClass = document.getElementById("backdrop");
      if(backdropClass) {
            backdropClass.classList.remove("p-d-block");
            backdropClass.classList.add("p-d-none");
      }
          
    }

    useEffect(() => {
        onLoadAnimate();
        let isCancelled = false;
        const tenantId = ManageLocalStorage.get("tenantId");
        getProfile().then((profile) => {
          
          setUserCoach(profile.data.coach)
          setGameLevel(profile.data.game.level)
          getConversationDetails(tenantId, profile.data.game.level)
          .then((conversation) => {
            console.log('iscanceled',isCancelled)
            if(!isCancelled){
  // const convLength = conversation.data.active.length;
                    // if (convLength <= 0) {
                    // } else {
                      setActiveConversation(conversation.data.active);
                      // }
                      if(conversation.data.active?.length==0){
                        infoModalShow()
                      }
                      setCompletedConversation(conversation.data.completed);
            }
                })
                .catch((err) => {
                    console.log("error", err);
                });
        });
        return () => {
          isCancelled=true
        }
    }, []);


    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const goToConversation = async (isCoach, coach_img, coach_name, topicId) => {
        ManageLocalStorage.set("topicId", topicId);
        if (isCoach === "") {
            props.history.push("/conversation_agent");
        } else {
            ManageLocalStorage.set("agentDetails", {
                img_url: coach_img,
                name: coach_name,
            });

            await getTopics(ManageLocalStorage.get("tenantId"), isCoach, gameLevel)
            .then((res) => {
                res.data.map((topic) => {
                    if (topic.id == topicId) {
                        ManageLocalStorage.set("selectedTopicDetails", {
                            topic_id: topic.id,
                            audio_url: topic.audio_url,
                            topic_name: topic.topic_name,
                            task_name: topic.task_name,
                            topic_points: topic.topic_points,
                            topic_description: topic.description,
                        });
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });

            props.history.push(`/quadrant-selector/${topicId}`);
        }
    };

    return (
        <div
            id="TasksPageWrapper"
            className="tasks-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
            <div className="inner-page-header">
                <h1 className="p-m-0">Conversations</h1>
            </div>
            <div className="inner-page-content" id="container">
                <div className="task-list-wrap">
                    <div className="active-tasks tasks">
                        <div className="tasks-header p-py-2 p-px-3">
                            Active Conversations
                        </div>
                        <div className="tasks-content p-px-3  p-py-3">
                          {/* {console.log(completedConversation)} */}
                            <ul className="task-list">
                                {activeConversation &&
                                    activeConversation.length === 0 &&  completedConversation.length <60 &&(
                                        <li>
                                            No Active Conversation Found
                                        </li>
                                    )}

                                    {completedConversation &&  activeConversation?.length === 0 && completedConversation.length>=60 && (<>
                                
                                    <li>
                                    Congratulations! You Completed All The Active Conversations.
                                        </li>
                                  
                                    </>)}
                                {activeConversation.map(
                                    (conversationDetails) => {
                                      if (conversationDetails.default_coach === ""){
                                        conversationDetails.default_coach = userCoach.coach_id
                                        conversationDetails.coach_img = userCoach.img_url
                                        conversationDetails.coach_name = userCoach.name

                                      } 
                                        return (
                                            <li key={conversationDetails.id}>
                                                <div
                                                    className="task-item active-convo p-d-flex p-ai-center"
                                                    onClick={() =>
                                                        goToConversation(
                                                            conversationDetails.default_coach ,
                                                            conversationDetails.coach_img,
                                                            conversationDetails.coach_name,
                                                            conversationDetails.id
                                                        )
                                                    }
                                                >
                                                    <div className="task-userimage">
                                                        <img
                                                            src={
                                                                conversationDetails.coach_img
                                                            }
                                                        />
                                                    </div>
                                                    <div className="active-task-wrapper">
                                                        <div className="task-name">
                                                            {
                                                                conversationDetails.topic_name
                                                            }
                                                        </div>
                                                        <div className="task-btn">
                                                            <span>
                                                                Start Now
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                                {/* <li>
                  <div className="task-item active-convo" onClick={goToConversation}>
                    <div className="task-item-wrapper p-d-flex p-ai-center">
                      <div className="task-userimage">
                        <img src={userImage} />
                      </div>
                      <div className="active-task-wrapper">
                        <div className="task-name">
                          Conversation - Bridge
                        </div>
                        <div className="task-btn">
                          <span>Start Now</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </li>
                <li>
                  <div className="task-item" onClick={goToConversation}>
                    <div className="task-item-wrapper p-d-flex p-ai-center">
                      <div className="task-userimage">
                        <img src={userImage} />
                      </div>
                      <div className="active-task-wrapper">
                        <div className="task-name">
                          Conversation - Police
                        </div>
                        <div className="task-btn">
                          <span>Start Now</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="task-item" onClick={goToConversation}>
                    <div className="task-item-wrapper p-d-flex p-ai-center">
                      <div className="task-userimage">
                        <img src={userImage} />
                      </div>
                      <div className="active-task-wrapper">
                        <div className="task-name">
                          Conversation - Sandwich
                        </div>
                        <div className="task-btn">
                          <span>Start Now</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="completed-tasks tasks p-mt-3">
                        <div className="tasks-header p-py-2 p-px-3">
                            Completed Conversations
                        </div>
                        <div className="tasks-content p-px-3  p-py-3">
                            <ul className="task-list">
                                {completedConversation &&
                                    completedConversation.length === 0 && (
                                        <li>
                                            No Completed Conversations
                                        </li>
                                  )}
                                {completedConversation.map(
                                    (compConveration) => {
                                      if (compConveration.default_coach === ""){
                                        compConveration.default_coach = userCoach.coach_id
                                        compConveration.coach_img = userCoach.img_url
                                        compConveration.coach_name = userCoach.name

                                      } 
                                        return (
                                            <li
                                                className="completed"
                                                key={compConveration.id}
                                            >
                                                <div
                                                    className="task-item p-d-flex"
                                                    onClick={() =>
                                                        goToConversation(
                                                            compConveration.default_coach,
                                                            compConveration.coach_img,
                                                            compConveration.coach_name,
                                                            compConveration.id
                                                        )
                                                    }
                                                >
                                                    <div className="play-again-left-wrap p-d-flex p-ai-center p-jc-between p-px-2 p-py-1">
                                                        <div>
                                                            <div className="task-name">
                                                                {
                                                                    compConveration.topic_name
                                                                }
                                                            </div>
                                                            <div className="date">
                                                                {dateFormat(
                                                                    compConveration.completed_on,
                                                                    "dd mmm yyyy"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-d-flex">
                                                            <div className="task-val p-mr-2">
                                                                +1
                                                            </div>
                                                            <div className="p-d-flex p-flex-column task-type">
                                                                <div className="task-type-img">
                                                                    <img
                                                                        src={
                                                                          compConveration.quadrant === 'thinking' && thinkingCompletedImg ||
                                                                          compConveration.quadrant === 'feeling' && feelingCompletedImg ||
                                                                          compConveration.quadrant === 'acting' && actingCompletedImg ||
                                                                          compConveration.quadrant === 'believing' && believingCompletedImg
                                                                        }
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {
                                                                        compConveration.quadrant
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="task-btn h-100">
                                                            <span>
                                                                Play Again
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}

                                {/* <li className="completed">
                  <div className="task-item p-d-flex" onClick={goToConversation}>

                    <div className="play-again-left-wrap p-d-flex p-ai-center p-jc-between p-px-2 p-py-1">
                      <div>
                        <div className="task-name">
                          Conversation - Sandwich
                        </div>
                        <div className="date">
                          3rd Oct 2021
                        </div>
                      </div>
                      <div className="p-d-flex">
                        <div className="task-val p-mr-2">+1</div>
                        <div className="p-d-flex p-flex-column task-type">
                          <div className="task-type-img">
                            <img src={thinkingCompletedImg} />
                          </div>
                          <span>Thinking</span>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="task-btn h-100">
                        <span>Play Again</span>
                      </div>
                    </div>
                  </div>

                </li>
                <li className="completed">
                  <div className="task-item p-d-flex" onClick={goToConversation}>

                    <div className="play-again-left-wrap p-d-flex p-ai-center p-jc-between p-px-2 p-py-1">
                      <div>
                        <div className="task-name">
                          Conversation - Sandwich
                        </div>
                        <div className="date">
                          3rd Oct 2021
                        </div>
                      </div>
                      <div className="p-d-flex">
                        <div className="task-val p-mr-2">+1</div>
                        <div className="p-d-flex p-flex-column task-type">
                          <div className="task-type-img">
                            <img src={thinkingCompletedImg} />
                          </div>
                          <span>Thinking</span>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="task-btn h-100">
                        <span>Play Again</span>
                      </div>
                    </div>
                  </div>

                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="infoWrapper2" className="infoWrapper" onClick={(e)=>hideBtn(e)}>
          <InfoModal content = {InfoContent} />
      </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                toastOptions={{
                    // Define default options
                    icon: false,
                    style: {
                        background: "#323232",
                        color: "#fff",
                    },
                }}
            />
        </div>
    );
};
export default WithLayout(TasksContainer);
