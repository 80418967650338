import React from "react";
import { loginUser, useGlobalState } from "../../Context";
import LoginComponent from "./Login";

const LoginContainer = (props) => {
  const stateValue = useGlobalState().dispatch; //get the dispatch method from the useDispatch custom hook
  const dispatch = stateValue;
  const loginAction = async (username, password) => {
    try {
      let response = await loginUser(dispatch, { username, password }); //loginUser action makes the request and handles all the neccessary state changes
      console.log('res',response)
      if (response && !response.username) return;
      props.history.push("/welcome"); //navigate to dashboard on success
    } catch (error) {
      if (
        error.code === "NotAuthorizedException" ||
        error.code === "UserNotFoundException"
      ) {
        props.showToast("error", "Error", error.message);
      } else if (error.code === "NetworkError") {
        props.showToast(
          "error",
          "Error",
          "Please check your network connection"
        );
      } else if (error.code === "TENANT_USER_LOGIN") {
        props.showToast("error","Error", error.message);
      } else if (error.code === "TENANT_USER_DISABLED") {
        props.showToast("error","Error", error.message);
      } else {
        props.showToast("error", "Something went wrong please try again");
      }
    }
  };

  const forgotPasswordOnClick = () => {
    props.history.push("/resetpassword");
  };

  const SignUpOnClick = () => {
    props.history.push("/signup");
  };
  return (
    <LoginComponent
      {...{
        onLoginClick: loginAction,
        forgotPasswordOnClick: forgotPasswordOnClick,
        SignUpOnClick: SignUpOnClick,
      }}
    />
  );
};

export default LoginContainer;
