
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { urlLinks } from "../Assets/urlLinks";
import GetConfigAWS from "../GetAWSConfig";
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
import { coachList } from "../Services/SiteServices";
import { GlobalReducer, initialState } from "./Reducer";
import { useHistory } from 'react-router-dom';

const GlobalStateContext = React.createContext();

export function useGlobalState() {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
}
const ConfigAWS = GetConfigAWS();

export const GlobalStateProvider = ({ children }) => {
  const history = useHistory();
  const [user, dispatch] = React.useReducer(GlobalReducer, initialState);
  const [playAudio, setPlayAudio] = useState(false)
  const [bgMusic, setBgMusic] = useState('')
  const [volumeDip, setvolumeDip] = useState(false)
  useEffect(() => {
    const musicUrl = urlLinks.backgroundMusic.map(item => {
      return (
        ConfigAWS.BASE_URL + item
      )
    })
    musicUrl && setBgMusic(musicUrl[Math.floor(Math.random() * musicUrl.length)])
    // setPlayAudio(true)
    return () => {
          
    }
  }, [])

    useEffect(() => {
      coachList()
          .then((res) => {
              ManageLocalStorage.set("coachList",res.data.coaches_list.sort((firstItem, nextItem) => firstItem.order > nextItem.order ? 1 : -1));
          })
          .catch((error) => {
            dispatch({type:'LOGOUT'})
            // history.push(`/login`);
              console.log('er',error);
             
          });

          return () => {
          
          }
  }, []);


  const handleNewSong = (ev) => {
    const musicUrl = urlLinks.backgroundMusic.map(item => {
      return (
        ConfigAWS.BASE_URL + item
      )
    })
    musicUrl && setBgMusic(musicUrl[Math.floor(Math.random() * musicUrl.length)])
    // setPlayAudio(true)

  }


  return (
    <GlobalStateContext.Provider value={{ user: user, dispatch: dispatch, playAudio, setPlayAudio ,volumeDip,setvolumeDip}}>
      <div className="audio-player" style={{ display: 'none' }}>
        <ReactPlayer
          url={bgMusic}
          // controls={true}
          // style={{ width: '300px', height: '75px' }}
          playing={playAudio}
          volume={volumeDip?0.03:0.1}
          // onClick={() => setPlayAudio(prev => !prev)}
          onEnded={handleNewSong}
        />
      </div>
      {children}
    </GlobalStateContext.Provider>
  );
};