import React, { Component } from "react";
import WithLayout from "../../Shared/Components/HeaderHOC";
// import avatarOne from "../../Assets/images/avatar-1.png";
// import avatarTwo from "../../Assets/images/avatar-2.png";
// import avatarThree from "../../Assets/images/avatar-3.png";
// import avatarFour from "../../Assets/images/avatar-4.png";
import { getAvatar } from "../../Services/SiteServices";
import "../../Assets/css/profile-updated.scss";
import { getProfile } from "../../Services/SiteServices";
import { postAvatar } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

class ProfileContainer extends Component {
  constructor() {
    super();
    this.state = {
      avatarOne: "",
      avatarTwo: "",
      avatarThree: "",
      avatarFour: "",
    };
    ManageLocalStorage.delete("popupShown");
    this.profileDetails();
    this.courageousAvatar();
  }
  profileDetails = () => {
    getProfile().then((profile) => {
      // console.log(profile);
      ManageLocalStorage.set(
        "popupShown",
        profile.data.avatar.popup_shown ? 1 : 0
      );
    });
  };

  courageousAvatar = () => {
    getAvatar().then((avtaar) => {
      avtaar.data.forEach((avr) => {
        if (avr.avatar_name === "jasmine")
          this.setState({ avatarOne: avr.url });
        if (avr.avatar_name === "luke") this.setState({ avatarTwo: avr.url });
        if (avr.avatar_name === "max") this.setState({ avatarThree: avr.url });
        if (avr.avatar_name === "sarah") this.setState({ avatarFour: avr.url });
      });
    });
  };

  uploadAvatar = async (popupShown, url) => {
    try {
      const avtObj = {
        avatar: {
          popup_shown: popupShown,
          url,
        },
      };
      await postAvatar(avtObj);
    } catch (e) {
      console.log("error........", e);
    }
  };

  expandAvatar = (avatarId, avatarUrl) => {
    const avatarClasses = [
      "avatarOne",
      "avatarTwo",
      "avatarThree",
      "avatarFour",
    ];
    avatarClasses.forEach((clId) => {
      if (clId !== avatarId)
        document.getElementById(clId).classList.add("hide");
    });
    document.getElementById(avatarId).classList.add("zoom");

    document
      .getElementById("avatarPageWrapper")
      .classList.add("avatar-expanded");

    // Call Upload Avatar
    this.uploadAvatar(true, avatarUrl);
    ManageLocalStorage.set("avatarUrl", avatarUrl);

    setTimeout(() => {
      this.props.history.push("/dashboard");
    }, 1000);
  };
  render() {
    return (
      <div
        id="avatarPageWrapper"
        className="avataar-page-wrapper h-100 p-d-flex p-flex-column"
      >
        <div className="avatar-hor-image preload">
          <div
            id="avatarOne"
            onClick={() => this.expandAvatar("avatarOne", this.state.avatarOne)}
          >
            <img src={this.state.avatarOne} />
          </div>
          <div
            id="avatarTwo"
            onClick={() => this.expandAvatar("avatarTwo", this.state.avatarTwo)}
          >
            <img src={this.state.avatarTwo} />
          </div>
          <div
            id="avatarThree"
            onClick={() =>
              this.expandAvatar("avatarThree", this.state.avatarThree)
            }
          >
            <img src={this.state.avatarThree} />
          </div>
          <div
            id="avatarFour"
            onClick={() =>
              this.expandAvatar("avatarFour", this.state.avatarFour)
            }
          >
            <img src={this.state.avatarFour} />
          </div>
        </div>

        <div className="avatar-info-wrapper">
          <div className="avatar-info p-py-4 p-px-4 p-mt-4 p-text-center">
            Pick an avatar
          </div>
        </div>
        <div id="" className="avataar-popup"></div>
      </div>
    );
  }
}

export default WithLayout(ProfileContainer);
