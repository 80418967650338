import React, { useEffect } from "react";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";

const IntervieweeContainer = (props) => {
  useEffect(() => { }, []);

  return (
    <div
      id="IntervieweePageWrapper"
      className="interviewee-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0"> Select Interviewee </h1>
      </div>
      <div className="inner-page-content">
        <div className="interviewee-content h-100 w-100 p-d-flex p-ai-center">
          <div className="interviewee-row p-d-flex w-100">
            <div className="interviewee-col">
              <div className="interviewee-person p-d-flex p-jc-between p-flex-column interviewee-person-active">
                <div className="interviewee-person-head">
                  Toreeka
                </div>

                <div className="interviewee-person-buttons p-d-flex p-flex-column p-ai-center">
                  <div className="interviewee-btn">
                    Play
                  </div>
                  <div className="interviewee-btn">
                    Mike
                  </div>
                </div>
              </div>
            </div>
            <div className="interviewee-col">
              <div className="interviewee-person p-d-flex p-jc-between p-flex-column">
                <div className="interviewee-person-head">
                  Leslie
                </div>

                <div className="interviewee-person-buttons p-d-flex p-flex-column p-ai-center">
                  <div className="interviewee-btn">
                    Play
                  </div>
                  <div className="interviewee-btn">
                    Mike
                  </div>
                </div>
              </div>
            </div>
            <div className="interviewee-col">
              <div className="interviewee-person p-d-flex p-jc-between p-flex-column">
                <div className="interviewee-person-head">
                  Ceclie
                </div>

                <div className="interviewee-person-buttons p-d-flex p-flex-column p-ai-center">
                  <div className="interviewee-btn">
                    Play
                  </div>
                  <div className="interviewee-btn">
                    Mike
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithLayout(IntervieweeContainer);
