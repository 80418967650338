import React, { useEffect, useState } from "react";
import {
    getProfile,
    getInfo,
    profileDetails,
    updateReward,
} from "../../Services/SiteServices";

import ButtonWrapper from "../../Shared/Components/Button";
import infoIcon from "../../Assets/images/info.svg";
import ccLogo from "../../Assets/images/cc_logo.png";
import badge from "../../Assets/images/badge.png";
import infoPerson from "../../Assets/images/info-bg-person.png";
import "../../Assets/css/dashboard.scss";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import RewardsContainer from "./RewardsContainer";
import InfoModal from "../../Shared/Components/InfoModal";
let profileName;

function EndUserDashboard(props) {
    const [latest, setLatestLevel] = React.useState(0); // Dynamic values
    const [previous, setPreviousLevel] = React.useState(0);
    let [avatarImage, setAvatarImage] = React.useState("");
    let [profileName, setProfileName] = React.useState("");
    const [infoContent, setInfoContent] = React.useState("");
    const [userLevel, setUserlevel] = useState({});

    // const getLatestLevel = (level) => {
    //     setLatestLevel(latest + level);
    // };

    // const getPreviousLevel = (level) => {
    //     let prev = level === 0 ? 0 : level - 1;
    //     setPreviousLevel(prev);
    // };

    const onLoadAnimate = () => {
        setTimeout(showAvataar, 100);
    };

    const popShownSet = () => {
        getProfile().then((profile) => {
            ManageLocalStorage.set(
                "popupShown",
                profile.data.avatar.popup_shown ? 1 : 0
            );
            ManageLocalStorage.set("tenantId", profile.data.tenant_id);
            // if(profile.data.avatar.url !== "") {
            //   this.props.history.push('/dashboard')
            // }
        });
    };

    const showFooterOnSkip = () => {
        const dashBoardClass = document.getElementById(
            "dashboardConversationBtn"
        );
        dashBoardClass?.classList.remove("p-d-flex");
        dashBoardClass?.classList.add("p-d-none");
        dashBoardClass?.classList.add("fadeVisibleOut");
        const footerClass = document.getElementById("footerLayout");
        footerClass?.classList.remove("fadeVisibleOut");
        footerClass?.classList.add("fadeVisibleIn");
        const profileClass = document.getElementById("menuWrapper");
        profileClass?.classList.add("opened");
        const backdropClass = document.getElementById("backdrop");
        backdropClass?.classList.remove("p-d-block");
        backdropClass?.classList.add("p-d-none");

        const footerStaticDiamomdClass = document.getElementById("menuWrapper");
        footerStaticDiamomdClass?.classList.remove("fadeVisibleOut");
        footerStaticDiamomdClass?.classList.add("dashBoardDiamonds");
    };
    const showAvataar = () => {
        //;;
        // 1
        const levelLayerClass = document.getElementById("levelbanner");
        levelLayerClass?.classList.remove("fadeVisibleOut");
        levelLayerClass?.classList.add("fadeVisibleIn");
        const logoAnimationClass = document.getElementById("logoAnimation");
        setTimeout(logoAnimationSecond, 500);
    };

    const logoAnimationFirst = () => {
        //;;
        // 2
        console.log("Logo Animation");
        const logoAnimationClass = document.getElementById("logoAnimation");
        logoAnimationClass?.classList.add("animated");
    };

    const logoAnimationSecond = () => {
        //;;
        // 2.1
        logoAnimationFirst();
        setTimeout(showBackdrop, 1);
    };

    const showBackdrop = () => {
        //;;
        // 3
        console.log("Show backdrop");
        const backdropClass = document.getElementById("backdrop");
        backdropClass?.classList.remove("p-d-none");
        backdropClass?.classList.add("p-d-block");
        // setTimeout(showRewardWrapper, 800);
        setTimeout(animationOverlayLevelEnd, 1);
    };

    const showRewardWrapper = () => {
        //;;
        // 4
        const rewardWrapperAnimatedClass =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass?.classList.add("slide-down");
        console.log("Show reward");
        setTimeout(rewardAnimationWrapper, 800);
    };

    const rewardAnimationWrapper = () => {
        //;;
        // 5
        const rewardAnimatedClass = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass?.classList.add("animationPrev");
        setTimeout(levelClassWrapper, 800);
    };

    const levelClassWrapper = () => {
        //;;
        // 6
        const rewardAnimatedLevelClass =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass?.classList.add("animationPrev");
        setTimeout(animationOverlayLevelStart, 800);
    };

    const animationOverlayLevelStart = () => {
        //;;
        // 7
        const rewardAnimatedClass = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass?.classList.add("fadeVisibleOutOnly");
        overlayEnabled();
        setTimeout(animationOverlayLevelEnd, 800);
    };

    const BadgeClassWrapper = () => {
        //;;
        const rewardAnimatedBadgeClass2 = document.getElementById("levelNext");
        rewardAnimatedBadgeClass2?.classList.add("animationNext");
        rewardAnimatedBadgeClass2?.classList.add("p-d-none");
        overlayEnabled();
        const badgeTextAnimationClass = document.getElementById("badgeText");
        badgeTextAnimationClass?.classList.add("animationPrev");
        setTimeout(animationOverlayBadgeStart, 900);
    };

    const animationOverlayBadgeStart = () => {
        //;;
        overlayEnabled();
        setTimeout(animationBadgeOverlayEnd, 900);
    };

    const overlayEnabled = () => {
        //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass?.classList.add("enabled");
    };
    const overlayDisabled = () => {
        //;;
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass?.classList.remove("enabled");
    };

    const animationOverlayLevelEnd = () => {
        //;;
        overlayDisabled();
        const rewardAnimatedLevelClass =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass?.classList.remove("animationPrev");
        rewardAnimatedLevelClass?.classList.add("animationNext");
        const rewardAnimatedLevelClass1 = document.getElementById("levelNext");
        rewardAnimatedLevelClass1?.classList.add("animationPrev");
        setTimeout(overlayEnabled, 400);
        // setTimeout(BadgeClassWrapper, 1300);
        setTimeout(rewardWrapper, 900);
        setTimeout(overlayDisabled, 900);
    };

    const animationBadgeOverlayEnd = () => {
        //;;
        overlayDisabled();
        const rewardAnimatedBadgeClass = document.getElementById("badgeText");
        rewardAnimatedBadgeClass?.classList.remove("animationPrev");
        rewardAnimatedBadgeClass?.classList.add("animationNext");
        const rewardAnimatedBadgeClass1 = document.getElementById("badgeImg");
        rewardAnimatedBadgeClass1?.classList.add("animationPrev");
        setTimeout(overlayEnabled, 1300);
        setTimeout(rewardWrapper, 1800);
        setTimeout(overlayDisabled, 1800);
    };
    const dashboardAvatarBGWrapper = () => {
        //;;
        const dashboardAvatarBGClass =
            document.getElementById("dashboardAvatarBG");
        dashboardAvatarBGClass?.classList.remove("fadeVisibleOutOnly");
        dashboardAvatarBGClass?.classList.add("fadeVisibleInOnly");
        // setTimeout(infoWrapper, 600);
    };

    const rewardWrapper = () => {
        //;;
        const rewardWrapperAnimatedClass =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass?.classList.add("fadeVisibleOut");
        setTimeout(dashboardAvatarBGWrapper, 8);
        setTimeout(infoWrapper, 210);
    };

    const infoWrapper = () => {
        //;;
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper");

        if (ManageLocalStorage.get("popupShown") !== "1") {
            getInfo("welcome")
                .then((res) => {
                    if (res.data) {
                        setInfoContent(res.data[0].content);
                        infoWrapperAnimatedClass?.classList.add("slideUp");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            // infoWrapperAnimatedClass?.classList.add("slideUp");
        } else {
            infoWrapperAnimatedClass?.classList.remove("slideUp");
            showFooterOnSkip();
        }
    };

    const showConversationBtn = () => {
        const dashBoardClass = document.getElementById(
            "dashboardConversationBtn"
        );
        dashBoardClass?.classList.remove("p-d-none");
        dashBoardClass?.classList.add("p-d-flex");
        dashBoardClass?.classList.add("fadeVisibleIn");
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper");
        infoWrapperAnimatedClass?.classList.remove("slideUp");
    };
    const profileDetails = async () => {
        return getProfile().then((profile) => profile.data);
    };
    useEffect(() => {
        getProfile().then((profile) => {
            const data=profile.data
            if (!data?.avatar?.popup_shown) {
               
                props.history.push("/welcome");
            } else {
                ManageLocalStorage.set("popupShown","1")
                ManageLocalStorage.set("avatarUrl",data?.avatar?.url)
                setAvatarImage(data?.avatar?.url);
            }
            
            const CurrentLevel = profile.data.game
                // profile.data.game.level === 0 ? 1 : profile.data.game.level;
            setUserlevel(CurrentLevel);
        });
        

        if (ManageLocalStorage.get("popupShown") !== "1") {
            profileDetails().then((profile) => {
                console.log(profile)
                ManageLocalStorage.set("tenantId", profile.tenant_id);
                // const CurrentLevel =
                //     // profile.game.level === 0 ? 1 : profile.game.level;
                // setPreviousLevel(CurrentLevel);
                setProfileName(profile.nickname);
                onLoadAnimate();
                // updateReward(
                //     {
                //         "tenant_id":profile.tenant_id,
                //         "activity_id":ManageLocalStorage.get("avatarId"),
                //         "type":"avatar",
                //         "quadrant":"acting",
                //         "points":1
                //     }
                // )
            });
        } else {
            profileDetails().then((profile) => {
                
                setProfileName(profile.nickname);
                ManageLocalStorage.set("tenantId", profile.tenant_id);

                // const CurrentLevel =
                //     profile.game.level === 0 ? 1 : profile.game.level;

                // setPreviousLevel(CurrentLevel);
            });
            // const logoAnimationClass = document.getElementById("logoAnimation");
            // logoAnimationClass?.classList.add("animated");
            setTimeout(logoAnimationFirst, 600);
            const levelLayerClass = document.getElementById("levelbanner");
            levelLayerClass?.classList.remove("fadeVisibleOut");
            levelLayerClass?.classList.add("fadeVisibleIn");
            setTimeout(dashboardAvatarBGWrapper, 800);
            overlayDisabled();
            setTimeout(showFooterOnSkip, 1800);
        }

        return () => {
          
        }
    }, []);

    const loadRewards = () => {
        // 1
        // const levelLayerClass = document.getElementById("levelbanner");
        //       levelLayerClass?.classList.add("fadeVisibleOut");
        //       levelLayerClass?.classList.remove("fadeVisibleIn");
        //       console.log('14')

        // 2
        // const logoAnimationClass = document.getElementById("logoAnimation");
        //       logoAnimationClass?.classList.remove("animated");

        //3
        const backdropClass = document.getElementById("backdrop");
        backdropClass?.classList.add("p-d-none");
        backdropClass?.classList.remove("p-d-block");

        //4
        const rewardWrapperAnimatedClass11 =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass11?.classList.remove("slide-down");

        //5
        const rewardAnimatedClass11 = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass11?.classList.remove("animationPrev");

        //6
        const rewardAnimatedLevelClass12 =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass12?.classList.remove("animationPrev");

        // 7
        const rewardAnimatedClass = document.getElementById(
            "rewardAnimationHead"
        );
        rewardAnimatedClass?.classList.remove("fadeVisibleOutOnly");

        //overlay disbled
        const rewardAnimationOverlayClass = document.getElementById("overlay");
        rewardAnimationOverlayClass?.classList.add("enabled");

        //overlay
        const rewardAnimationOverlayClass13 =
            document.getElementById("overlay");
        rewardAnimationOverlayClass13?.classList.remove("enabled");

        //
        const rewardAnimatedLevelClass =
            document.getElementById("levelCurrent");
        rewardAnimatedLevelClass?.classList.add("animationPrev");
        rewardAnimatedLevelClass?.classList.remove("animationNext");
        const rewardAnimatedLevelClass1 = document.getElementById("levelNext");
        rewardAnimatedLevelClass1?.classList.remove("animationPrev");

        const rewardAnimatedBadgeClass2 = document.getElementById("levelNext");
        rewardAnimatedBadgeClass2?.classList.remove("animationNext");
        rewardAnimatedBadgeClass2?.classList.remove("p-d-none");
        const badgeTextAnimationClass = document.getElementById("badgeText");
        badgeTextAnimationClass?.classList.remove("animationPrev");

        // const rewardAnimatedBadgeClass = document.getElementById("badgeText");
        //       rewardAnimatedBadgeClass?.classList.add("animationPrev");
        badgeTextAnimationClass?.classList.remove("animationNext");
        const rewardAnimatedBadgeClass1 = document.getElementById("badgeImg");
        rewardAnimatedBadgeClass1?.classList.remove("animationPrev");

        const rewardWrapperAnimatedClass =
            document.getElementById("rewardWrapper");
        rewardWrapperAnimatedClass?.classList.remove("fadeVisibleOut");

        // const dashboardAvatarBGClass = document.getElementById("dashboardAvatarBG");
        // dashboardAvatarBGClass?.classList.add("fadeVisibleOutOnly");
        // dashboardAvatarBGClass?.classList.remove("fadeVisibleInOnly");

        // const infoWrapperAnimatedClass = document.getElementById("infoWrapper");
        //       infoWrapperAnimatedClass?.classList.remove("slideUp");

        onLoadAnimate();
    };

    return (
        <div className="dashboardContainer h-100">
            <div
                id="levelbanner"
                className="dashboardUserRewards p-d-flex fadeVisibleOut p-jc-between p-ai-center p-px-5"
            >
                <div className="dashboardUsername">{profileName}</div>
                <div className="dashboardReward p-d-flex p-jc-center">
                    {/* <img src={profileLevel} /> */}

                    <div className="dashboardLevelTextwrap">
                        <div className="dashboardLevelLabel">Level</div>
                        <div className="dashboardLevelValue">{userLevel.level}</div>
                    </div>
                </div>
            </div>

            <div className="dashboardAvatarWrap">
                <div className="dashboardbg w-100 h-100 p-d-flex p-jc-center p-ai-center">
                    <div
                        id="dashboardAvatarBG"
                        className="dashboardAvatarBG fadeVisibleOutOnly animated-bg"
                    ></div>
                    <img src={avatarImage} />
                    <div id="logoAnimation" className="logoAnimation">
                        <img src={ccLogo} />
                    </div>
                </div>
            </div>

            <div id="backdrop" className="backdrop w-100 h-100 p-d-none"></div>
            <div
                id="dashboardConversationBtn"
                className="dahboardPopupBtns h-100 w-100 p-d-none p-jc-center p-ai-center p-px-4 p-flex-column fadeVisibleOut"
            >
                <ButtonWrapper
                    className="login-btn p-px-6 btn w-100"
                    label="Start Conversation"
                    onClick={() => {
                        popShownSet();
                        props.history.push("/tasks");
                    }}
                />

                <div
                    onClick={() => {
                        showFooterOnSkip();
                        popShownSet();
                    }}
                    className="p-mt-3 skip-btn"
                >
                    Skip
                </div>
            </div>

            <div id="rewardWrapper" className="rewardWrapper p-pt-6 p-pb-4 ">
                <div className="rewardInnerWrapper h-100">
                    <div className="rewardWrapperHeading p-text-center">
                        Rewards
                    </div>
                    <div
                        id="rewardAnimationHead"
                        className="reward-animation-head p-py-2"
                    >
                        +1 Acting
                    </div>
                    <div id="levelId">
                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center levels"
                            id="levelCurrent"
                        >
                            <div className="level-wrapper p-d-flex p-flex-column">
                                <span className="level">Level</span>
                                <span
                                    id="previousLevel"
                                    className="level-value"
                                >
                                    {userLevel.level == 0 ? userLevel.level : userLevel.prv_level}
                                </span>
                            </div>
                        </div>

                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center levels"
                            id="levelNext"
                        >
                            <div className="level-wrapper p-d-flex p-flex-column">
                                <span className="level">Level</span>
                                <span id="latestLevel" className="level-value">
                                    {userLevel.level == 0 ? userLevel.level + 1 : userLevel.level}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id="badgeId">
                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center badge"
                            id="badgeText"
                        >
                            <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                                <span id="newBadge" className="badge-value">
                                    Badge
                                </span>
                            </div>
                        </div>

                        <div
                            className="level_badge_wrapper p-d-flex p-ai-center badge"
                            id="badgeImg"
                        >
                            <div className="level-wrapper badge-wrapper p-d-flex p-flex-column">
                                <span className="level-value">
                                    <img src={badge} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>                
                <div id="overlay" className="overlay"></div>
            </div>

            {/* <RewardsContainer latest={latest} previous={previous} badge={badge}/> */}

            {/* <div id="infoWrapper" className="infoWrapper" onClick={(e) => showConversationBtn(e)}>
                <div className="rewardInnerWrapper h-100">
                    <div className="infoWrapperIcon p-text-center p-py-2">
                        <img src={infoIcon} />
                    </div>
                    <div className="infoWrapperContent p-d-flex p-py-2 p-px-2 p-jc-center p-ai-center">
                        More information can be entered here.
                    </div>
                    <div className="infoWrapperFooter p-d-flex p-jc-end p-py-2 p-ai-end">
                        <span>Tap anywhere to close</span>
                        <img src={infoPerson} />
                    </div>
                </div>
            </div> */}

            <div
                id="infoWrapper"
                className="infoWrapper"
                onClick={(e) => showConversationBtn(e)}
            >
                <InfoModal content={infoContent} />
            </div>
        </div>
    );
}

export default EndUserDashboard;
