import React, { useEffect, useState } from "react";
import "../../Assets/css/badges.scss";
import badgeOne from "../../Assets/images/badge-1.png";
import badgeTwo from "../../Assets/images/badge-2.png";
import badgeThree from "../../Assets/images/badge-3.png";
import badgeFour from "../../Assets/images/badge-4.png";
import badgeFive from "../../Assets/images/badge-5.png";
import badgeSix from "../../Assets/images/badge-6.png";
import badgeSeven from "../../Assets/images/badge-7.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import ModalContainer from "./ModalContainer";

const BadgeContainer = (props) => {


  const badgesArray = [
    {
      name: 'CC',
      description : '1st badge',
      badeNo: 1,
      image_url: badgeOne,
      earned_date : '15-05-2021'
    },
    {
      name: 'KU',
      description : '2nd badge',
      badeNo: 2,
      image_url: badgeTwo,
      earned_date : '15-05-2021'
    },
    {
      name: 'BM',
      description : '3rd badge',
      badeNo: 3,
      image_url: badgeThree,
      earned_date : '15-05-2021'
    },
    {
      name: 'CC',
      description : '4th badge',
      badeNo: 4,
      image_url: badgeFour,
      earned_date : '15-05-2021'
    },
    {
      name: 'CC',
      description : '5th badge',
      badeNo: 5,
      image_url: badgeFive,
      earned_date : '15-05-2021'
    },
    {
      name: 'CC',
      description : '6th badge',
      badeNo: 6,
      image_url: badgeSix,
      earned_date : '15-05-2021'
    },
    {
      name: 'CC',
      description : '7th badge',
      badeNo: 7,
      image_url: badgeSeven,
      earned_date : '15-05-2021'
    }
  ]

  useEffect(() => {

    setBadges(badgesArray);
    onLoadAnimate();
    setModalType('badges');

    return () => {
          
    }
  }, []);

  const [badges, setBadges] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [popupItem, setPopUpItem] = useState([]);
  const [modalType, setModalType] = useState();

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  };

  const itemClick= (item) => {
    // console.log(item)
    setPopUpItem(item);
    setShowModal(true)
  }

  return (
    <div
      id="BadgePageWrapper"
      className="badge-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0"> Badge </h1>
      </div>
      <div className="inner-page-content">

        <div className="badge-list p-p-3 p-d-flex p-jc-center">

          <div className="badge-wrap">

            {
              badges.map(item =>(
                <div key={item.badeNo} className="badge-item" onClick={()=>itemClick(item)}>
                  <div className="badge">
                    <img src={item.image_url} />
                  </div>
                </div>
              ))
            }

            {/* <div className="badge-item">
              <div className="badge">
                <img src={badgeOne} />
              </div>
            </div>
            
            <div className="badge-item">
              <div className="badge">

              </div>
            </div>
            <div className="badge-item">
              <div className="badge">

              </div>
            </div>
            <div className="badge-item">
              <div className="badge">
                <img src={badgeOne} />
              </div>
            </div>
            <div className="badge-item">
              <div className="badge">

              </div>
            </div>
            <div className="badge-item">
              <div className="badge">
                <img src={badgeOne} />
              </div>
            </div>
            <div className="badge-item">
              <div className="badge">
                <img src={badgeOne} />
              </div>
            </div>
            <div className="badge-item">
              <div className="badge">

              </div>
            </div>
            <div className="badge-item">
              <div className="badge">

              </div>
            </div> */}

          </div>
        </div>
      </div>

      <ModalContainer type={modalType} modal={showModal} popupItem={popupItem} 
                      closeModal={(value) => setShowModal(value)}/>
    </div>
  );
};
export default WithLayout(BadgeContainer);
