import React, { useEffect, useState } from 'react'
import { ManageLocalStorage } from '../../Services/ManageLocalStorage';
import { coachList, getProfile, userDefaultCoach } from '../../Services/SiteServices';
import WithLayout from '../../Shared/Components/HeaderHOC';
import imagePlaceholder from '../../Assets/images/image-placeholder.png'

const SelectMyCoach = (props) => {
    const [coaches, setCoaches] = useState([]);
    const [defaultCoach, setDefaultCoach] = useState({});
    const [avatar, setAvatar] = useState({})

    useEffect(async() => {
       await setCoaches(JSON.parse(ManageLocalStorage.get('coachList')))

       await getProfile().then((profile) => {
            profile.data.coach && setDefaultCoach(profile.data.coach);
            setAvatar(profile.data.avatar)
        }
        );
    }, []);

    const goToCoach = (id, img, name, data) => {
        console.log(id, img, name, data)
        setDefaultCoach({
            "coach_id": id,
            "img_url": img,
            "name": name
        })
        userDefaultCoach({
            "coach_id": id,
            "img_url": img,
            "name": name
        })
        .then(res =>{
            if(res.status == 201){
                if(ManageLocalStorage.get("popupShown") == "1") {
                    
                    props.history.push("/dashboard");
                } else {
                    props.history.push("/compass");
                }
                if(data.popup_shown) {
                    ManageLocalStorage.set("avatarUrl", data.url);
                    ManageLocalStorage.set("popupShown", "1");
                }
                
            }
        })
    }

    return (
        <>
            <div className="conersation-agents-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
                <div className="conersation-agents-content p-d-flex p-flex-column p-jc-between h-100">
                    <div className="space-around">
                        <div className="popup-body p-my-auto">
                            <div className="conersation-start-text p-px-4 p-text-center">
                                <p className="p-mt-0">
                                    Now that you've met all of our coaches, please choose who will guide you 
                                    through your journey as your coach. You will have an opportunity to meet the
                                    other coaches through some of the conversations.
                                </p>
                                <p>
                                    You can also change your coach during your experience in the Settings menu.
                                </p>
                            </div>
                            <div className="conersation-agents-wrap p-d-flex p-jc-center p-ai-center">
                                <div className="p-d-flex p-flex-wrap p-jc-center">
                                    {coaches?.length > 0 ?
                                        coaches.map((coach) => (
                                            <div
                                                key={coach.id}
                                                className="conersation-agent"
                                                style={{cursor: "pointer", }}
                                                onClick={() => goToCoach(coach.id, coach.img_url, coach.coach_name, avatar)}
                                            >
                                                <div className="conersation-agent-img" style={{border: defaultCoach.coach_id === coach.id && '5px solid #1ACF4D'}}>
                                                    <div className="placeholder-img">
                                                        <img src={imagePlaceholder} />
                                                    </div>
                                                    <img
                                                        src={coach.img_url}
                                                        alt="coach"
                                                    />
                                                </div>
                                            </div>
                                        ))
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithLayout(SelectMyCoach);