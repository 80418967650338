import React from 'react';
import _isFunction from 'lodash/isFunction';
import _cloneDeep from 'lodash/cloneDeep';
import { actions, forgotPwdSendCode, forgotPwd } from '../../Services/UsermanagementServices';
import ForgotPassword from './ForgotPassword';
import { trackPromise } from 'react-promise-tracker';

const UserManagementContainer = props => {
    const _isMounted = React.useRef(false);
    const [action, setAction] = React.useState(actions.sendCode);

    const setStateOnMount = (value, callback) => {
        if (_isMounted.current) {
            _isFunction(callback) && callback(_cloneDeep(value));
        }
    };

    const sendCodeOnClick = (email) => {
        trackPromise(
            forgotPwdSendCode(email).then(() => {
                setStateOnMount(actions.resetPwd, setAction);
            }).catch((error) => {
                if (error.code === 'UserNotFoundException') {
                    props.showToast('error', 'Error', 'User not found');
                } else {
                    props.showToast('error', 'Error', 'Something went wrong please try again');
                }
            })
        );
    };

    const resetPasswordOnClick = (formObj) => {
        return new Promise((resolve) => {
            trackPromise(
                forgotPwd(formObj).then(() => {
                    props.history.push('/login');
                    props.showToast('success', 'Success', 'Password changed Successfully');
                    resolve(true);
                }).catch((error) => {
                    props.showToast('error', 'Error', error.message);
                })
            );
        });
    };

    React.useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        };
    }, []);

    return <ForgotPassword {...{ action, sendCodeOnClick, resetPasswordOnClick }} />;
};

export default UserManagementContainer;
