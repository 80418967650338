import React from "react";

import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation, validateEmail } from "../../Utilities/Utils";
import { actions } from "../../Services/UsermanagementServices";

import WithLayout from "../../Shared/Components/HeaderHOC";
import { InputText } from "primereact/inputtext";

const ForgotPassword = (props) => {
  const { action, sendCodeOnClick, resetPasswordOnClick } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [formObj, setFormObj] = React.useState({
    email: "",
    password: "",
    confirmPwd: "",
    code: "",
  });
  const sendCodeSubmit = () => {
    setSubmitted(true);
    if (formObj.email && validateEmail(formObj.email)) {
      sendCodeOnClick(formObj.email);
      setSubmitted(false);
    }
  };
  const sendCodresetPasswordSubmit = () => {
    setSubmitted(true);
    if (
      formObj.email &&
      formObj.code &&
      formObj.password &&
      formObj.confirmPwd &&
      formObj.password === formObj.confirmPwd
    ) {
      resetPasswordOnClick(formObj).then(() => {
        setSubmitted(false);
      });
    }
  };
  const setInput = (event) => {
    setSubmitted(false);
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };
  const checkPwdSame = () => {
    return formObj.password === formObj.confirmPwd;
  };
  return (
    <>
      <div className="h-100  p-d-flex p-jc-center p-ai-center">
        <div className="login-box w-100 p-px-3 p-pt-4 p-pb-6">
          {action === actions.sendCode ? (
            <>
              <div className="p-field p-mb-3">
                <div className="p-float-label">
                  <InputText
                    id="user_email"
                    name="email"
                    type="text"
                    className="w-100"
                    value={formObj.email}
                    required
                    autoFocus
                    onChange={setInput}
                  />
                  <label htmlFor="user_email">
                    {TEXT_LABELS.userManagementModule.email} *
                  </label>
                </div>
                {submitted && !formObj.email
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.email
                    )
                  : !validateEmail(formObj.email) && submitted
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.emailInvalid
                    )
                  : showFormValidation(false)}
              </div>
              <div className="p-field p-mb-2">
                <ButtonWrapper
                  className="login-btn p-px-6 btn w-100"
                  label={TEXT_LABELS.userManagementModule.sendCode}
                  icon="pi pi-envelope"
                  iconPos="right"
                  onClick={sendCodeSubmit}
                />
              </div>
            </>
          ) : (
            <>
              <div className="p-field p-mb-4">
                <div className="p-float-label">
                  <InputText
                    id="user_code"
                    name="code"
                    aria-describedby="code-help"
                    type="text"
                    value={formObj.code}
                    required
                    autoFocus
                    onChange={setInput}
                    className="w-100"
                  />
                  <label htmlFor="user_code">
                    {TEXT_LABELS.userManagementModule.code}{" "}
                  </label>
                </div>
                <small id="code-help" className="p-text-italic code-help">
                  {TEXT_LABELS.userManagementModule.codehelp}
                </small>
                {submitted && !formObj.code
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.code
                    )
                  : showFormValidation(false)}
              </div>
              <div className="p-field p-mb-3">
                <div className="p-float-label">
                  <InputText
                    id="reset_pwd"
                    type="password"
                    name="password"
                    value={formObj.password}
                    required
                    onChange={setInput}
                    className="w-100"
                  />
                  <label htmlFor="reset_pwd">
                    {TEXT_LABELS.userManagementModule.pwd}
                  </label>
                </div>
                {submitted && !formObj.password
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.pwd
                    )
                  : showFormValidation(false)}
              </div>
              <div className="p-field p-mb-3">
                <div className="p-float-label">
                  <InputText
                    id="reset_confirm_pwd"
                    type="password"
                    name="confirmPwd"
                    value={formObj.confirmPwd}
                    required
                    onChange={setInput}
                    className="w-100"
                  />
                  <label htmlFor="reset_confirm_pwd">
                    {TEXT_LABELS.userManagementModule.confirmPwd}
                  </label>
                </div>
                {submitted && !formObj.confirmPwd
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.confirmPwd
                    )
                  : submitted && !checkPwdSame()
                  ? showFormValidation(
                      true,
                      true,
                      TEXT_LABELS.userManagementModule.required.pwdMisMatch
                    )
                  : showFormValidation(false)}
              </div>
              <div className="p-field p-mb-0 p-mt-3 p-text-center">
                <ButtonWrapper
                  className="login-btn p-px-6 btn w-100"
                  label={TEXT_LABELS.userManagementModule.resetPwd}
                  icon="pi pi-key"
                  iconPos="right"
                  onClick={sendCodresetPasswordSubmit}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WithLayout(ForgotPassword);
