import React, { useEffect, useState } from 'react'
import { ManageLocalStorage } from '../../Services/ManageLocalStorage';
import { coachList } from '../../Services/SiteServices';
import ButtonWrapper from '../../Shared/Components/Button';
import WithLayout from '../../Shared/Components/HeaderHOC';
import imagePlaceholder from '../../Assets/images/image-placeholder.png'


const CoachContainer = (props) => {
    const [coaches, setCoaches] = useState([]);

    useEffect(() => {
        setCoaches(JSON.parse(ManageLocalStorage.get('coachList')))
    }, []);
    

    const goToCoach = () => {
        props.history.push("/coach_bio")
    }

    return (
        <>
            <div className="conersation-agents-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
                <div className="conersation-agents-content p-d-flex p-flex-column p-jc-between h-100">
                    <div className="space-around">
                        <div className="popup-body mt-top-auto">
                            <div className="conersation-start-text p-px-4 p-text-center p-mb-6">
                                <p>
                                    Before we get started, let's meet the Courageous Coaches
                                    who will guide you through your experience.
                                </p>
                            </div>
                            <div className="conersation-agents-wrap p-d-flex p-jc-center p-ai-center">
                                <div className="p-d-flex p-flex-wrap p-jc-center">
                                    {coaches && coaches.map((coach) => (
                                        <div
                                            key={coach.id}
                                            className="conersation-agent"
                                        >
                                            <div className="conersation-agent-img">
                                                <div className="placeholder-img">
                                                    <img src={imagePlaceholder} />
                                                </div>
                                                <img
                                                    src={coach.img_url}
                                                    alt="coach"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="p-px-3 p-pt-3 p-pb-3 w-100 p-mt-auto">
                            <ButtonWrapper
                                className="login-btn w-100 btn"
                                label="Meet the coaches"
                                id="welcomeButton"
                                onClick={goToCoach}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithLayout(CoachContainer);