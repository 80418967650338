import React from 'react';

import infoIcon from "../../Assets/images/info.svg";
import infoPerson from "../../Assets/images/info-bg-person.png";

const InfoModal = (props) => {

    return (
        <>
            <div className="rewardInnerWrapper h-100">
                <div className="infoWrapperIcon p-text-center p-py-2">
                    <img src={infoIcon} />
                </div>
                <div  className="infoWrapperContent p-d-flex p-py-2 p-px-3 p-jc-center p-ai-center" style={{whiteSpace: "preWrap"}}>
                    {props.content}
                </div>
                <div className="infoWrapperFooter p-d-flex p-jc-end p-py-2 p-ai-end">
                    <span>Tap anywhere to close</span>
                    <img src={infoPerson} />
                </div>
            </div>
        </>
    )
}

export default InfoModal
