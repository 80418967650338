import React from "react";
import Header from "./Header";

const WithLayout = (Component) => ({ ...props }) => (
  <div className="main-container wrapper without-footer h-100">
    <div className="container h-100">
      <Header {...props} />
      <div className="page-content">
        {" "}
        <Component {...props} />{" "}
      </div>
    </div>
  </div>
);

export default WithLayout;
