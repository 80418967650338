import React, { useEffect, useState } from "react";
import "../../Assets/css/library.scss";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import  { getAssets } from '../../Services/SiteServices';
import ModalContainer from "./ModalContainer";

const LinksContainer = (props) => {

  const [links, setLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [popupItem, setPopUpItem] = useState([]);
  const [modalType, setModalType] = useState();


  useEffect(() => {
    getAssets('links')
    .then(res => {
        setLinks(res.data);
        setModalType('links');
      }
    )
    .catch(error => {
      console.log(error);
    })
    onLoadAnimate()
  }, []);

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  }

  const itemClick = (item) => {
    setPopUpItem(item);
    setShowModal(true)
  }

  const vistUrl = (item,e) => {
    setShowModal(true)
    e.stopPropagation()
    window.open(item.content_url, '_blank');
   
  }

  return (
    
    <div id="BadgePageWrapper" className="badge-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">

      <div className="inner-page-header">
        <h1 className="p-m-0"> Links </h1>
      </div>

      <div className="inner-page-content">
        <div className="library-item-wrapper">
          <div className="library-content full-list p-px-3  p-py-3">
            <ul className="library-list quotes">
              {
                links.map(item =>(
                  <li key={item.hier} onClick={() =>  itemClick(item)}>
                    <div className="library-item" style={{ backgroundImage: `url("${item.image_url}")` }} />
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <ModalContainer type={modalType} modal={showModal} popupItem={popupItem} 
                      visitUrlClick={(value,e)=>vistUrl(value,e)} closeModal={(value)=>setShowModal(value)} />

    </div>
  );
};
export default WithLayout(LinksContainer);
