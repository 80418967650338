import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import {
  getProfile,
} from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import info from "../../Assets/images/info.svg";
import ButtonWrapper from "../../Shared/Components/Button";

export default function Welcome({ ...props }) {
  

  // const showVideoLayout = () => {
  //   document.getElementById("welcome").className =
  //     "welcome-text h-100 fade-out";
  //   document.getElementById("video").classList.remove("fade-out");
  //   document.getElementById("video").classList.add("fade-in");
  // };

  const showVideoLayout = () => {
    props.history.push("/poetryvideo"); //na
  };

  return (
    <div className="welcome-page-wrapper h-100">
      <div id="welcome" className="welcome-text h-100">
        <div className="space-around">
          <div className="popup-body p-p-3 p-m-auto">
            <p>Welcome to Courageous Conversation</p>
            <p>
              Please create a profile, customize your settings, and complete
              objectives to improve our score.
            </p>
            <p>
              On any screen, press the
              <img src={info} />
              icon for more information.
            </p>
            <p>
              This experience helps transform beliefs and behaviors about race
              using real-world scenarios to make your experience personal and
              meaningful.
            </p>
            <p>Start your experience now</p>
          </div>
          <div className="p-px-3 p-pt-3 p-pb-3 w-100 p-mt-auto">
            <ButtonWrapper
              className="login-btn w-100 btn"
              label="Join the conversation"
              id="videoButton"
              onClick={showVideoLayout}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
