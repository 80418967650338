import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { coachList, getProfile } from "../../Services/SiteServices";
import ButtonWrapper from "../../Shared/Components/Button";
import WithLayout from "../../Shared/Components/HeaderHOC";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import GetConfigAWS from "../../GetAWSConfig";
import { urlLinks } from "../../Assets/urlLinks";
import {useGlobalState} from '../../Context'
const ConfigAWS = GetConfigAWS();

const WelcomeVideo = ({ ...props }) => {

	const [avatar, setAvatar] = useState({});
	const [enableButton, setEnableButton] = useState(false)
	const [playAudio, setPlayAudio] = useState(true)
	const [profile, setprofile] = useState()

	const goToCoach = (data) => {
		if(ManageLocalStorage.get("popupShown") == "1") {
			props.history.push("/dashboard");
		} else {
			props.history.push("/defaultcoach")
		}
		
	}

	useEffect(() => {
		!JSON.parse(ManageLocalStorage.get('welcomeSkipBtn')) ?
			setTimeout(() => {
				setEnableButton(true)
				ManageLocalStorage.set('welcomeSkipBtn', true)
			}, 1000 * 60 * 2) : setEnableButton(true)
	}, [])

	useEffect(() => {

		getProfile().then((profile) => {
			setAvatar(profile.data.avatar);
			setprofile(profile.data);
		});
		coachList()
			.then((res) => {
				ManageLocalStorage.set("coachList", res.data.coaches_list.sort((firstItem, nextItem) => firstItem.order > nextItem.order ? 1 : -1));
			})
			.catch((error) => {
				console.log(error);
			});
	},[]);
	const { setvolumeDip } = useGlobalState();
    useEffect(() => {
      setvolumeDip(playAudio)
    
      return () => {
        setvolumeDip(false)
      }
    }, [playAudio])
	return (
		<div className="welcome-page-wrapper h-100">
			<div className="welcome-video h-100">
				<div className="space-around">
					<div className="popup-body p-m-auto w-100">
						<div className="welcome-container">
							<div className="welcome-container-title p-ml-3">
								<h3>Glenn E. <br /> Singleton</h3>
								<h4>Content Lead, <br /> Courageous Labs</h4>
							</div>

							<ReactPlayer
								className="react-player"
								url={ConfigAWS.BASE_URL + urlLinks.introVideo}
								playing={playAudio}
								width="100%"
								height="100%"
								volume={1}
								onClick={() => setPlayAudio(prev => !prev)}
								style={{ cursor: 'pointer' }}
							/>
						</div>
					</div>
					<div className="p-px-3 p-pt-3 p-pb-3 p-mt-auto w-100">
						{enableButton && <ButtonWrapper
							className="login-btn w-100 btn"
							label="NEXT"
							id="welcomeButton"
							onClick={() => goToCoach(profile)}
						// disabled={!enableButton}
						/>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithLayout(WelcomeVideo);
