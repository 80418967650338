import React from 'react'
import activityImg from "../../../Assets/images/activity-img.png";
import thinking from "../../../Assets/images/thinking-light.png";
import feeling from "../../../Assets/images/feeling-light.png";
import believing from "../../../Assets/images/believing-light.png";
import acting from "../../../Assets/images/acting-light.png";

export default function CompassThree() {
    const agent = { "bio": "James has long been committed to empowerment through surfacing and centering the voice(s) of others. This is especially evident through his skill and will to step into and hold space for difficult, but necessary dialogue. He holds building community through incessant learning and growing as personal and professional objectives. The passion and practice to lean into what it means to be White and male in leadership for racial equity is what brings him to this work.",
    "coach_name": "James",
    "img_url": "https://cc-files-production.s3.amazonaws.com/assets/agents/agent-5.png",
    "intro": "https://cc-files-production.s3.amazonaws.com/assets/intro/Coach-James-Intro.mp3",
    "order": "2",
    "id": "0f5953e5-3f91-4bb9-80ae-55ffc05aa04e",
    "type": "coach"}
    const selectedTopicDetails={audio_url: "https://cc-files-production.s3.us-east-1.amazonaws.com/assets/files/topic_file/7-1 CC App Madison Potts.m4a",
    task_name: "conversation",
    topic_description: "A Black family puts their home on the market for sale and is asked by the realtor to remove the family photos and not be present for the open house.\n",
    topic_id: "09b51de3-fdcf-4d57-b7bb-dbe469d24840",
    topic_name: "Level 7: Scenario 1 - For Sale",
    topic_points: "1"}
    const images = [thinking, feeling, acting, believing];
    const defaultSortedStories=[
        {
            "story_name": "Question 1",
            "content": "What do I believe? Why?",
            "quadrant": "believing",
            "content_type": "",
            "options": "[{\"M\":{\"name\":{\"S\":\"The realtor understands that homes are difficult to sell if potential buyers know that a Black family resided there.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The realtor understands that homes are difficult to sell if potential buyers know that a Black family resided there.\"}}},{\"M\":{\"name\":{\"S\":\"The real estate industry is steeped in racist beliefs and practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The real estate industry is steeped in racist beliefs and practices.\"}}},{\"M\":{\"name\":{\"S\":\"Real estate properties are under valuated because they are Black-owned.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Real estate properties are under valuated because they are Black-owned.\"}}},{\"M\":{\"name\":{\"S\":\"The realtor wants to create appeal for the home to the broadest market.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The realtor wants to create appeal for the home to the broadest market.\"}}}]",
            "id": "091459f4-dd01-40ef-9476-9719a6ce84f1",
            "file_url": "",
            "type": "story",
            "completed": false
        },
        {
            "story_name": "Question 4",
            "content": "What am I doing, or want to do with this?",
            "quadrant": "acting",
            "content_type": "",
            "options": "[{\"M\":{\"name\":{\"S\":\"I am going to research the prevalence of this practice.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to research the prevalence of this practice.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to advocate for the outlawing of such requests, practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to advocate for the outlawing of such requests, practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to write a letter to the press detailing such practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to write a letter to the press detailing such practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to create a platform for reporting such incidents.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to create a platform for reporting such incidents.\"}}}]",
            "id": "1328d964-d8b1-496f-a778-ea355b4119d1",
            "file_url": "",
            "type": "story",
            "completed": false
        },
        {
            "story_name": "Question 3",
            "content": "What are my thoughts, what do I understand about this?",
            "quadrant": "thinking",
            "content_type": "",
            "options": "[{\"M\":{\"name\":{\"S\":\"Is this an acceptable/accepted standard of practice, service?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Is this an acceptable/accepted standard of practice, service?\"}}},{\"M\":{\"name\":{\"S\":\"Are White families asked or expected to do the same thing?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Are White families asked or expected to do the same thing?\"}}},{\"M\":{\"name\":{\"S\":\"Is this legal?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Is this legal?\"}}},{\"M\":{\"name\":{\"S\":\"What is the significance to the success and bottom line of a home sale.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"What is the significance to the success and bottom line of a home sale.\"}}}]",
            "id": "35d81829-05a3-419d-90c8-2fafa3e46aba",
            "file_url": "",
            "type": "story",
            "completed": false
        },
        {
            "story_name": "Question 2",
            "content": "What am I feeling? Name the feeling.",
            "quadrant": "feeling",
            "content_type": "",
            "options": "[{\"M\":{\"name\":{\"S\":\"I am disappointed that the realtor would be so callous.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am disappointed that the realtor would be so callous.\"}}},{\"M\":{\"name\":{\"S\":\"I am angry that the real estate industry embraces and employs such practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am angry that the real estate industry embraces and employs such practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am disgusted by the realtorâ€™s insensitivity.    \"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am disgusted by the realtorâ€™s insensitivity.    \"}}},{\"M\":{\"name\":{\"S\":\"I am sorrowful for the Black familyâ€™s being subjected to this standard practice.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am sorrowful for the Black familyâ€™s being subjected to this standard practice.\"}}}]",
            "id": "55c0f3c0-9911-4588-a21c-8c72c72e8761",
            "file_url": "",
            "type": "story",
            "completed": false
        }
    ]
  return (
    <>
            
            <div style={{marginTop:"150px"}} className="inner-page-content">
                <div className="questionaire p-d-flex p-flex-column">
                    <div className="conv-avatar-section">
                        <div className="conv-top-section quadrant-section p-px-3 p-pb-4">
                            <div className="conv-top-section-inner p-d-flex p-flex-column">
                                <div className="conv-top-section-header p-d-flex p-ai-center p-jc-between">        
                                </div>
                                <div className="p-px-3 p-text-center p-mt-3 conv-top-section-quote">
                                    {selectedTopicDetails.topic_description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conv-question-section p-py-3 p-px-4 p-text-center">
                        Where are you on the compass right now?
                    </div>

                    <div className="conv-question-types p-py-4 p-px-4 p-text-center">


                        <div className="activity-img-outer">
                            <div className="activity-img">
                                <img src={activityImg} />
                            </div>
                            <div className="activity-items-wrapper w-100 h-100 p-d-flex p-jc-between ">
                                {defaultSortedStories.map((story, index) => (
                                    <div key={story.id} className="activity-diamond-wrapper" >
                                        <div className="story-type">{story.quadrant}</div>
                                        <div className="activity-type-icon">
                                            <img src={images[index]} />
                                        </div>
                                    </div>
                                ))}


                            

                            </div>
                        </div>
                    </div>

                </div>
            </div>
     
    
    
    </>
  )
}
