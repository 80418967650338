import React, { useState, useEffect } from "react";

import { getInfo } from '../../Services/SiteServices';

import "../../Assets/css/activity.scss";

import { getProfile } from "../../Services/SiteServices";
import activityImg from "../../Assets/images/activity-img.png";
import thinking from "../../Assets/images/thinking-light.png";
import feeling from "../../Assets/images/feeling-light.png";
import believing from "../../Assets/images/believing-light.png";
import acting from "../../Assets/images/acting-light.png";
import ReactPlayer from "react-player";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import InfoModal from "../../Shared/Components/InfoModal";
import { useGlobalState } from '../../Context'

function CompassContainer() {
    const [profileName, setProfileName] = useState("");
    const [infoContent, setInfoContent] = useState("");
    const [game, setGame] = useState({});
    const [completed, setcompleted] = useState([])
    const [pending, setpending] = useState([4, 3, 2, 1])
    const [current, setcurrent] = useState(1)
    const [playAudio, setPlayAudio] = useState(true);
    const [enableButton, setEnableButton] = useState(false)
    const introText = 'The Courageous Conversation™ Compass is a “personal navigation tool” to help guide you through this journey.  There are four ways of being in a conversation about race, which are identified on the Compass: Thinking, Believing, Feeling, and Acting.  We’ll refer back to the Compass to help identify where you are throughout this experience. '
    const profileDetails = async () => {
        await getProfile()
            .then((profile) => {
                setProfileName(profile.data.nickname);
                setGame(profile.data.game);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ShowIntro = () => {
        setInfoContent(introText)
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
        infoWrapperAnimatedClass.classList.add("slideUp");
    }
    useEffect(() => {
        onLoadAnimate();
        profileDetails();
        setTimeout(ShowIntro, 1800);
        return () => {
        }
    }, []);

    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const infoModal = (type, n) => {
        const last = n
        setcurrent(last)
        setpending(pending.filter((p) => p != last))
        console.log(last)
        getInfo(`activity-${type}`)
            .then(res => {
                console.log(res.data)
                if (res.data) {
                    setInfoContent(res.data[0].content)
                    const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
                    infoWrapperAnimatedClass.classList.add("slideUp");
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const hideBtn = (e) => {
        const last = pending.pop();
        setcurrent(last)
        setcompleted(complete => [...complete, last])
        console.log(last, pending)
        // setpending(pending=>pending.map((p)=>p!=last))

        const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
        infoWrapperAnimatedClass.classList.remove("slideUp");

        const backdropClass = document.getElementById("backdrop");
        if (backdropClass) {
            backdropClass.classList.remove("p-d-block");
            backdropClass.classList.add("p-d-none");
        }

    }


    const audioEndHandler = () => {
        setPlayAudio(!playAudio);
    }
    const { setvolumeDip } = useGlobalState();

    useEffect(() => {
        setvolumeDip(playAudio)

        return () => {
            setvolumeDip(false)
        }
    }, [playAudio])

    return (<>
        <style jsx='true'>
            {
                `
                                            position:absolute
                                            .audio-player {
                                                width: 100%;
                                                height: auto
                                            }
                                            .audio-player > div:first-child{
                                                width: 210px !important;
                                                height: 60px !important;
                                            }
                                            .activity-contaianer-outer-block{
                                                height:90%
                                            }
                                            .coachImage{
                                                width: 90px;
                                                height: 90px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                border: 5px solid #fff;
                                                flex: 0 0 auto;  
                                            }
                                            .coachImage img{ 
                                                max-width:100%
                                            }
                                            `
            }
        </style>

        <div className="audio-icon" style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
            
                <div className="coachImage">
                    <img src={'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-1.png'} alt="agent" />
                </div>

            
            <div className="audio-player" >
                <ReactPlayer url={'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+Buildout+(CHANEL)+Recording+1.m4a'}
                    controls={true}
                    style={{ width: '300px', height: '75px' }}
                    playing={playAudio}
                    onEnded={audioEndHandler} />
            </div>

        </div>

        <div className='activity-contaianer-outer-block' id='fadelayout'>




            <div className="activity-contaianer w-100">
                {/* <div className="activity-user-name">
                      <span className="activity-user-name-text">
                          {profileName}
                      </span>
                  </div> */}


                <div className="activity-img-outer">
                    <div className="activity-img">
                        <img src={activityImg} />
                    </div>
                    <div className="activity-items-wrapper w-100 h-100 p-d-flex p-jc-between p-flex-column">

                        <div className="activity-item-row p-d-flex p-jc-between activity-row-start ">

                            <div className={`tl ${current == 1 ? 'element' : ""}`} onClick={() => infoModal('thinking', 1)}>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label " style={{ color: 'white' }} >Thinking</span>
                                        <span className="type-level">
                                            <img src={thinking} />
                                        </span>
                                    </div>
                                </div>
                                <div className="activity-type-icon">

                                </div>
                            </div>

                            <div className={`tr ${current == 4 ? 'element' : ""}`} onClick={() => infoModal('feeling', 4)}>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }}>Feeling</span>
                                        <span className="type-level">
                                            <img src={feeling} />
                                        </span>
                                    </div>
                                </div>
                                <div className="activity-type-icon">

                                </div>
                            </div>

                        </div>

                        <div className="activity-item-row p-d-flex p-jc-center">
                            <div className="activity-level">
                                {game.level}
                            </div>
                        </div>

                        <div className="activity-item-row p-d-flex p-jc-between activity-row-end">
                            <div className={`bl ${current == 2 ? 'element' : ""}`} onClick={() => infoModal('believing', 2)}>
                                <div className="activity-type-icon">

                                </div>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }}>Believing</span>
                                        <span className="type-level">
                                            <img src={believing} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`br ${current == 3 ? 'element' : ""}`} onClick={() => infoModal('acting', 3)}>

                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }} >Acting</span>
                                        <span className="type-level">

                                            <img src={acting} />

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="infoWrapper3" className="infoWrapper" onClick={(e) => hideBtn(e)}>
                    <InfoModal content={infoContent} />
                </div>

            </div>


        </div>


    </>);
}
export default WithLayout(CompassContainer);