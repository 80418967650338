import React, { useState, useEffect } from "react";

import { getInfo } from '../../Services/SiteServices';

import { getProfile } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import ReactPlayer from "react-player";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import ButtonWrapper from "../../Shared/Components/Button";
import { useGlobalState } from '../../Context'
import infoIcon from "../../Assets/images/info.svg";
import infoPerson from "../../Assets/images/info-bg-person.png";
import "../../Assets/css/task.scss";
import "../../Assets/css/activity.scss";
import CompassOne from "./Compass/CompassOne";
import CompassTwo from "./Compass/CompassTwo";
import CompassThree from "./Compass/CompassThree";
import CompassFour from "./Compass/CompassFour";
import CompassFive from './Compass/CompassFive'
import CompassSix from './Compass/CompassSix'
const InfoModal = (props) => {
    return (
        <>
            <div className="rewardInnerWrapper h-100">
                <div className="infoWrapperIcon p-text-center p-py-2">
                    <img src={infoIcon} />
                </div>
                {props.contentType != 'img' ? (<>
                    <div className="infoWrapperContent p-d-flex p-py-2 p-px-3 p-jc-center p-ai-center" style={{ whiteSpace: "preWrap" }}>
                        {props.content}
                    </div>
                </>) : (<>
                    <div className="infoWrapperContent p-d-flex p-py-2 p-px-3 p-jc-center p-ai-center" style={{ whiteSpace: "preWrap" }}>
                        {props.content}  <img src={props.content}>
                        </img>
                    </div>
                </>)}
                <div className="infoWrapperFooter p-d-flex p-jc-end p-py-2 p-ai-end">
                    <span>Tap anywhere to close</span>
                    <img src={infoPerson} />
                </div>
            </div>
        </>
    )
}


function CompassIntoContainer(props) {

    const [profileName, setProfileName] = useState("");
    const [infoContent, setInfoContent] = useState("");
    const [currentStep, setcurrentStep] = useState(0)
    const [contentType, setcontentType] = useState('text')
    const introText = 'The Courageous Conversation™ Compass is a “personal navigation tool” to help guide you through this journey.  There are four ways of being in a conversation about race, which are identified on the Compass: Thinking, Believing, Feeling, and Acting.  We’ll refer back to the Compass to help identify where you are throughout this experience. '


    const images=[
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-2.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-1.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/Ali.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-2.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-1.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-2.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/Ali.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-1.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/Ali.png',
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-2.png', 
        'https://cc-files-production.s3.amazonaws.com/assets/agents/agent-2.png',  ]
    const audioLink = [
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+Buildout+(CHANEL)+Recording+1.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/STORYLINE+BUILD-OUT_+Emme%2C+Recording+2.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+Buildout+3+Sheeri.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+buildout+Claudine+4.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/STORYLINE+BUILD-OUT_+Emme%2C+Recording+6.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+Buildout+(CHANEL)+Recording+9.5.m4a',
'https://cc-files-production.s3.amazonaws.com/assets/compass/Flipbook+Storyline+Buildout+7+Sheeri.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+Buildout+9+Sheeri-%5BAudioTrimmer.com%5D.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/STORYLINE+BUILD-OUT_+Emme%2C+Recording+10.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+buildout+Claudine+10.5-%5BAudioTrimmer.com%5D.m4a',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Storyline+buildout+(CHANEL)+Recording+11-%5BAudioTrimmer.com%5D.m4a',
    ]
    const imgLink = ['https://cc-files-production.s3.amazonaws.com/assets/compass/Screenshot+(841).png',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Screenshot+(842).png',
        'https://cc-files-production.s3.amazonaws.com/assets/compass/Screenshot+(843).png']
    const [game, setGame] = useState({});
    const [completed, setcompleted] = useState([])
    const [pending, setpending] = useState([4, 3, 2, 1])
    const [current, setcurrent] = useState(0)
    const [playAudio, setPlayAudio] = useState(false);
    const [enableButton, setenableButton] = useState(false)
    const profileDetails = async () => {
        await getProfile()
            .then((profile) => {
                setProfileName(profile.data.nickname);
                setGame(profile.data.game);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ShowIntro = () => {
        setInfoContent(introText)
        setPlayAudio(true)
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
        infoWrapperAnimatedClass.classList.add("slideUp");

    }

    useEffect(() => {
        onLoadAnimate();
        profileDetails();
        setTimeout(ShowIntro, 1800);
        return () => {
        }
    }, []);



    const onLoadAnimate = () => {
        // const footerClass = document.getElementById("footerLayout");
        // footerClass.classList.remove("fadeVisibleOut");
        // footerClass.classList.add("fadeVisibleIn");
    };

    const infoModal = (type, n) => {
        const last = n
        setcurrent(last)
        setpending(pending.filter((p) => p != last))
        console.log(last)
        getInfo(`activity-${type}`)
            .then(res => {
                console.log(res.data)
                if (res.data) {
                    setInfoContent(res.data[0].content)
                    const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
                    infoWrapperAnimatedClass.classList.add("slideUp");
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const hideBtn = (e) => {
        const last = pending.pop();
        setcurrent(last)
        setcontentType('text')
        setcompleted(complete => [...complete, last])
        console.log(last, pending)
        // setpending(pending=>pending.map((p)=>p!=last))

        const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
        infoWrapperAnimatedClass.classList.remove("slideUp");

        const backdropClass = document.getElementById("backdrop");
        if (backdropClass) {
            backdropClass.classList.remove("p-d-block");
            backdropClass.classList.add("p-d-none");
        }
    }
    const showVideoLayout = () => {
        if (currentStep == audioLink.length-1) {
            if (ManageLocalStorage.get("popupShown") == "1") {
                props.history.push("/dashboard");
            }else{
                props.history.push("/avataar");
            }
        } else {
            setcurrentStep(currentStep + 1)
        }
        setenableButton(false)
    };


    const audioEndHandler = () => {
        setPlayAudio(!playAudio);
    }
    const { setvolumeDip } = useGlobalState();

    useEffect(() => {
        setvolumeDip(playAudio)

        return () => {
            setvolumeDip(false)
        }
    }, [playAudio])


    useEffect(() => {
       console.log('e',currentStep)
       if(currentStep==1){
        setInfoContent('To get the most out of a conversation, it is important to know which compass quadrants you and others are occupying during the conversation.   On the following screen, tap on a compass quadrant to learn more about it and select it.  Tap anywhere to continue. ')
        setPlayAudio(true)
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
        infoWrapperAnimatedClass.classList.add("slideUp");
       }
        setTimeout(() => { setenableButton(true) }, 200);
        return () => {
        }
    }, [currentStep])
   

    return (<>
         <div
            id="TasksPageWrapper"
            className="tasks-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
       <div className="inner-page-header">
                <h1 className="p-m-0">{currentStep<5?'Compass Tutorial':"Protocol Storyline Buildout"}</h1>
            </div>
        <div className="audio-icon" style={{ width: "100%", display: "flex", justifyContent: "space-around" ,position:"sticky",}}>

            <div className="coachImage">
                <img src={images[currentStep]} alt="agent" />
            </div>


            <div className="audio-player" >
                <ReactPlayer url={audioLink[currentStep]}
                    controls={true}
                    style={{ width: '300px', height: '75px' }}
                    playing={playAudio}
                    onEnded={audioEndHandler}
                />
            </div>

        </div>
        <div className='activity-contaianer-outer-block'>

            <div className="activity-contaianer w-100">

                {currentStep <4 ? (<>
                    <CompassOne infoModal={infoModal} currentStep={currentStep} game={game} />

                </>) : (currentStep == 4) ? (<>
                    <CompassTwo />
                </>) : (currentStep == 5 )? (<>
                    <CompassThree />
                </>): (currentStep == 6 )? (<>
                    <CompassThree />
                </>) : (currentStep == 7) ? (<>
                    <CompassSix />
                   
                </>):(currentStep == 9) ? (<>
                    <CompassFour />
                </>) : (currentStep == 8) ? (<>
                    <CompassFive />
                </>) :(currentStep == 10) ? (<>
                    <CompassFive />
                </>) : (<>
                    <CompassOne infoModal={infoModal} currentStep={currentStep} game={game} />
                </>)}
                <div id="infoWrapper3" className="infoWrapper" onClick={(e) => hideBtn(e)}>
                    <InfoModal content={infoContent} contentType={contentType}> </InfoModal>
                </div>

            </div>


        </div>
        <div className="p-px-3 p-pt-3 p-pb-3 w-100 p-mt-auto" >
            {enableButton && <ButtonWrapper
                className="login-btn w-100 btn"
                label="Next"
                id="videoButton"
                style={{position:"sticky",marginTop:"20px"}}
                onClick={showVideoLayout}
            />}
        </div>

        <style jsx='true'>
            {
                `
                                            position:absolute
                                            .audio-player {
                                                width: 100%;
                                                height: auto
                                            }
                                            .audio-player > div:first-child{
                                                width: 210px !important;
                                                height: 60px !important;
                                            }
                                            .activity-contaianer-outer-block{
                                                height:70%;

                                                overflow-y:scroll
                                            }
                                            .coachImage{
                                                width: 90px;
                                                height: 90px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                border: 5px solid #fff;
                                                flex: 0 0 auto;  
                                            }
                                            .coachImage img{ 
                                                max-width:100%
                                            }
                                            .activity-img-outer{
                                                margin-top: 9px;
                                                height: 150%;
                                            } .activity-img {
                                                height: 150%;
                                            }
                                            `
            }
        </style></div>
    </>);
}
export default WithLayout(CompassIntoContainer)