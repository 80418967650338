import React, { useEffect } from "react";
import "../../Assets/css/task.scss";
import actingImg from "../../Assets/images/acting.png";
import actingBlueImg from "../../Assets/images/acting-blue.png";
import feelingBlueImg from "../../Assets/images/feeling-blue.png";
import thinkingImg from "../../Assets/images/thinking.png";
import feelingImg from "../../Assets/images/feeling.png";
import believingImg from "../../Assets/images/believing.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import toast, { Toaster } from 'react-hot-toast';


const PastTasksContainer = (props) => {
  useEffect(() => {
    onLoadAnimate();
  }, []);
  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  };

  const notify = () => toast.error('Not available in this version');

  const goToExperiance = () => {
    props.history.push("/experience");
  };
  const goToConversation = () => {
    props.history.push("/conversation_agent");
  };

  const goToTasks = () => {
    props.history.push("/tasks");
  };
  return (
    <div
      id="TasksPageWrapper"
      className="tasks-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0">Past Tasks </h1>
      </div>
      <div className="inner-page-content">

        <div className="task-list-wrap">
          <div className="active-tasks tasks">
            <div className="tasks-header p-py-2 p-px-3">Active Tasks</div>
            <div className="tasks-content p-px-3  p-py-3">
              <ul className="task-list">
                <li className="past">
                  {/* <div className="task-item p-d-flex">
                    <div className="task-userimage">

                    </div>
                    <div className="task-name">
                      Conversation - Library
                    </div>
                    <div className="task-btn">
                      Start Now
                    </div>
                  </div> */}

                  <div className="task-item p-d-flex" onClick={goToConversation}>
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="past">
                  <div className="task-item p-d-flex" onClick={goToExperiance}>
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="past" onClick={notify}>
                  <div className="task-item p-d-flex">
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="past" onClick={notify}>
                  <div className="task-item p-d-flex">
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="passive-tasks tasks">
            <div className="tasks-header p-py-2 p-px-3">Passive Tasks</div>
            <div className="tasks-content p-px-3  p-py-3">
              <ul className="task-list">
                <li className="past" onClick={notify}>
                  <div className="task-item p-d-flex">
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="past" onClick={notify}>
                  <div className="task-item p-d-flex">
                    <div className="task-item-left task-full-width">
                      <div className="task-item-left-top p-jc-between p-py-2 p-px-2 p-text-left">
                        <span>Complete a conversation - Police reactions</span>
                        <span className="task-progress-count">+1 Thinking</span>
                      </div>
                      <div className="task-item-left-bottom p-py-2 p-text-center active">
                        <span className="start-label">COMPLETED</span>
                        <div
                          className="task-item-progress"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-text-center task-btn-wrap p-pt-2">
          <button
            className="task-btn btn p-ml-auto p-mr-auto"
            onClick={goToTasks}
          >
            View Tasks
          </button>
        </div>
      </div>
      <Toaster position="bottom-center"   reverseOrder={false} 
                toastOptions={{
                    // Define default options
                    icon: false,
                    style: {
                        background: '#323232',
                        color: '#fff',
                    }
                }}
      />
    </div>
  );
};
export default WithLayout(PastTasksContainer);
