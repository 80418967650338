import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import "../../Assets/css/library.scss";
import share from "../../Assets/images/share.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";

import { getLibrary } from '../../Services/SiteServices';
import ModalContainer from "./ModalContainer";

const LibraryContainer = (props) => {


  const [downloads, setDownloads] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [links, setLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [popupItem, setPopUpItem] = useState([]);
  const [modalType, setModalType] = useState();

  useEffect( () => {

      getLibrary()
      .then(res => {
        setDownloads(res.data.downloads);
        setQuotes(res.data.quotes)
        setLinks(res.data.links)
      })
      .catch(error => {
        console.log(error.response);
      })
      onLoadAnimate();
  }, []);

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  };

  const history = useHistory();

  const redirect = (type) => {
    history.push(`/${type}`);
  }

  const itemClick = (item, type) => {
    setPopUpItem(item);
    setModalType(type)
    setShowModal(true)
  }

  const downloadItem = (item, e) => {
    window.open(item.content_url);
    e.stopPropagation();
    setShowModal(true)
  }

  const vistUrl = (item, e) => {
    setShowModal(true)
    window.open(item.content_url, '_blank');
    e.stopPropagation()
  }

  return (
    <div
      id="BadgePageWrapper"
      className="badge-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0"> Library </h1>
      </div>
      <div className="inner-page-content">
        <div className="library-item-wrapper">
          <div className="library-header p-py-2 p-px-2">Downloads</div>
          <div className="library-content p-px-3  p-py-3">
            <ul className="library-list downloads">
              {
                downloads.slice(0, 3).map(item => (
                  <li key={item.hier} onClick={() => itemClick(item, 'downloads')}>
                    <div className="library-item" style={{ backgroundImage: `url("${item.image_url}")` }} />
                  </li>
                ))
              }
              {
                downloads.length > 3 ?
                  <li style={{ cursor: 'pointer' }} onClick={() => redirect('downloads')}>
                    <div className="library-item">
                      More
                    </div>
                </li> : ''
              }
            </ul>
          </div>
        </div>

        <div className="library-item-wrapper p-mt-2">
          <div className="library-header p-py-2 p-px-2">Quotes</div>
          <div className="library-content p-px-3  p-py-3">
            <ul className="library-list quotes">

              {
                quotes.slice(0, 3).map(item => (
                  <li key={item.hier} style={{ cursor: 'pointer' }} onClick={() => itemClick(item, 'quotes')}>
                    <div className="library-item" style={{ backgroundImage: `url("${item.image_url}")` }} />
                  </li>
                ))
              }
              {
                quotes.length > 3 ?
                  <li style={{ cursor: 'pointer' }} onClick={() => redirect('quotes')}>
                    <div className="library-item">
                          More
                    </div>
                  </li> : ''
              }
            </ul>
          </div>
        </div>

        <div className="library-item-wrapper p-mt-2">
          <div className="library-header p-py-2 p-px-2">Links</div>
          <div className="library-content p-px-3  p-py-3">
            <ul className="library-list downloads">

              {
                links.slice(0, 3).map(item => (
                  <li key={item.hier} onClick={() => itemClick(item, 'links')}>
                    <div className="library-item" style={{ backgroundImage: `url("${item.image_url}")` }} />
                  </li>
                ))
              }

              {
                links.length > 3 ?
                  <li style={{ cursor: 'pointer' }} onClick={() => redirect('links')}>
                    <div className="library-item">
                      More
                    </div>
                  </li> : ''
              }

            </ul>
          </div>
        </div>
      </div>

      <ModalContainer type={modalType} modal={showModal} popupItem={popupItem} downloadClick={(value, e) => downloadItem(value, e)}
                      visitUrlClick={(value, e) => vistUrl(value, e)} closeModal={(value) => setShowModal(value)} />

    </div>

  );
};
export default WithLayout(LibraryContainer);
