import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../Assets/css/login.module.scss";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import { InputText } from "primereact/inputtext";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation, validateEmail } from "../../Utilities/Utils";
import { trackPromise } from "react-promise-tracker";
import { RadioButton } from "primereact/radiobutton";
import { getAllDomains } from "../../Services/SiteServices";
import { Dropdown } from "primereact/dropdown";

const Signup = (props) => {
  const { onSignupClick, gotoLogin } = props;
  const [submitted, setSubmitted] = React.useState(false);
  let [gender, setGender] = React.useState(null);
  const [domains, setDomains] = useState([]);
  const [domainCheck, setDomainCheck] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);


  const [formObj, setFormObj] = React.useState({
    nickname: "",
    email: "",
    phone_number: "",
    gender: "male",
    domain_name: null,
    password: "",
    confirm_password: '',
    topic_list: [],
    passwordMatch: true,
    domainMatch: true
  });

  const passwordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const confirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const onSignupSubmit = async (e) => {

    e.preventDefault();
    setSubmitted(true);

    const email = formObj.email;

    if (email) {
      const emailDomain = email.split("@")[1];
      let checkDomain = domains.some(domain => domain.domain_name == emailDomain);

      if (checkDomain) {
        
        let domain = domains.find(item => item.domain_name === emailDomain);
        formObj.domain_name = domain.id;
        
        setFormObj({ ...formObj, domainMatch: true });
        formObj.domainMatch = true;
        // setDomainCheck(true)
      } else {
        setFormObj({ ...formObj, domainMatch: false });
        formObj.domainMatch = false;
        // setDomainCheck(false);
      }
    }

    if (formObj.password == formObj.confirm_password) {
      setFormObj({ ...formObj, passwordMatch: true })
      formObj.passwordMatch = true
    } else {
      setFormObj({ ...formObj, passwordMatch: false })
      formObj.passwordMatch = false
    }

    if (formObj.email && formObj.nickname && formObj.phone_number && formObj.passwordMatch == true && formObj.domainMatch == true) {
      // return console.log(formObj);
      trackPromise(
        onSignupClick(
          formObj.nickname,
          formObj.email,
          formObj.phone_number,
          formObj.gender,
          formObj.domain_name,
          formObj.password
        )
      );
    }
  };
  const setInput = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setFormObj({ ...formObj, [name]: value });
  };
  useEffect(() => {

    let domainAr = [];
    setFormObj({ topic_list: domainAr });

    getAllDomains().then((list) => {
      // console.log(list);
      setDomains([...list.data]);
    });

    return () => {
          
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDomainChange = (e) => {
    console.log("+++++domiain change", e);
    setFormObj({ ...formObj, domain_name: e });
  };
  return (
    <>
      <div className="h-100  p-d-flex p-jc-center p-ai-center">
        <div className="login-box w-100 p-px-3 p-pt-4 p-pb-6 p-m-auto">
          <form onSubmit={(e) => onSignupSubmit(e)}>
            <div className="p-field p-mb-2">
              <span className="p-float-label">
                <InputText
                  id="nickname"
                  name="nickname"
                  type="text"
                  className="w-100"
                  value={formObj.nickname}
                  required
                  autoFocus
                  autoComplete="off"
                  onChange={(e) => setInput(e)}
                />
                <label htmlFor="signup_nickname">
                  {TEXT_LABELS.SignupModule.nickname}{" "}
                </label>
              </span>

              {submitted && !formObj.nickname
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.userManagementModule.required.nickname
                )
                : ""}
            </div>

            {/* <div className="p-field p-mb-2">
              <span className="p-float-label p-float-select-label">
                <select
                  className="w-100 form-select"
                  value={formObj.domain_name}
                  onChange={(e) => onDomainChange(e.currentTarget.value)}
                >
                  <option>Select domain</option>
                  {formObj.topic_list.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <label>Select domain</label>
              </span>
              {submitted && !formObj.domain_name
                ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.userManagementModule.required.domain_name
                  )
                : ""}
            </div> */}

            <div className="p-field p-mb-2">
              <span className="p-float-label">
                <InputText
                  id="signup_email"
                  name="email"
                  type="email"
                  className="w-100"
                  value={formObj.email}
                  required
                  autoComplete="off"
                  onChange={(e) => setInput(e)}
                />
                <label htmlFor="signup_email">
                  {TEXT_LABELS.SignupModule.email}{" "}
                </label>
              </span>
              {submitted && !formObj.email
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.userManagementModule.required.email
                )
                : !validateEmail(formObj.email) && submitted
                  ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.userManagementModule.required.emailInvalid
                  )
                  : showFormValidation(false)}
              {
                submitted && !formObj.domainMatch ? showFormValidation(true, true, 'Domain not registed. Please check !') : ''
              }
            </div>
            <div className="p-field p-mb-2">
              <span className="p-float-label">
                <InputText
                  id="phone_number"
                  type="number"
                  name="phone_number"
                  className="w-100 hideArrow"
                  value={formObj.phone_number}
                  required
                  autoComplete="off"
                  onChange={(e) => setInput(e)}
                />
                <label htmlFor="signup_phone_number">
                  {TEXT_LABELS.SignupModule.phone_number}
                </label>
              </span>
              {submitted && !formObj.phone_number
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.userManagementModule.required.phone_number
                )
                : ""}
            </div>

            <div className="p-field p-mb-2">

              <span className="p-float-label password-text-erap">
                <InputText
                  id="password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={formObj.password}
                  required
                  className="w-100"
                  autoComplete="off"
                  onChange={(e) => setInput(e)}
                />
                <i onClick={passwordVisiblity}  className={passwordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
                <label htmlFor="password">Password</label>
              </span>

              {submitted && !formObj.password
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.loginModule.required.pwd
                )
                : showFormValidation(false)}
            </div>

            <div className="p-field p-mb-2">

              <span className="p-float-label password-text-erap">
                <InputText
                  id="confirm_pwd"
                  type={confirmPasswordShown ? "text" : "password"}
                  name="confirm_password"
                  autoComplete="off"
                  value={formObj.confirm_password}
                  required
                  className="w-100"
                  onChange={(e) => setInput(e)}
                />
                <i onClick={confirmPasswordVisiblity}  className={confirmPasswordShown ? "pi pi-eye-slash" : "pi pi-eye"}></i>
                <label htmlFor="confirm_pwd">Confirm Password</label>
              </span>

              {submitted && !formObj.confirm_password
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.loginModule.required.pwd
                )
                : showFormValidation(false)}
              {
                submitted && !formObj.passwordMatch ?
                  showFormValidation(
                    true,
                    true,
                    'Password not maching'
                  )
                  : showFormValidation(false)
              }
            </div>

            <div className="p-field p-mb-2 p-d-none">
              <label htmlFor="signup_gender">
                {" "}
                {TEXT_LABELS.SignupModule.gender}
              </label>
              <span className="p-float-label">
                <div className="p-field-radiobutton p-mb-1">
                  <div className="p-mr-2">
                    <RadioButton
                      value="male"
                      name="gender"
                      className="p-mr-1"
                      onChange={(e) => setInput(e)}
                      checked={formObj.gender === "male"}
                    />
                    <span>Male</span>
                  </div>
                  <div>
                    <RadioButton
                      value="female"
                      name="gender"
                      className="p-mr-1"
                      onChange={(e) => setInput(e)}
                      checked={formObj.gender === "female"}
                    />
                    <span>Female</span>
                  </div>
                </div>
                {submitted && !formObj.gender
                  ? showFormValidation(
                    true,
                    true,
                    TEXT_LABELS.userManagementModule.required.gender
                  )
                  : ""}
              </span>
            </div>
            <div className="p-field p-mt-4">
              <ButtonWrapper
                className="login-btn btn btn-primary w-100"
                label={TEXT_LABELS.SignupModule.signup}
                onClick={(e) => onSignupSubmit(e)}
              />
            </div>

            <div className="p-field p-mt-4">
              <div className="backtodiv p-d-flex p-ai-center p-jc-center">
                <span className="p-mr-2">Already have an account?</span>
                <span className="anchor-link" onClick={gotoLogin}>
                  Login
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Signup.propTypes = {
  onSignupClick: PropTypes.func.isRequired,
  gotoLogin: PropTypes.func,
};

export default WithLayout(Signup);
