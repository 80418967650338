import React,{useEffect, useState} from "react";
import PropTypes from "prop-types";

import "../../Assets/css/login.module.scss";

import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { TEXT_LABELS } from "../../Utilities/TextLabels";
import InputTextWrapper from "../../Shared/Components/InputText";
import ButtonWrapper from "../../Shared/Components/Button";
import { showFormValidation } from "../../Utilities/Utils";
import { trackPromise } from "react-promise-tracker";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const Confirm = (props) => {
  const { onConfirmClick } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [formObj, setFormObj] = React.useState({
    username: ManageLocalStorage.get("temp_email"),
    code: "",
  });
  const onConfirmSignupSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (formObj.username && formObj.code) {
      trackPromise(onConfirmClick(formObj.username, formObj.code));
    }
  };
  const setInput = (event) => {
    const { name, value } = event.target;
    setFormObj({ ...formObj, [name]: value });
  };
  return (
    <>
      <div className="h-100  p-d-flex p-jc-center p-ai-center">
        <div className="login-box w-100 p-px-3 p-pt-4 p-pb-6 p-m-auto">
          <form onSubmit={(e) => onConfirmSignupSubmit(e)}>
            <div className="p-field p-mb-2">
              <span className="p-float-label">
                <InputTextWrapper
                  id="username"
                  type="text"
                  name="username"
                  className="w-100"
                  value={formObj.username}
                  disabled
                  required
                  onChange={(e) => setInput(e)}
                />
                <label htmlFor="confirm_signup">
                  {TEXT_LABELS.SignupModule.email}
                </label>                
              </span>
              {submitted && !formObj.username
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.userManagementModule.required.email
                )
                : ""}
            </div>
            <div className="p-field p-mb-2">
              <span className="p-float-label">
                
                <InputTextWrapper
                  id="code"
                  type="text"
                  className="w-100"
                  name="code"
                  value={formObj.code}
                  required
                  onChange={(e) => setInput(e)}
                /> 
                <label htmlFor="confirm_signup">
                  {TEXT_LABELS.SignupModule.code}
                </label>               
              </span>
              {submitted && !formObj.code
                ? showFormValidation(
                  true,
                  true,
                  TEXT_LABELS.userManagementModule.required.code
                )
                : ""}
            </div>

            <div className="p-field">
              <ButtonWrapper
                className="btn btn-primary w-100"
                label={TEXT_LABELS.SignupModule.confirm}
                onClick={(e) => onConfirmSignupSubmit(e)}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
};

export default WithLayout(Confirm);
