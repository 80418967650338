import React, { useState, useEffect } from "react";
import experienceIcon from "../../Assets/images/experience.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";

import { getExperiance } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const ExperianceContainer = (props) => {
  const [experiance, setExperiance] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    onLoadAnimate();
    getExperiance(ManageLocalStorage.get("tenantId"),'experience').then((exp) => {
      // console.log(exp);
      setExperiance(exp.data);
      setLoaded(true)

    });
  }, []);

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  };

  const goToConversation = (storyId) => {
    // const type = 'conversation'
    // props.history.push(`/story?type=${type}?storyId=${storyId}`);
    props.history.push(`/story/conversation/${storyId}`);

  };

  // console.log(experiance.length);

  return (
    <div
      id="ExperiancePageWrapper"
      className="tasks-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
    >
      <div className="inner-page-header">
        <h1 className="p-m-0">Select an experience</h1>
      </div>
      <div className="inner-page-content">
        <div className="p-px-4 p-py-6 p-text-center">
          <img src={experienceIcon} />
        </div>

        <div className="experience-list p-px-3">
          <ul>
            {experiance.map((exp, inx) => (
              exp.story_id ? (
                <li
                key={inx}
                className="active"
                onClick={(e) => goToConversation(exp.story_id)}
              >
                <span className="experience-name"> {exp.topic_name}</span>
                {/* <span className="font-weight-bold experience-crs-date">
                  {exp.launch_date}
                </span> */}
              </li>
              ) : null
              
            ))}
          </ul>
          <div className="no-topic">
            {
              !experiance.length && loaded ? 
                <span className="p-mx-auto">No topics found</span>
                : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithLayout(ExperianceContainer);
