import React, { useState, useEffect } from "react";

import { getInfo } from '../../Services/SiteServices';

import "../../Assets/css/activity.scss";

import { getProfile } from "../../Services/SiteServices";
import activityImg from "../../Assets/images/activity-img.png";
import thinking from "../../Assets/images/thinking-light.png";
import feeling from "../../Assets/images/feeling-light.png";
import believing from "../../Assets/images/believing-light.png";
import acting from "../../Assets/images/acting-light.png";

import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import InfoModal from "../../Shared/Components/InfoModal";

const ActivityContainer = (props) => {
  const [profileName, setProfileName] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [game, setGame] = useState({});

  const profileDetails = async () => {
    await getProfile()
      .then((profile) => {
        setProfileName(profile.data.nickname);
        setGame(profile.data.game);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onLoadAnimate();
    profileDetails();

    return () => {
          
    }
  }, []);

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  };

  const infoModal = (type) => {
    getInfo(`activity-${type}`)
      .then(res => {
        console.log(res.data)
        if (res.data) {
          setInfoContent(res.data[0].content)
          const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
          infoWrapperAnimatedClass.classList.add("slideUp");
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const hideBtn = (e) => {
    const infoWrapperAnimatedClass = document.getElementById("infoWrapper3");
    infoWrapperAnimatedClass.classList.remove("slideUp");

    const backdropClass = document.getElementById("backdrop");
    if (backdropClass) {
      backdropClass.classList.remove("p-d-block");
      backdropClass.classList.add("p-d-none");
    }

  }

  return (
    <div className="activity-contaianer-outer-block">
      <div className="activity-contaianer w-100">
        <div className="activity-user-name">
          <span className="activity-user-name-text">
            {profileName}
          </span>
        </div>

        <div className="activity-img-outer">
          <div className="activity-img">
            <img src={activityImg} />
          </div>
          <div className="activity-items-wrapper w-100 h-100 p-d-flex p-jc-between p-flex-column">

            <div className="activity-item-row p-d-flex p-jc-between activity-row-start">

              <div className="activity-diamond-wrapper tl" onClick={() => infoModal('thinking')}>
                <div className="activity-diamond">
                  <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                    <span className="type-label">Thinking</span>
                    <span className="type-level">
                      {game.thinking}
                    </span>
                  </div>
                </div>
                <div className="activity-type-icon">
                  <img src={thinking} />
                </div>
              </div>

              <div className="activity-diamond-wrapper tr" onClick={() => infoModal('feeling')}>
                <div className="activity-diamond">
                  <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                    <span className="type-label">Feeling</span>
                    <span className="type-level">
                      {game.feeling}
                    </span>
                  </div>
                </div>
                <div className="activity-type-icon">
                  <img src={feeling} />
                </div>
              </div>

            </div>

            <div className="activity-item-row p-d-flex p-jc-center">
              <div className="activity-level">
                {game.level}
              </div>
            </div>

            <div className="activity-item-row p-d-flex p-jc-between activity-row-end">
              <div className="activity-diamond-wrapper bl" onClick={() => infoModal('believing')}>
                <div className="activity-type-icon">
                  <img src={believing} />
                </div>
                <div className="activity-diamond">
                  <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                    <span className="type-label">Believing</span>
                    <span className="type-level">
                      {game.believing}
                    </span>
                  </div>
                </div>
              </div>
              <div className="activity-diamond-wrapper br" onClick={() => infoModal('acting')}>
                <div className="activity-type-icon">
                  <img src={acting} />
                </div>
                <div className="activity-diamond">
                  <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                    <span className="type-label">Acting</span>
                    <span className="type-level">
                      {game.acting}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <div className="active-tasks tasks p-mt-5">
        <div className="activity-list-header p-py-2 p-px-3">Total Activity</div>
        <div className="activity-list-content p-px-3  p-py-3">
          <ul className="activity-list">
            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Conversations Completed
                </div>
                <div className="activity-list-right">
                  12
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Badges Earned
                </div>
                <div className="activity-list-right">
                  23
                </div>
              </div>
            </li>

            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Background Earned
                </div>
                <div className="activity-list-right">
                  3
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Total XP Earned
                </div>
                <div className="activity-list-right">
                  64
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Total Logins
                </div>
                <div className="activity-list-right">
                  33
                </div>
              </div>
            </li>
            <li>
              <div className="p-d-flex activity-list-wrapper">
                <div className="activity-list-left">
                  Start Date
                </div>
                <div className="activity-list-right">
                  February 1st 2021
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div> */}

        <div id="infoWrapper3" className="infoWrapper" onClick={(e) => hideBtn(e)}>
          <InfoModal content={infoContent} />
        </div>

      </div>
    </div>
  );
};
export default WithLayout(ActivityContainer);
