import React from 'react'

export default function CompassFive() {
    const agent = { "bio": "James has long been committed to empowerment through surfacing and centering the voice(s) of others. This is especially evident through his skill and will to step into and hold space for difficult, but necessary dialogue. He holds building community through incessant learning and growing as personal and professional objectives. The passion and practice to lean into what it means to be White and male in leadership for racial equity is what brings him to this work.",
    "coach_name": "James",
    "img_url": "https://cc-files-production.s3.amazonaws.com/assets/agents/agent-5.png",
    "intro": "https://cc-files-production.s3.amazonaws.com/assets/intro/Coach-James-Intro.mp3",
    "order": "2",
    "id": "0f5953e5-3f91-4bb9-80ae-55ffc05aa04e",
    "type": "coach"}
  return (
 <>
   <div style={{marginTop:"100px"}} className="inner-page-content">
                <div className="questionaire h-100 p-d-flex p-flex-column">
                    <div className="conv-avatar-section">
                        <div className="conv-top-section quadrant-section p-px-3 p-pb-4">
                            <div className="conv-top-section-inner p-d-flex p-flex-column">
                                
                                <div className="p-px-3 p-text-center p-mt-3 conv-top-section-quote">
                                    {'A Black family puts their home on the market for sale and is asked by the realtor to remove the family photos and not be present for the open house.'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conv-question-section p-py-3 p-px-4 p-text-center">
                        Share your thoughts on this conversation
                    </div>
                    <div className="conv-thoughts-textarea p-py-3 p-px-3 p-text-center">
                        <textarea
                            name="feedbackArea"
                            className="w-100"
                            id="feedbackArea"
                            cols="20"
                            rows="6"
                            placeholder="Tap here to start typing"
                        ></textarea>
                        
                    </div>
                </div>
            </div>
 
 </>
  )
}
