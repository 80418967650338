import React, { Component } from "react";
import { postConversation } from "../../Services/SiteServices";
import WithLayout from "../../Shared/Components/HeaderHOC";
class ChatList extends Component {
  constructor() {
    super();
    this.state = {
      reply: ["hi"],
      options: ["hi"],
      CONVERSATION: [],
      chatDelay: false,
    };
    this.updateConversation = this.updateConversation.bind(this);
  }

  updateConversation = async (selectedText) => {
    this.setState({ chatDelay: true });

    this.state.CONVERSATION.push(selectedText);
    postConversation(selectedText).then((dt) => {
      this.setState({ chatDelay: false });
      setTimeout(() => {
        this.setState({ options: dt.data.options });
      }, 100);
      let opt = dt.data.reply;
      this.state.CONVERSATION.push(opt);
      setTimeout(() => {
        this.setState({ reply: dt.data.reply });
      }, 200);
    });
  };

  render() {
    return (
      <>
        <div className="conversation-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
          <div className="inner-page-header">
            <h1 className="p-m-0"> Conversations </h1>
          </div>
          <div className="inner-page-content">
            <div className="chatwindow h-100 p-jc-between p-d-flex p-flex-column">
              <div className="discussion p-d-flex p-flex-column">
                {this.state.CONVERSATION.map((item, index) => {
                  const ifClass = index % 2 == 0 ? "recipient" : "sender";
                  return (
                    <div className={[ifClass, "bubble", "first"].join(" ")}>
                      <div className="txt">
                        <div className="name-wrapper p-d-flex p-jc-between p-ai-center">
                          <span className="name">Roy</span>
                          <span className="timestamp p-ml-4">10:20 pm</span>
                        </div>
                        <p className="message" key={{ index }}>
                          {item}
                        </p>
                      </div>
                      <div
                        className={[ifClass, "bubble-arrow"].join(" ")}
                      ></div>
                    </div>
                  );
                })}

                <div
                  className={
                    "p-mt-1 " +
                    (this.state.chatDelay ? "fadeVisibleIn" : "fadeVisibleOut")
                  }
                  id="chatloader"
                >
                  <div id="darkblue" className="chat-loading">
                    <div className="bubble sender last">
                      <span className="loading"></span>
                      <span className="loading"></span>
                      <span className="loading"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="OptionClass">
                <ul>
                  {this.state.options.map((item, index) => {
                    return (
                      <li
                        className="option"
                        key={index}
                        onClick={() => this.updateConversation(item)}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithLayout(ChatList);
