import React, { useEffect } from "react";

import { getInfo } from '../../Services/SiteServices';

import info from "../../Assets/images/info.svg";
import more from "../../Assets/images/more.svg";
import library from "../../Assets/images/library.svg";
import activity from "../../Assets/images/activity.svg";
import badges from "../../Assets/images/badges.svg";
import task from "../../Assets/images/task.svg";
import profile from "../../Assets/images/profile.svg";
import "../../Assets/css/footer.scss";
import InfoModal from "./InfoModal";

const Footer = (props) => {

  const handleSidebarToggle = () => {
    const wrapperClass = document.getElementById("menuWrapper");
    const wrapper = wrapperClass.classList;
    if (wrapper.contains("opened")) {
      wrapper.remove("opened");
    } else {
      wrapper.add("opened");
    }
  };

  const [infoContent, setInfoContent] = React.useState("");

  const goToTasks = () => {
    props.history.push("/tasks");
  };

  const goToBadges = () => {
    props.history.push("/badge");
  };

  const goToLibrary = () => {
    props.history.push("/library");
  };

  const goToActivity = () => {
    props.history.push("/activity");
  };
  const goToMorePage = () => {
    props.history.push("/more")
  }
  const hideBtn = (e) => {

    const infoWrapperAnimatedClass = document.getElementById("infoWrapper2");
          infoWrapperAnimatedClass.classList.remove("slideUp");

    const backdropClass = document.getElementById("backdrop");
    if(backdropClass) {
          backdropClass.classList.remove("p-d-block");
          backdropClass.classList.add("p-d-none");
    }
        
  }

  const infoClickGetUrl = () => {

        let str = props.history.location.pathname;
        let index = 0;
        str = str.substring(0, index) + str.substring(index + 1);
        // console.log(str)

        infoModalShow(str);
  }

  const infoModalShow = (page) => {
    getInfo(page)
    .then(res => {
      // console.log(res)
      if(res.data) {
        setInfoContent(res.data[0].content)
        const infoWrapperAnimatedClass = document.getElementById("infoWrapper2");
              infoWrapperAnimatedClass.classList.add("slideUp");
      }
    })
    .catch(err => {
      console.log(err)
    })
    
  }

  return (
    <>
      <div
        id="footerLayout"
        className="footer p-d-flex p-jc-between fadeVisibleOut"
      >
        <div className="footer-left">
          <div className="box" onClick={goToMorePage}>
            <div className="footer-text">
              <img src={more} />
              <span>More</span>
            </div>
          </div>
        </div>

        <div className="footer-center text-center">
          <div className="box" onClick={()=>{props.history.push('/dashboard');handleSidebarToggle()}}>
          {/* <div className="box" onClick={handleSidebarToggle}> */}

            <div className="footer-text">
              <img src={profile} />
              <span>Profile</span>
            </div>
          </div>
        </div>

        <div className="footer-right" onClick={()=>infoClickGetUrl()}>
          <div className="box">
            <div className="footer-text">
              <img src={info} />
              <span>Info</span>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-wrapper w-100" id="menuWrapper">
        <div id="staticDiamonds" className="static-diamonds">
          <div className="diamond small small-1">
            <div className="diamond-text"></div>
          </div>
          <div className="diamond small small-2">
            <div className="diamond-text"></div>
          </div>
          <div className="diamond small small-3">
            <div className="diamond-text"></div>
          </div>
        </div>
        <div id="profileTab" className="menu-items p-d-flex p-jc-center">
          <div className="diamond d-one" onClick={goToLibrary}>
            <div className="diamond-text">
              <div className="diamond-icon">
                <img src={library} />
              </div>
              <div className="diamond-label">library</div>
            </div>
          </div>
          <div className="diamond d-two" onClick={goToActivity}>
            <div className="diamond-text">
              <div className="diamond-icon">
                <img src={activity} />
              </div>
              <div className="diamond-label">activity</div>
            </div>
          </div>
          <div className="diamond d-three" onClick={goToBadges}>
            <div className="diamond-text">
              <div className="diamond-icon">
                <img src={badges} />
              </div>
              <div className="diamond-label">badges</div>
            </div>
          </div>
          <div className="diamond d-four" onClick={goToTasks}>
            <div className="diamond-text">
              <div className="diamond-icon">
                <img src={task} />
              </div>
              <div className="diamond-label">Conversations</div>
            </div>
          </div>
        </div>
      </div>
      <div id="infoWrapper2" className="infoWrapper" onClick={(e)=>hideBtn(e)}>
          <InfoModal content = {infoContent} />
      </div>
    </>
  );
};

export default Footer;
