import React, { useState, useEffect } from "react";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { getStoryListByTopicId } from "../../Services/SiteServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import activityImg from "../../Assets/images/conversation-star.png";
import thinking from "../../Assets/images/thinking-light.png";
import feeling from "../../Assets/images/feeling-light.png";
import believing from "../../Assets/images/believing-light.png";
import acting from "../../Assets/images/acting-light.png";
import audioIcon from "../../Assets/images/audio-icon.svg";
import ReactPlayer from "react-player";

const QuadrantSelectorContainer = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [agent, setAgent] = useState([]);
    const [stories, setStories] = useState([]);
    const [selectedTopicDetails, setSelectedTopicDetails] = useState([]);

    const images = [thinking, feeling, acting, believing];

    useEffect(() => {
        setAgent(JSON.parse(ManageLocalStorage.get("agentDetails")));
        console.log(JSON.parse(ManageLocalStorage.get("selectedTopicDetails")))
        setSelectedTopicDetails(JSON.parse(ManageLocalStorage.get("selectedTopicDetails")));
        onLoadAnimate();

        const topicId = props.match.params.id;
        getStoryListByTopicId(topicId)
            .then((res) => {
                console.log("stories ===>", res.data.stories);
                setStories(res.data.stories);
                setLoaded(true);
                ManageLocalStorage.set("storyDetails", res.data.stories);
            })
            .catch((err) => {
                console.log(err);
                setLoaded(true);
            });

        // return () => {
        //     // Cleanup actions
        // };
    }, []);

    const quadrants = ["thinking", "feeling", "acting", "believing"];
    let defaultSortedStories = [];
    let completedQuadrantArr = [];

    quadrants.map((key) => {
        let found = false;
        stories &&
            stories.filter((story) => {
                if (!found && story.quadrant === key) {
                    defaultSortedStories.push(story);
                    found = true;
                    return false;
                } else return true;
            });
    });

    defaultSortedStories.map(story => {
        if(story.completed){
            completedQuadrantArr.push(story);
        }
    })
    
    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const goToStory = (storyId, quadrant, completed) => {
        // completed ? ManageLocalStorage.set("completed", "1") : ManageLocalStorage.set("completed", "0");
        completedQuadrantArr.length > 0 ? ManageLocalStorage.set("completed", "1") : ManageLocalStorage.set("completed", "0");
        ManageLocalStorage.set("userSelectedQuadrant", quadrant);
        props.history.push(`/story/conversation/${storyId}`);
    };

    // Capitalize
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div
            id="ExperiancePageWrapper"
            className="questionaire-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
            <div className="inner-page-header">
                <h1 className="p-m-0">Conversation</h1>
            </div>
            <div className="inner-page-content">
                <div className="questionaire p-d-flex p-flex-column">
                    <div className="conv-avatar-section">
                        <div className="conv-top-section quadrant-section p-px-3 p-pb-4">
                            <div className="conv-top-section-inner p-d-flex p-flex-column">
                                <div className="conv-top-section-header p-d-flex p-ai-center p-jc-between">
                                    <div className="p-d-flex">
                                        <div className="conv-top-section-person">
                                            <img src={agent.img_url} alt="agent" />
                                        </div>
                                        <h3 className="p-ml-3">Coach {agent.name}</h3>
                                    </div>

                                    <style jsx='true'>
                                        {
                                            `
                                            .audio-player {
                                               
                                                width: 100%;
                                                height: auto
                                            }
                                            .audio-player > div:first-child{
                                                width: 210px !important;
                                                height: 60px !important;
                                            }
                                            `
                                        }
                                    </style>

                                    <div className="audio-icon p-mr-3">
                                        {/* <img src={audioIcon} /> */}
                                        {
                                            selectedTopicDetails?.audio_url ? 
                                            <div className="audio-player">
                                                <ReactPlayer url={selectedTopicDetails.audio_url.replace('us-east-1','us-east-1')} controls={true} style={{width:'300px', height: '75px'}}/> 
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="p-px-3 p-text-center p-mt-3 conv-top-section-quote">
                                    {selectedTopicDetails.topic_description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conv-question-section p-py-3 p-px-4 p-text-center">
                        Where are you on the compass right now?
                    </div>

                    <div className="conv-question-types p-py-4 p-px-4 p-text-center">


                        <div className="activity-img-outer">
                            <div className="activity-img">
                                <img src={activityImg} />
                            </div>
                            <div className="activity-items-wrapper w-100 h-100 p-d-flex p-jc-between ">
                                {defaultSortedStories.map((story, index) => (
                                    <div key={story.id} className="activity-diamond-wrapper" onClick={() => goToStory(story.id, story.quadrant, story.completed)}>
                                        <div className="story-type">{story.quadrant}</div>
                                        <div className="activity-type-icon">
                                            <img src={images[index]} />
                                        </div>
                                    </div>
                                ))}


                                {/* <div className="activity-diamond-wrapper tl">
                                        aa
                                        <div className="activity-type-icon">
                                            <img src={thinking} />
                                        </div>
                                    </div>

                                    <div className="activity-diamond-wrapper tr">
                                        bb
                                        <div className="activity-type-icon">
                                            <img src={feeling} />
                                        </div>
                                    </div>
                                    <div className="activity-diamond-wrapper bl">
                                        <div className="activity-type-icon">
                                            <img src={believing} />
                                        </div>
                                        cc
                                    </div>
                                    <div className="activity-diamond-wrapper br">
                                        <div className="activity-type-icon">
                                            <img src={acting} />
                                        </div>
                                        dd
                                    </div> */}

                                {/* <div className="activity-item-row p-d-flex p-jc-between activity-row-start">

                                    <div className="activity-diamond-wrapper tl">
                                        aa
                                        <div className="activity-type-icon">
                                            <img src={thinking} />
                                        </div>
                                    </div>

                                    <div className="activity-diamond-wrapper tr">
                                        bb
                                        <div className="activity-type-icon">
                                            <img src={feeling} />
                                        </div>
                                    </div>

                                </div> */}



                                {/* <div className="activity-item-row p-d-flex p-jc-between activity-row-end">
                                    <div className="activity-diamond-wrapper bl">
                                        <div className="activity-type-icon">
                                            <img src={believing} />
                                        </div>
                                        cc
                                    </div>
                                    <div className="activity-diamond-wrapper br">
                                        <div className="activity-type-icon">
                                            <img src={acting} />
                                        </div>
                                        dd
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>



                    <div className="questionaire-bottom-topic p-py-3 p-px-4">
                        <div className="p-text-center">

                        </div>



                        <div className="no-topic">
                            {!stories.length && loaded ? (
                                <span className="p-mx-auto">
                                    No stories found
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithLayout(QuadrantSelectorContainer);
