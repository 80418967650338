import React,{useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import { useGlobalState } from "../Context";
import ProgressBarWrapper from "../Shared/Components/ProgressBar";
import { useHistory } from 'react-router-dom';
const AppRoutes = ({
  component: Component,
  path,
  isPrivate,
  permissions,
  ...rest
}) => {
  const userDetails = useGlobalState().user;
  const history =useHistory()
  useEffect(() => {
    console.log(path)
    if(!userDetails.token){
      if(path !='/signup' && path !='/login' && path !='/confirm'  && path !='/resetpassword'){
        history.push('login')
      }
    }
    return () => {
    }
  }, [userDetails])
  
  return (
    <Route
      path={path}
      render={(props) => {
      
        if (Boolean(userDetails.token) && (path === "/" || path === "/login")) {
          return <Redirect to={{ pathname: "/welcome" }} />;
        } else if ((isPrivate && !Boolean(userDetails.token)) || path === "/") {
          return <Redirect to={{ pathname: "/splash" }} />;
        } else if (isPrivate && !permissions.includes(userDetails.userRole)) {
          return <Redirect to={{ pathname: "/welcome" }} />;
        } else {
          return (
            <>
              <ProgressBarWrapper />
              <Component {...props} {...rest} />
            </>
          );
        }
      }}
      {...rest}
    />
  );
};

export default AppRoutes;
