import React from "react";
import { useGlobalState } from "../../Context";
import ConfirmComponent from "./Confirm";
import { confirm } from "../../Services/UsermanagementServices";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";

const ConfirmContainer = (props) => {
  const stateValue = useGlobalState().dispatch; //get the dispatch method from the useDispatch custom hook
  const dispatch = stateValue;
  const confirmAction = async (username, code) => {
    try {
      let response = await confirm(username, code); //Signup action makes the request and handles all the neccessary state changes
      if (response) {
        props.showToast(
          "success",
          "Success",
          "Code verified successfully. Please login to proceed."
        );
        ManageLocalStorage.delete("temp_email");
        props.history.push("/login"); //navigate to login on success
      }
    } catch (error) {
      if (
        error.code === "NotAuthorizedException" ||
        error.code === "UserNotFoundException"
      ) {
        props.showToast("error", "Error", error.message);
      } else if (error.code === "NetworkError") {
        props.showToast(
          "error",
          "Error",
          "Please check your network connection"
        );
      } else {
        props.showToast("error", "Something went wrong please try again");
      }
    }
  };

  return (
    <ConfirmComponent
      {...{
        onConfirmClick: confirmAction,
      }}
    />
  );
};

export default ConfirmContainer;
