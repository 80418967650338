import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const WithLayout = (Component) => ({ ...props }) => (
  <div className="main-container wrapper h-100">
    <div className="container h-100">
      <Header {...props} />
      <div className="page-content">
        <Component {...props} />
      </div>
      <Footer {...props} />
    </div>
  </div>
);

export default WithLayout;
