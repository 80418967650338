import React from "react";
import _isFunction from "lodash/isFunction";
import _cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import WithLayout from "../../Shared/Components/HeaderHOC";
import Welcome from "./Welcome";
import { trackPromise } from "react-promise-tracker";
import {
  addNewSite,
  addSiteRule,
  getDownloadDelay,
  loadProjectList,
} from "../../Services/SiteServices";
import { getProjectDetails } from "../../Services/SettingsService";
import { attrTemplateToJSON, templateToJson } from "../../Utilities/Utils";

function WelcomeContainer({ showToast, ...props }) {
  const _isMounted = React.useRef(false);
  const [projectTemplate, setprojectTemplate] = React.useState([]);
  const [selectedProjectID, setselectedProjectID] = React.useState();
  const [projectList, setprojectList] = React.useState([]);
  const [downloadDelay, setDownloadDelay] = React.useState();
  return <Welcome {...props} />;
}

export default WithLayout(WelcomeContainer);
