import React, { useEffect, useState } from "react";

import toast, { Toaster } from 'react-hot-toast';

import "../../Assets/css/more.scss";
import compass from "../../Assets/images/activity.svg";
import community from "../../Assets/images/community.svg";
import resources from "../../Assets/images/resources.svg";
import coaching from "../../Assets/images/coaching.svg";
import loggedout from "../../Assets/images/logout.svg";
import avatarIcon from "../../Assets/images/avatar-icon.png";
import changeCoachIcon from "../../Assets/images/coach.png";

import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import { logout, useGlobalState } from "../../Context";

const MoreContainer = (props) => {
    const dispatch = useGlobalState().dispatch;
    const [logoutClick, setLogoutClick] = useState(false);
    
    useEffect(() => {
        onLoadAnimate();
    }, []);

    const onLoadAnimate = () => {
        const footerClass = document.getElementById("footerLayout");
        footerClass.classList.remove("fadeVisibleOut");
        footerClass.classList.add("fadeVisibleIn");
    };

    const logOutOnClick = () => {            
        logout(dispatch);
        setLogoutClick(false)
        props.history.push("/login");
    };
    
    const notify = () => toast.error('Not available in this version');

    return (
        <div className="h-100 " >
            <div
                id="MorePageWrapper"
                className="more-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
            >
                <div className="inner-page-header">
                    <h1 className="p-m-0"> More </h1>
                </div>
                <div className="inner-page-content">
                    <div className="p-px-2">
                        <div className="p-grid">
                            <div className="p-col p-col-6" onClick={()=>{ props.history.push("/compass")}}>
                                <div className="p-d-flex p-jc-end">
                                    <div className="diamond d-four odd">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={compass} />
                                            </div>
                                            <div className="diamond-label">Compass</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col p-col-6" onClick={notify}>
                                <div className="p-d-flex p-jc-start even">
                                    <div className="diamond d-four">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={community} />
                                            </div>
                                            <div className="diamond-label">Community</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col p-col-6" onClick={notify}>
                                <div className="p-d-flex p-jc-end">
                                    <div className="diamond d-four odd">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={resources} />
                                            </div>
                                            <div className="diamond-label">Resources</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col p-col-6" onClick={notify}>
                                <div className="p-d-flex p-jc-start even">
                                    <div className="diamond d-four">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={coaching} />
                                            </div>
                                            <div className="diamond-label">Coaching</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col p-col-6" onClick={()=> props.history.push('/changeAvataar')}>
                                <div className="p-d-flex p-jc-end odd">
                                    <div className="diamond d-four avatar-icon">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={avatarIcon} />
                                            </div>
                                            <div className="diamond-label">Change Avatar</div>
                                        </div>
                                    </div>
                                </div>
                            </div>       
                            <div className="p-col p-col-6" onClick={()=> props.history.push('/selectmycoach')}>
                                <div className="p-d-flex p-jc-start even">
                                    <div className="diamond d-four">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={changeCoachIcon} />
                                            </div>
                                            <div className="diamond-label">Change Coach</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col p-col-6" onClick={()=>setLogoutClick(true)} >
                                <div className="p-d-flex p-jc-end odd">
                                    <div className="diamond d-four logout">
                                        <div className="diamond-text">
                                            <div className="diamond-icon">
                                                <img src={loggedout} />
                                            </div>
                                            <div className="diamond-label">Logout</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={!logoutClick ? "confirmation-message" : "confirmation-message active"}>
                <div className="confirmation-message-head p-px-3 p-pt-3 p-pb-2">
                    <h3>Close this app?</h3>
                </div>
                <div className="confirmation-message-body p-px-3 p-py-2">
                    Are you sure want to logout?
                </div>
                <div className="confirmation-message-footer p-text-right p-px-3 p-pt-2 p-pb-3">
                    <button className="btn p-px-3 p-mr-2" onClick={()=>logOutOnClick()}>
                        Logout
                    </button>
                    <button className="btn p-px-3" onClick={()=>setLogoutClick(false)}>
                        No
                    </button>
                </div>
            </div>
            <div className={!logoutClick ? "backdrop confirm-backdrop" : "backdrop confirm-backdrop active"}></div>



            <Toaster position="bottom-center" reverseOrder={false}
                toastOptions={{
                    // Define default options
                    icon: false,
                    style: {
                        background: '#323232',
                        color: '#fff',
                    }
                }}
            />
        </div>
    );
};

export default WithLayout(MoreContainer);
