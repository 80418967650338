import React from 'react';
import { Button } from 'primereact/button';

const ButtonWrapper = props => {
    return (
        <Button {...props}/>
    );
};


export default ButtonWrapper;