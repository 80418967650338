import React,{useState,useEffect,useRef} from 'react'
import ReactPlayer from "react-player";


export default function CompassFour() {
const data=[{
    "reply": "What are my thoughts, what do I understand about this?",
    "reply_type": "",
    "options": "[{\"M\":{\"name\":{\"S\":\"Is this an acceptable/accepted standard of practice, service?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Is this an acceptable/accepted standard of practice, service?\"}}},{\"M\":{\"name\":{\"S\":\"Are White families asked or expected to do the same thing?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Are White families asked or expected to do the same thing?\"}}},{\"M\":{\"name\":{\"S\":\"Is this legal?\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Is this legal?\"}}},{\"M\":{\"name\":{\"S\":\"What is the significance to the success and bottom line of a home sale.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"What is the significance to the success and bottom line of a home sale.\"}}}]",
    "file_url": ""
},{
    "reply": "What am I feeling? Name the feeling.",
    "reply_type": "",
    "options": "[{\"M\":{\"name\":{\"S\":\"I am disappointed that the realtor would be so callous.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am disappointed that the realtor would be so callous.\"}}},{\"M\":{\"name\":{\"S\":\"I am angry that the real estate industry embraces and employs such practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am angry that the real estate industry embraces and employs such practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am disgusted by the realtorâ€™s insensitivity.    \"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am disgusted by the realtorâ€™s insensitivity.    \"}}},{\"M\":{\"name\":{\"S\":\"I am sorrowful for the Black familyâ€™s being subjected to this standard practice.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am sorrowful for the Black familyâ€™s being subjected to this standard practice.\"}}}]",
    "file_url": ""
} ,{
    "reply": "What am I doing, or want to do with this?",
    "reply_type": "",
    "options": "[{\"M\":{\"name\":{\"S\":\"I am going to research the prevalence of this practice.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to research the prevalence of this practice.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to advocate for the outlawing of such requests, practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to advocate for the outlawing of such requests, practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to write a letter to the press detailing such practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to write a letter to the press detailing such practices.\"}}},{\"M\":{\"name\":{\"S\":\"I am going to create a platform for reporting such incidents.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"I am going to create a platform for reporting such incidents.\"}}}]",
    "file_url": ""
},{
    "reply": "What do I believe? Why?",
    "reply_type": "",
    "options": "[{\"M\":{\"name\":{\"S\":\"The realtor understands that homes are difficult to sell if potential buyers know that a Black family resided there.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The realtor understands that homes are difficult to sell if potential buyers know that a Black family resided there.\"}}},{\"M\":{\"name\":{\"S\":\"The real estate industry is steeped in racist beliefs and practices.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The real estate industry is steeped in racist beliefs and practices.\"}}},{\"M\":{\"name\":{\"S\":\"Real estate properties are under valuated because they are Black-owned.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"Real estate properties are under valuated because they are Black-owned.\"}}},{\"M\":{\"name\":{\"S\":\"The realtor wants to create appeal for the home to the broadest market.\"},\"link\":{\"S\":\"\"},\"type\":{\"S\":\"text\"},\"content\":{\"S\":\"The realtor wants to create appeal for the home to the broadest market.\"}}}]",
    "file_url": ""
}]
const messagesEndRef = useRef();
const [reply, setReply] = useState([]);
    const [options, setOptions] = useState([]);
    const [conversation, setConversation] = useState([]);
    const [chatDelay, setChatDelay] = useState(false);
    const [endStory, setEndStory] = useState(false);
    const [endConversation, setEndConversation] = useState(false);
    const [image_url, setImage_url] = useState("");
    const [imageMedia, setImageMedia] = useState(false);
    const [stories, setStories] = useState([]);
    const [agent, setAgent] = useState([]);
    const [quadrants, setQuadrants] = useState([]);
    const [count, setCount] = useState(1);
    
    useEffect(() => {
        
        let replayAr = [];
        let optionAr = [];
        setQuadrants(["thinking", "feeling", "acting", "believing"]);

        document.getElementById("innerPage").classList.add("disabled");
     
         
            JSON.parse(data[0]?.options).map((opt) => {
                
                    optionAr.push(Object.values(opt)[0]);
                });

            replayAr.push(data[0].reply);
            setConversation([data[0]]);
            setReply(replayAr);
            setOptions(optionAr);
            document.getElementById("innerPage").classList.remove("disabled");
    }, []);


    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const setFile = (file_url, type) => {
        if (type == "image") {
            setImageMedia(true);
            setImage_url(file_url);
        }
    };

    const updateConversation = async (item) => {
        console.log(count)
        setCount((currCount) => currCount + 1);    
        if (count <= 3) {
            document.getElementById("innerPage")?.classList?.add("disabled");
            setChatDelay(true);
            setConversation((prevState) => {
                return [...prevState, item];
            });
            let optionAr = [];
                setChatDelay(false);
                setTimeout(() => {
                    JSON.parse(data[count]?.options).map((opt) => {
                
                        optionAr.push(Object.values(opt)[0]);
                    });
                    setOptions(optionAr);
                }, 1000);

                let opt = data[count];
                setConversation((prevState) => {
                    return [...prevState, opt];
                });

                setTimeout(() => {
                    setReply(data[count].reply);
                    // scrollToBottom();
                }, 200);

                if (data[count].options.length === 0) {
                    setEndStory(true);
                }
                document
                    .getElementById("innerPage")
                    ?.classList?.remove("disabled");
        }

        if (count > 3) {
            setTimeout(() => {
                setOptions([]);
                setConversation((prevState) => {
                    return [
                        ...prevState,
                        item,
                        { reply: "Thanks for the conversation today." },
                    ];
                });
                setEndConversation(true);
            }, 100);
        }
    };
  return (
    <>
        <div className="conversation-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
                
                <div className="inner-page-content" id="innerPage">
                    <div className="chatwindow h-100 p-jc-between p-d-flex p-flex-column">
                        <div className="discussion p-d-flex p-flex-column">
                            {conversation.map((item, index) => {
                                const ifClass =
                                    index % 2 == 0 ? "recipient" : "sender";
                                const userName =
                                    index % 2 == 0 ? agent.name : "Me";
                                // let today = new Date();
                                // let currentTime = today.toLocaleTimeString()
                                // let currentTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                                // const currentTime = "10:10 pm";
                                return (
                                    <div
                                        key={index}
                                        className={[
                                            ifClass,
                                            "bubble",
                                            "first",
                                        ].join(" ")}
                                        ref={messagesEndRef}
                                    >
                                        <div className="txt">
                                            <div className="name-wrapper p-d-flex p-jc-between p-ai-center">
                                                <span className="name">
                                                    {userName}
                                                </span>
                                                {/* <span className="timestamp p-ml-4">
                          {currentTime}
                        </span> */}
                                            </div>
                                            <p
                                                className="message question-text-format"
                                                key={{ index }}
                                            >
                                               
                                                { item?.reply ||Object.values(item?.content)[0]}
                                            </p>
                                            <div className="image-video-viewer p-mt-1">
                                                {console.log(item)}
                                                {item.reply_type === "image" ? (
                                                    <img
                                                        alt="file"
                                                        onClick={() =>
                                                            setFile(
                                                                item.file_url,
                                                                "image"
                                                            )
                                                        }
                                                        style={{
                                                            marginTop: "8px",
                                                            width: "135px",
                                                        }}
                                                        src={item.file_url}
                                                    />
                                                ) : item.reply_type ===
                                                  "video" ? (
                                                    <ReactPlayer
                                                        url={item.file_url}
                                                        controls={true}
                                                    />
                                                ) : null}
                                            </div>

                                            <div className="audio-viewer p-mt-2">
                                                {item.reply_type ===
                                                    "audio" && (
                                                    <ReactPlayer
                                                        url={item.file_url}
                                                        controls={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={[
                                                ifClass,
                                                "bubble-arrow",
                                            ].join(" ")}
                                        ></div>
                                    </div>
                                );
                            })}

                            <div
                                className={
                                    "p-mt-1 " +
                                    (chatDelay
                                        ? "fadeVisibleIn"
                                        : "fadeVisibleOut")
                                }
                                id="chatloader"
                            >
                                <div id="darkblue" className="chat-loading">
                                    <div className="bubble sender last">
                                        <span className="loading"></span>
                                        <span className="loading"></span>
                                        <span className="loading"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="OptionClass">
                            <ul>
                              
                                {options?.length > 0 &&
                                    options?.map((item, index) => {
                                        return (
                                            <li
                                                // style={{display: conversation.length % 2 === 0 && "none"}}
                                                className="option"
                                                key={index}
                                                onClick={() =>
                                                    updateConversation(item)
                                                }
                                            >
                                                
                                                {Object.values(item.content)[0]}
                                               
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    
    </>
  )
}
