import React from "react";
import { Router, Switch } from "react-router-dom";
import { GlobalStateProvider } from "./Context";
import AppRoute from "./Core/AppRoute";
import routes from "./Core/Routes";
import history from "./Core/History";
import { Toast } from "primereact/toast";
import { coachList, getProfile } from "./Services/SiteServices";
import { ManageLocalStorage } from "./Services/ManageLocalStorage";
function App() {
  const myToast = React.useRef(null);
  const showToast = (severityValue, summaryValue, detailValue, life = 3000) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
      life: life,
    });
  };

//   for (var a in localStorage) {
//     console.log(a, ' = ', localStorage[a]);
//  }
React.useEffect(() => {
  getProfile().then((profile) => {
    const data=profile.data
    if (!data?.avatar?.popup_shown) {
      ManageLocalStorage.set('welcomeSkipBtn', false)
      ManageLocalStorage.set("poetrySkiButton", false)
    } else {
      ManageLocalStorage.set('welcomeSkipBtn', true)
      ManageLocalStorage.set("poetrySkiButton", true)
    }
    if (!data?.avatar?.popup_shown) {
      ManageLocalStorage.delete("popupShown")
  } else {
      ManageLocalStorage.set("popupShown","1")
      ManageLocalStorage.set("avatarUrl",data?.avatar?.url)
     
  }
   
});
}, []);

  return (
    <GlobalStateProvider>
      <Router history={history}>
        <Switch>
          {routes.map((route) => {
            return (
              <AppRoute
                key={route.path}
                component={route.component}
                path={route.path}
                isPrivate={route.isPrivate}
                permissions={route.permissions}
                showToast={showToast}
              />
            );
          })}
        </Switch>
      </Router>
      <Toast ref={myToast} position="top-right" />
    </GlobalStateProvider>
  );
}

export default App;
