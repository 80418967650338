import React from 'react'
import activityImg from "../../../Assets/images/activity-img.png";
import thinking from "../../../Assets/images/thinking-light.png";
import feeling from "../../../Assets/images/feeling-light.png";
import believing from "../../../Assets/images/believing-light.png";
import acting from "../../../Assets/images/acting-light.png";

export default function CompassOne({infoModal,currentStep,game}) {
  return (
    <>
    <div className="activity-img-outer">
                    <div className="activity-img">
                        <img src={activityImg} />
                    </div>
                    <div id="quadAction" className="activity-items-wrapper w-100 h-100 p-d-flex p-jc-between p-flex-column" style={{ display: "none" }}>

                        <div className=" activity-item-row p-d-flex p-jc-between activity-row-start ">

                            <div className={`tl ${currentStep >=1 ? 'element' : ""}`} onClick={() => infoModal('thinking', 1)}>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label " style={{ color: 'white' }} >Thinking</span>
                                        <span className="type-level">
                                            <img src={thinking} />
                                        </span>
                                    </div>
                                </div>
                                <div className="activity-type-icon">

                                </div>
                            </div>

                            <div className={`tr ${currentStep >=1 ? 'element' : ""}`} onClick={() => infoModal('feeling', 4)}>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }}>Feeling</span>
                                        <span className="type-level">
                                            <img src={feeling} />
                                        </span>
                                    </div>
                                </div>
                                <div className="activity-type-icon">

                                </div>
                            </div>
                        </div>

                        <div className="activity-item-row p-d-flex p-jc-center">
                            <div className="activity-level">
                                {game.level}
                            </div>
                        </div>

                        <div className="activity-item-row p-d-flex p-jc-between activity-row-end">
                            <div className={`bl ${currentStep >=1 ? 'element' : ""}`} onClick={() => infoModal('believing', 2)}>
                                <div className="activity-type-icon">

                                </div>
                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }}>Believing</span>
                                        <span className="type-level">
                                            <img src={believing} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`br ${currentStep >=1 ? 'element' : ""}`} onClick={() => infoModal('acting', 3)}>

                                <div className="activity-diamond">
                                    <div className="type-wrapper p-d-flex p-flex-column p-ai-center">
                                        <span className="type-label" style={{ color: 'white' }} >Acting</span>
                                        <span className="type-level">

                                            <img src={acting} />

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    </>
  )
}
