
import Auth from '@aws-amplify/auth';
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
import { userRoles } from "../Utilities/Utils";
import { tenantActiveCheck } from '../Services/UsermanagementServices'


const loginApi = params => Auth.signIn(params.username, params.password).then(response => response);
export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await loginApi(loginPayload);

    if(response && response.username && response.attributes['custom:app'] === userRoles.EndUser) {

     await tenantActiveCheck()
      .then(res => {
        if(res.data.active === true) {
          dispatch({ type: 'LOGIN_SUCCESS', payload: response });
          return response;
        } else {
          const error = {
            code : "TENANT_USER_DISABLED",
            message : res.data.message
          }
          dispatch({ type: 'TENANT_DISABLED', error: error});
          throw error;
        }
      })
        
    } else if (response.attributes['custom:app'] === userRoles.TenantUser) {

      const error = {
        code : "TENANT_USER_LOGIN",
        message : "You are not authorised, please contact the vendor."
      }

      dispatch({ type: 'TENANT_ERROR', error: error});
      throw error;

    } else {
      dispatch({ type: 'LOGIN_ERROR', error: response.errors[0] });
      return;
    } 

  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    throw error;
  }
}

export const logout = (dispatch) => {
  Auth.signOut();
  ManageLocalStorage.clear();
  dispatch({ type: 'LOGOUT' });
}

// export const checkToken = (dispatch) => {
//   const userDetails =ManageLocalStorage.get('userDetails');
//   Auth.();
//   ManageLocalStorage.clear();
//   dispatch({ type: 'LOGOUT' });
// }