import React from 'react'
import dateFormat from "dateformat";
import actingCompletedImg from "../../../Assets/images/acting-completed.png";
import feelingCompletedImg from "../../../Assets/images/feeling-completed.png";
import thinkingCompletedImg from "../../../Assets/images/thinking-completed.png";
import believingCompletedImg from "../../../Assets/images/believing-completed.png";
export default function CompassTwo() {
    const activeConversation=[{
        "unlock_level": "2",
        "default_coach": "2752c18a-a287-495f-8c84-1ceb69adb4a2",
        "task_name": "conversation",
        "topic_name": "Level 1: Scenario 2 - Work",
        "topic_points": "1",
        "description": "You work with a company that is predominantly White and male. In the aftermath of the public unrest and demonstrations of protest, the company has expressed the intention to prioritize becoming more diverse, equitable and inclusive (DEI). The current HR Office and its head, a White male director, are being elevated to a C-suite level designation. A White-expressing Latina manager is promoted to a newly-created position of Director of DEI in that office. There ensues racial grumblings and chatter in the break rooms, elevators and parking lot, and that is called to the attention of new Chief of Human Resource Office. A company-wide meeting is called to talk about the concerns.",
        "id": "7b8b9a6f-3159-437b-bf97-a639a7fa9044",
        "type": "topic",
        "coach_img": "https://cc-files-production.s3.amazonaws.com/assets/agents/Asa.png",
        "coach_name": "Asa",
        "audio_url": "https://cc-files-production.s3.us-east-1.amazonaws.com/assets/files/topic_file/1-2 Marcus Brooks.m4a",
        "quadrant": "thinking",
        "completed_on": "2022-06-19 13:13:15.735343"
      },]
       const completedConversation =[
        {
          "unlock_level": "0",
          "default_coach": "2752c18a-a287-495f-8c84-1ceb69adb4a2",
          "task_name": "conversation",
          "topic_name": "Level 1: Scenario 1 - Home",
          "topic_points": "1",
          "description": "Youâ€™re at home carrying on with your typical day-to-day home life. You hear some commotion outside and glance out the window to see whatâ€™s going on. You see an Asian couple, neighbors you recognize, who seem to be walking their dog down the sidewalk across the street engaged in heated exchange with a White man, also a neighbor you recognize. You can hear derogatory language and slurs being hurled toward the couple as they disengage and walk away.Â  When your partner gets home from errands you want to tell them about what you experienced.",
          "id": "e74d3b88-c4f7-4cf5-ab2f-7f4a140021ff",
          "type": "topic",
          "coach_img": "https://cc-files-production.s3.amazonaws.com/assets/agents/Asa.png",
          "coach_name": "Asa",
          "audio_url": "https://cc-files-production.s3.us-east-1.amazonaws.com/assets/files/topic_file/1-1 CC App Madison Potts .m4a",
          "quadrant": "thinking",
          "completed_on": "2022-07-05 08:15:52.944892"
        }
      ]
  return (
    <>
       <div
            id="TasksPageWrapper"
            className="tasks-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column"
        >
           
                    <div className="inner-page-content" id="container">
                <div className="task-list-wrap">
                    <div className="active-tasks tasks">
                        <div className="tasks-header p-py-2 p-px-3">
                            Active Conversations
                        </div>
                        <div className="tasks-content p-px-3  p-py-3">
                        
                            <ul className="task-list">
                                {activeConversation &&
                                    activeConversation.length === 0 &&  completedConversation.length <60 &&(
                                        <li>
                                            No Active Conversation Found
                                        </li>
                                    )}

                                    {completedConversation &&  activeConversation?.length === 0 && completedConversation.length>=60 && (<>
                                
                                    <li>
                                    Congratulations! You Completed All The Active Conversations.
                                        </li>
                                  
                                    </>)}
                                {activeConversation.map(
                                    (conversationDetails) => {
                                      if (conversationDetails.default_coach === ""){}
                                        return (
                                            <li key={conversationDetails.id}>
                                                <div
                                                    className="task-item active-convo p-d-flex p-ai-center"
                                                    
                                                >
                                                    <div className="task-userimage">
                                                        <img
                                                            src={
                                                                conversationDetails.coach_img
                                                            }
                                                        />
                                                    </div>
                                                    <div className="active-task-wrapper">
                                                        <div className="task-name">
                                                            {
                                                                conversationDetails.topic_name
                                                            }
                                                        </div>
                                                        <div className="task-btn">
                                                            <span>
                                                                Start Now
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                      
                            </ul>
                        </div>
                    </div>

                    <div className="completed-tasks tasks p-mt-3">
                        <div className="tasks-header p-py-2 p-px-3">
                            Completed Conversations
                        </div>
                        <div className="tasks-content p-px-3  p-py-3">
                            <ul className="task-list">
                                {completedConversation &&
                                    completedConversation.length === 0 && (
                                        <li>
                                            No Completed Conversations
                                        </li>
                                  )}
                                {completedConversation.map(
                                    (compConveration) => {
                                      if (compConveration.default_coach === ""){
                                       

                                      } 
                                        return (
                                            <li
                                                className="completed"
                                                key={compConveration.id}
                                            >
                                                <div
                                                    className="task-item p-d-flex"
                                                    
                                                >
                                                    <div className="play-again-left-wrap p-d-flex p-ai-center p-jc-between p-px-2 p-py-1">
                                                        <div>
                                                            <div className="task-name">
                                                                {
                                                                    compConveration.topic_name
                                                                }
                                                            </div>
                                                            <div className="date">
                                                                {dateFormat(
                                                                    compConveration.completed_on,
                                                                    "dd mmm yyyy"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-d-flex">
                                                            <div className="task-val p-mr-2">
                                                                +1
                                                            </div>
                                                            <div className="p-d-flex p-flex-column task-type">
                                                                <div className="task-type-img">
                                                                    <img
                                                                        src={
                                                                          compConveration.quadrant === 'thinking' && thinkingCompletedImg ||
                                                                          compConveration.quadrant === 'feeling' && feelingCompletedImg ||
                                                                          compConveration.quadrant === 'acting' && actingCompletedImg ||
                                                                          compConveration.quadrant === 'believing' && believingCompletedImg
                                                                        }
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {
                                                                        compConveration.quadrant
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="task-btn h-100">
                                                            <span>
                                                                Play Again
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </>
  )
}
