import React, { useState, useEffect } from "react";
import logo from "../../Assets/images/logo.png";
import logoutIcon from "../../Assets/images/logout.png";
import backBtn from "../../Assets/images/backBtn.png";
import { logout, useGlobalState } from "../../Context";
import "../../Assets/css/header.scss";
import { useHistory } from "react-router-dom";
import musicIcon from "../../Assets/images/music.svg";
import musicMuteIcon from "../../Assets/images/music_mute.svg";
import GetConfigAWS from "../../GetAWSConfig";

const ConfigAWS = GetConfigAWS()

function Header(props) {
  // console.log(props);

  const history = useHistory();

  //get the dispatch method from the useDispatch custom hook
  const { dispatch, playAudio, setPlayAudio } = useGlobalState();
  const logOutOnClick = () => {
    logout(dispatch);
    props.history.push("/login");
  };
  const goHomeOnClick = () => {
    if (
      props.history.location.pathname === "/tasks" ||
      props.history.location.pathname === "/library" ||
      props.history.location.pathname === "/badge" ||
      props.history.location.pathname === "/activity" ||
      props.history.location.pathname === "/more"

    ) {
      props.history.push("/dashboard");
    }
    if (
      props.history.location.pathname === "/compass"
    ) {
      props.history.push("/more");
    }
    if (props.history.location.pathname === "/avataar") {
      props.history.push("/video");
    }
    if (props.history.location.pathname === "/video") {
      props.history.push("/poetryvideo");
    }
    if (props.history.location.pathname === "/changeAvataar") {
      props.history.push("/more");
    }
    if (
      props.history.location.pathname === "/conversation_agent" ||
      props.history.location.pathname === "/experience" ||
      props.history.location.pathname === "/past_tasks"
    ) {
      props.history.push("/tasks");
    }
    if (props.history.location.pathname === "/story") {
      props.history.push("/experience");
    }
    if (props.history.location.pathname === "/complete-exp") {
      history.goBack()
    }
    if (props.history.location.pathname === "/defaultcoach") {
      props.history.push("/video");
    }
    if (props.history.location.pathname === "/coach_bio") {
      props.history.push("/defaultcoach");
    }
    if (props.history.location.pathname === "/selectmycoach") {
      history.goBack()
    }
    if (props.history.location.pathname === "/coach") {
      props.history.push("/selectmycoach");
    }
    if (props.match.path === '/conversation/:id') {
      props.history.goBack();
    }
    if (props.history.location.pathname === "/conversation_questionaire") {
      props.history.push("/conversation");
    }
    if (props.match.path === '/quadrant-selector/:id') {
      history.goBack()
    }
    if (props.history.location.pathname === "/thoughts") {
      history.goBack()
    }
    if (props.match.path === '/conversation_questionaire/:id') {
      // props.history.push("/conversation");
      history.goBack()
    }
    if (props.match.path === '/story/conversation/:id') {
      // props.history.push("/conversation");
      history.goBack()
    }
    if (props.match.path === '/story/experience/:id') {
      // props.history.push("/experience");

      history.goBack()
    }
    if (
      props.history.location.pathname === "/downloads" ||
      props.history.location.pathname === "/links" ||
      props.history.location.pathname === "/quotes"
    ) {
      props.history.push("/library");
    }
  };
  let arrowClass = "p-d-none";
  let logoutClass = "p-d-none";
  let rewardsClass = "p-d-none";
  if (props.history) {
    if (
      props.history.location.pathname === "/welcome" ||
      props.history.location.pathname === "/login"
    ) {
      logoutClass = "p-d-block";
    }
    if (
      props.history.location.pathname === "/avataar" ||
      props.history.location.pathname === "/tasks" ||
      props.history.location.pathname === "/conversation_agent" ||
      props.history.location.pathname === "/conversation_questionaire" ||
      props.history.location.pathname === "/experience" ||
      props.history.location.pathname === "/story" ||
      props.history.location.pathname === "/library" ||
      props.history.location.pathname === "/downloads" ||
      props.history.location.pathname === "/links" ||
      props.history.location.pathname === "/quotes" ||
      props.history.location.pathname === "/badge" ||
      props.history.location.pathname === "/activity" ||
      props.history.location.pathname === "/compass" ||
      props.history.location.pathname === "/more" ||
      props.history.location.pathname === "/past_tasks" ||
      props.history.location.pathname === "/complete-exp" ||
      props.match.path === '/quadrant-selector/:id' ||
      props.match.path === '/conversation_questionaire/:id' ||
      props.match.path === '/story/conversation/:id' ||
      props.match.path === '/story/experience/:id' ||
      props.match.path === '/conversation/:id' ||
      props.history.location.pathname === "/changeAvataar" ||
      props.history.location.pathname === "/thoughts" ||
      props.history.location.pathname === "/defaultcoach" ||
      props.history.location.pathname === "/coach_bio" ||
      props.history.location.pathname === "/selectmycoach" ||
      props.history.location.pathname === "/coach" ||
      props.history.location.pathname === "/video"
    ) {
      // rewardsClass = "p-d-block";
      logoutClass = "p-d-block";
      arrowClass = "p-d-block";
    }
    if (props.history.location.pathname === "/dashboard") {
      rewardsClass = "p-d-block";
      logoutClass = "p-d-block";
    }
  }

  let condition1 = ["/signup", "/login", "/splash"].includes(history.location.pathname);

  useEffect(() => {
    if (history.location.pathname === "/welcome") {
      setPlayAudio(true)
    }
    setPlayAudio(prev => condition1 ? false : prev);
  }, [])


  return (
    <div className="header p-px-3">
      <div className="p-d-flex p-jc-between p-ai-center p-py-1 h-100">
        <div className="back-btn p-py-3 header-left header-div">
         skksks <img
            style={{ cursor: "pointer" }}
            src={backBtn}
            className={arrowClass}
            onClick={goHomeOnClick}
          />
        </div>
        <div className="logo header-center header-div">
          <img src={logo} />
        </div>
        <div className="rewards-button header-right header-div p-d-flex p-jc-end p-ai-center">
          {/* <span className={[rewardsClass, "rewards-label"].join(" ")}>
            Rewards
          </span> */}
          {!condition1 && <div className="play-audio-icon" style={{ cursor: 'pointer' }} onClick={() => setPlayAudio(prevValue => !prevValue)}>
            <img style={{ height: '35px', width: '35px' }} src={playAudio ? musicIcon : musicMuteIcon} />
          </div>}
          <div className="logout-btn p-ml-2">
            {/* <span
              className={[logoutClass, "logout-icon"].join(" ")}
              onClick={logOutOnClick}
            >
              <img src={logoutIcon} />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
