import React, { useState, useEffect, useRef } from "react";
import { getStory } from "../../Services/SiteServices";
import WithLayout from "../../Shared/Components/HeaderHOC";
import { ManageLocalStorage } from "../../Services/ManageLocalStorage";
import ImgsViewer from "react-images-viewer";
import ReactPlayer from "react-player";

const StoryList = (props) => {
    const [reply, setReply] = useState([]);
    const [options, setOptions] = useState([]);
    const [conversation, setConversation] = useState([]);
    const [chatDelay, setChatDelay] = useState(false);
    const [endStory, setEndStory] = useState(false);
    const [endConversation, setEndConversation] = useState(false);
    const [image_url, setImage_url] = useState("");
    const [imageMedia, setImageMedia] = useState(false);
    const [stories, setStories] = useState([]);
    const [agent, setAgent] = useState([]);
    const [quadrants, setQuadrants] = useState([]);
    const [count, setCount] = useState(1);

    const messagesEndRef = useRef();

    useEffect(() => {
        setStories(JSON.parse(ManageLocalStorage.get("storyDetails")));
        setAgent(JSON.parse(ManageLocalStorage.get("agentDetails")));
    }, []);

    useEffect(() => {
        const storyId = props.match.params.id;
        if (!storyId) {
            props.history.push("/tasks");
        }

        let replayAr = [];
        let optionAr = [];

        document.getElementById("innerPage").classList.add("disabled");
        getStory(storyId).then((str) => {
            console.log( JSON.parse(str?.data.options))
            str?.data?.options?.length &&
            JSON.parse(str?.data?.options).map((opt) => {
                
                    optionAr.push(Object.values(opt)[0]);
                });

            replayAr.push(str.data.reply);
            setConversation([str.data]);
            setReply(replayAr);
            setOptions(optionAr);
            document.getElementById("innerPage").classList.remove("disabled");
        });
    }, []);

    const userSelectedQuadrant = ManageLocalStorage.get("userSelectedQuadrant");
    useEffect(() => {
        switch (userSelectedQuadrant) {
            case "thinking":
                setQuadrants(["thinking", "feeling", "acting", "believing"]);
                break;
            case "feeling":
                setQuadrants(["feeling", "acting", "believing", "thinking"]);
                break;
            case "acting":
                setQuadrants(["acting", "believing", "thinking", "feeling"]);
                break;
            case "believing":
                setQuadrants(["believing", "thinking", "feeling", "acting"]);
                break;
        }
    }, [userSelectedQuadrant]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const updateConversation = async (item) => {
        console.log(count)
        setCount((currCount) => currCount + 1);
        let SortedStories = [];
        quadrants.map((key) => {
            let found = false;
            stories &&
                stories.filter((story) => {
                    if (!found && story.quadrant === key) {
                        SortedStories.push(story);
                        found = true;
                        return false;
                    } else return true;
                });
        });
        let sortedIDs = [];
        SortedStories.map((st) => {
            sortedIDs.push(st.id);
        });
       

        if (count <= 3) {
            document.getElementById("innerPage")?.classList?.add("disabled");
            setChatDelay(true);
            setConversation((prevState) => {
                return [...prevState, item];
            });
            await getStory(sortedIDs[count]).then((dt) => {
                let optionAr = [];
                setChatDelay(false);
                setTimeout(() => {
                    JSON.parse(dt?.data?.options).map((opt) => {
                
                        optionAr.push(Object.values(opt)[0]);
                    });
                    setOptions(optionAr);
                }, 1000);

                let opt = dt.data;
                setConversation((prevState) => {
                    return [...prevState, opt];
                });

                setTimeout(() => {
                    setReply(dt.data.reply);
                    scrollToBottom();
                }, 200);

                if (dt.data.options.length === 0) {
                    setEndStory(true);
                }
                document
                    .getElementById("innerPage")
                    ?.classList?.remove("disabled");
            });
        }

        if (count > 3) {
            setTimeout(() => {
                setOptions([]);
                setConversation((prevState) => {
                    return [
                        ...prevState,
                        item,
                        { reply: "Thanks for the conversation today." },
                    ];
                });
                setEndConversation(true);
            }, 100);
        }
    };

    const completeScreen = () => {
        props.history.push("/thoughts");
    };

    const setFile = (file_url, type) => {
        if (type == "image") {
            setImageMedia(true);
            setImage_url(file_url);
        }
    };
    //console.log(conversation);
    return (
        <>
            <ImgsViewer
                backdropCloseable={true}
                showImgCount={false}
                imgs={[{ src: image_url }]}
                isOpen={imageMedia}
                onClose={() => {
                    setImageMedia(false);
                }}
            />

            <div className="conversation-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
                <div className="inner-page-header">
                    <h1 className="p-m-0"> Conversations </h1>
                </div>
                <div className="inner-page-content" id="innerPage">
                    <div className="chatwindow h-100 p-jc-between p-d-flex p-flex-column">
                        <div className="discussion p-d-flex p-flex-column">
                            {conversation.map((item, index) => {
                                const ifClass =
                                    index % 2 == 0 ? "recipient" : "sender";
                                const userName =
                                    index % 2 == 0 ? agent.name : "Me";
                                // let today = new Date();
                                // let currentTime = today.toLocaleTimeString()
                                // let currentTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                                // const currentTime = "10:10 pm";
                                return (
                                    <div
                                        key={index}
                                        className={[
                                            ifClass,
                                            "bubble",
                                            "first",
                                        ].join(" ")}
                                        ref={messagesEndRef}
                                    >
                                        <div className="txt">
                                            <div className="name-wrapper p-d-flex p-jc-between p-ai-center">
                                                <span className="name">
                                                    {userName}
                                                </span>
                                                {/* <span className="timestamp p-ml-4">
                          {currentTime}
                        </span> */}
                                            </div>
                                            <p
                                                className="message question-text-format"
                                                key={{ index }}
                                            >
                                               
                                                { item?.reply ||Object.values(item?.content)[0]}
                                            </p>
                                            <div className="image-video-viewer p-mt-1">
                                               
                                                {item.reply_type === "image" ? (
                                                    <img
                                                        alt="file"
                                                        onClick={() =>
                                                            setFile(
                                                                item.file_url,
                                                                "image"
                                                            )
                                                        }
                                                        style={{
                                                            marginTop: "8px",
                                                            width: "135px",
                                                        }}
                                                        src={item.file_url}
                                                    />
                                                ) : item.reply_type ===
                                                  "video" ? (
                                                    <ReactPlayer
                                                        url={item.file_url}
                                                        controls={true}
                                                    />
                                                ) : null}
                                            </div>

                                            <div className="audio-viewer p-mt-2">
                                                {item.reply_type ===
                                                    "audio" && (
                                                    <ReactPlayer
                                                        url={item.file_url}
                                                        controls={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={[
                                                ifClass,
                                                "bubble-arrow",
                                            ].join(" ")}
                                        ></div>
                                    </div>
                                );
                            })}

                            <div
                                className={
                                    "p-mt-1 " +
                                    (chatDelay
                                        ? "fadeVisibleIn"
                                        : "fadeVisibleOut")
                                }
                                id="chatloader"
                            >
                                <div id="darkblue" className="chat-loading">
                                    <div className="bubble sender last">
                                        <span className="loading"></span>
                                        <span className="loading"></span>
                                        <span className="loading"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="OptionClass">
                            <ul>
                                {console.log(options)}
                                {options?.length > 0 &&
                                    options?.map((item, index) => {
                                        return (
                                            <li
                                                // style={{display: conversation.length % 2 === 0 && "none"}}
                                                className="option"
                                                key={index}
                                                onClick={() =>
                                                    updateConversation(item)
                                                }
                                            >
                                                
                                                {Object.values(item.content)[0]}
                                               
                                            </li>
                                        );
                                    })}
                            </ul>
                            {endStory || endConversation ? (
                                <div className="option-end-btn-wrap">
                                    <button
                                        onClick={() => completeScreen()}
                                        className="btn w-100"
                                    >
                                        Next
                                    </button>
                                </div>
                            ) : null}

                            {/* <div className="skip-to-last" onClick={() => props.history.push('/thoughts')}>
                <strong style={{cursor: "pointer"}}>End Conversation</strong>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithLayout(StoryList);
