import React from 'react';
import { InputText } from "primereact/inputtext";

const InputTextWrapper = props => {
  return (
    <InputText {...props}/>
  );
};


export default InputTextWrapper;