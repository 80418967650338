import React, { useEffect, useState } from 'react';
import { coachList } from '../../Services/SiteServices';
import ButtonWrapper from '../../Shared/Components/Button';
import WithLayout from '../../Shared/Components/HeaderHOC';
import PlayIcon from "../../Assets/images/play-icon.svg";
import PauseIcon from "../../Assets/images/pause-icon.svg";
import ReactPlayer from "react-player";
import { ManageLocalStorage } from '../../Services/ManageLocalStorage';
import imagePlaceholder from '../../Assets/images/image-placeholder.png'
import {useGlobalState} from '../../Context'
const CoachBioContainer = (props) => {

    const [coaches, setCoaches] = useState([]);
    const [count, setCount] = useState(0);
    const [playAudio, setPlayAudio] = useState(false);
    const [enableButton, setEnableButton] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setEnableButton(true)
        }, 1000 * 60)
        setCoaches(JSON.parse(ManageLocalStorage.get('coachList')));
       
        return () => {
            
        }
    }, [])
 
    useEffect(() => {
        setEnableButton(false)
        setTimeout(() => {
            setEnableButton(true)
        }, 1000 * 60)
        return () => {
            
          }
    }, [count])

    const nextCoach = () => {
        setPlayAudio(true);
        setTimeout(() => {
            setCount(prevCount => prevCount + 1);
        }, 50)
    }

    const selectMyCoach = () => {
        props.history.push("/selectmycoach")
        setCount(0);
    }

    const audioEndHandler = () => {
        setPlayAudio(false);
    }
    const { setvolumeDip } = useGlobalState();
    useEffect(() => {
      setvolumeDip(playAudio)
    
      return () => {
        setvolumeDip(false)
      }
    }, [playAudio])


    return (
        <>
            <div className="inner-page-wrapper h-100 p-d-flex p-flex-column coach-selection-wrap">
                {console.log(coaches)}
                {coaches?.length && (

                    <div className="space-around p-d-flex">
                        <div className="popup-body">
                            <div className="conersation-agents-wrap p-d-flex p-jc-center p-ai-center p-mt-4">
                                <div className="p-d-flex p-flex-wrap p-jc-center">
                                    <div className="conersation-agent">
                                        <div className="conersation-agent-img">
                                            <div className="placeholder-img">
                                                <img src={imagePlaceholder} />
                                            </div>
                                            <img
                                                src={coaches[count].img_url}
                                                alt="coach"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="conersation-start-text p-px-4 p-text-left">
                                <div className="p-d-flex p-jc-between p-ai-center">
                                    <h3>Coach {coaches[count].coach_name}</h3>
                                    {
                                        coaches[count].intro ?
                                            <div className="play-audio-icon" style={{ cursor: 'pointer' }} onClick={() => setPlayAudio(prevValue => !prevValue)}>
                                                <img src={playAudio ? PauseIcon : PlayIcon} />
                                            </div>
                                            : null
                                    }
                                </div>

                                {
                                    coaches[count].intro ?
                                        <div className="audio-player" style={{ display: 'none' }}>
                                            <ReactPlayer url={coaches[count].intro} controls={true} style={{ width: '300px', height: '75px' }} playing={playAudio} onEnded={audioEndHandler} />
                                        </div>
                                        : null
                                }
                                <p>{coaches[count].bio}</p>
                            </div>
                        </div>

                        <div className="p-px-3 p-pt-3 p-pb-4 p-mt-auto w-100">
                            {enableButton && <ButtonWrapper
                                className="login-btn w-100 btn medium-btn"
                                label={count === coaches.length - 1 ? "Select My Coach" : "Next Coach"}
                                id="welcomeButton"
                                onClick={() => count === coaches.length - 1 ? selectMyCoach() : nextCoach()}
                            // disabled={!enableButton}
                            />}
                        </div>
                    </div>
                )
                }
            </div>
        </>
    );
};

export default WithLayout(CoachBioContainer);