import React, { useEffect,useState } from "react";
import "../../Assets/css/library.scss";
import downloadOne from "../../Assets/images/download-1.png";
import downloadTwo from "../../Assets/images/download-2.png";
import downloadThree from "../../Assets/images/download-3.png";
import WithLayout from "../../Shared/Components/HeaderFooterHOC";
import  { getAssets } from '../../Services/SiteServices';
import share from "../../Assets/images/share.png";
import ModalContainer from "./ModalContainer";


const QuotesContainer = (props) => {

  const [quotes, setQuotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [popupItem, setPopUpItem] = useState([]);
  const [modalType, setModalType] = useState();


  useEffect(() => {
    getAssets('quotes')
    .then(res => {
        setQuotes(res.data);
        setModalType('quotes');
      }
    )
    .catch(error => {
      console.log(error);
    })
    onLoadAnimate()
  }, []);

  const onLoadAnimate = () => {
    const footerClass = document.getElementById("footerLayout");
    footerClass.classList.remove("fadeVisibleOut");
    footerClass.classList.add("fadeVisibleIn");
  }

  const itemClick = (item) => {
    setPopUpItem(item);
    setShowModal(true)
  }

  return (
    <div id="BadgePageWrapper" className="badge-page-wrapper inner-page-wrapper h-100 p-d-flex p-flex-column">
      <div className="inner-page-header">
        <h1 className="p-m-0"> Quotes </h1>
      </div>
      <div className="inner-page-content">
        <div className="library-item-wrapper">
          <div className="library-content full-list p-px-3  p-py-3">
            <ul className="library-list quotes">
              {
                quotes.map(item =>(
                  <li key={item.hier} onClick={()=>itemClick(item)}>
                    <div className="library-item" style={{ backgroundImage: `url("${item.image_url}")` }} />
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <ModalContainer type={modalType} modal={showModal} popupItem={popupItem} closeModal={(value)=>setShowModal(value)}/>

    </div>
  );
};
export default WithLayout(QuotesContainer);
