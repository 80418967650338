import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import GetConfigAWS from '../GetAWSConfig';
import history from '../Core/History';
import { ManageLocalStorage } from "../Services/ManageLocalStorage";
const ConfigAWS = GetConfigAWS();

const reRouteOnExecption = error => {
  if (error && error.response && error.response.status === 401) {
    Auth.signOut();
    ManageLocalStorage.clear();
    global.location.reload();
  } else if (error && error.response && error.response.status === 410) {
    history.push({ pathname: '/expired' });
  }else if (error && error.response && error.response.status === 402) {
    throw error;
  }
};

export const AmplifyAPI = async (method, path, request = {}, cognitoHeader = false) => {
  if (cognitoHeader) {
    const session = await Auth.currentSession();
    request.headers = {
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    };
  }
  switch (method) {
    case 'get':
      return API.get(ConfigAWS.apiGateway.NAME, path, request)
        .then(result => result)
        .catch(reRouteOnExecption);
    case 'post':
      return API.post(ConfigAWS.apiGateway.NAME, path, request)
        .then(result => result)
        .catch(reRouteOnExecption);
    case 'put':
      return API.put(ConfigAWS.apiGateway.NAME, path, request)
        .then(result => result)
        .catch(reRouteOnExecption);
    case 'delete':
      return API.del(ConfigAWS.apiGateway.NAME, path, request)
        .then(result => result)
        .catch(reRouteOnExecption);
    default:
      break;
  }
};

export default AmplifyAPI;
